<template>
  <Teleport to="#wrapper">
    <div class="modal_wrapper">
      <div class="modal_container">
        <div class="modal_title d-flex justify-content-between py-2 px-3">
          <h4 class="my-0">Upload file</h4>
          <div class="cursor-pointer" @click.prevent="closeModal()">
            <i class="fa fa-times" title="Close"></i>
          </div>
        </div>
        <div
          class="modal__upload__content d-flex flex-column justify-content-between pb-4 pt-2 px-4"
        >
          <div class="input-group">
            <input
              accept=".pdf"
              id="InPutFile"
              type="file"
              class="form-control my-2 my-lg-0"
              @change="previewFiles"
              multiple
            />
            <span class="input-group-append">
              <button
                type="button"
                class="stdy_upload_btn mr-0 py-1"
                @click="studyDataExport()"
                :disabled="showLoading"
              >
                <div class="d-flex align-items-center" v-if="showLoading">
                  <img
                    class="loading-img"
                    width="20"
                    src="../../assets/img/loading.webp"
                    alt="Wait while the document is added"
                  />
                </div>
                <div v-else>{{ $t("navigations.generatee") }}</div>
              </button>
            </span>
          </div>
          <div v-if="showValidation" class="errormsg">
            {{ $t("navigations.please select a file") }}
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script src="./UploadFile.js"></script>

<style scoped>
.modal-container {
  background: #fff;
  width: min(30%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}
</style>