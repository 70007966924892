<template>
    <div class="stat-card-white" ref="captureArea">
      <div class="stat-header-white">
        <span>{{ chartlabel }}</span>
        <div class="d-flex align-items-center w-75 justify-content-end">
          <!-- Dropdown to Select Site Code -->
          <label for="site-select" class="mr-2">Select Site:</label>
          <select id="site-select" class="graph-select py-0" v-model="selectedSiteCode" @change="updateChart">
            <option v-for="site in siteCodes" :key="site" :value="site">{{ site }}</option>
          </select>
          <div class="expand-block px-2 mb-1 cursor-pointer position-relative">
            <img @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
            <div class="more-expand">
              <span>{{ $t("navigations.data overview") }}</span>
            </div>
          </div>
          <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock = true">
            <img src="../../assets/img/expand.png" alt="Expand block">
          </div>
        </div>
      </div>
  
      <div class="stat-body-white chart-container d-flex flex-column" ref="chartContainerBlock">
        <div v-if="loader" class="loader"></div>
        <div v-show="!loader">
          <svg ref="chartContainer"></svg>
          <div ref="tooltip" class="tooltip"></div>
        </div>
      </div>
  
      <enlargerpopup v-if="expandBlock" @closeModal="expandBlock = false; createChart()">
        <div class="stat-body-white d-flex flex-column">
          <svg ref="popupChartContainer"></svg>
        </div>
      </enlargerpopup>
    </div>
  </template>
  
  <script>
  import * as d3 from "d3";
  export default {
    name: "LineChart",
    props: {
      data: {
        type: Array,
        required: true,
        default: () => [],
      },
      chartlabel: {
        type: String,
        default: "Interactive Analysis",
      },
      loader: {
        type: Boolean,
        default: false,
      },
      graphwidth: {
        type: Number,
        default: 600,
      },
      graphheight: {
        type: Number,
        default: 350,
      },
      xaxislabel:{
        type: String,
        default: "Time (YYYY-MM)",
      },
      yaxislabel:{
        type: String,
        default: "Average days",
      },
    },
    data() {
      return {
        selectedSiteCode: "",
        siteCodes: [],
        tooltip: null,
        chartContainer: null,
        svg: null,
        mouseX:0,
        mouseY:0
      };
    },
    watch: {
      data: {
        handler() {
          this.populateSiteCodes();
          this.createChart();
        },
        immediate: true,
        deep: true,
      },
      selectedSiteCode() {
        this.createChart();
      },
    },
    mounted() {
        window.addEventListener('mousemove', this.updateMousePosition)
      this.chartContainer = this.$refs.chartContainer;
      this.tooltip = this.$refs.tooltip;
      this.populateSiteCodes();
      this.createChart();
    },
    beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
  },
    methods: {
        updateMousePosition(event) {
      if (this.$refs.captureArea) {
      const rect = this.$refs.captureArea.getBoundingClientRect();
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    }
    },
      populateSiteCodes() {
        this.siteCodes = [...new Set(this.data.map((item) => item.siteCode))];
        if(this.siteCodes.length != 0 && this.selectedSiteCode==""){
        this.selectedSiteCode=this.siteCodes[0];
        }
      },
      updateChart() {
        this.createChart();
      },
      createChart() {
  const margin = { top: 20, right: 30, bottom: 70, left: 60 };
  const width = this.graphwidth - margin.left - margin.right;
  const height = this.graphheight - margin.top - margin.bottom;
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  // Filter data based on selected siteCode
  const selectedSiteData = this.selectedSiteCode
    ? this.data.find(item => item.siteCode === this.selectedSiteCode)
    : null;

  const filteredData = selectedSiteData && selectedSiteData.monthWiseGrouping
    ? selectedSiteData.monthWiseGrouping
    : [];

  if (!filteredData.length) {
    d3.select(this.chartContainer).selectAll("*").remove();
    d3.select(this.chartContainer)
      .append("text")
      .attr("x", width / 2)
      .attr("y", height / 2)
      .attr("text-anchor", "middle")
      .text("No data available for selected site");
    return;
  }

  // Define scales
  const xScale = d3
    .scalePoint()
    .domain(filteredData.map(d => `${d.year}-${monthNames[d.month - 1]}`))
    .range([0, width]);

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(filteredData, d => d.average)])
    .nice()
    .range([height, 0]);

  // Clear any previous chart
  d3.select(this.chartContainer).selectAll("*").remove();

  // Create SVG
  this.svg = d3
    .select(this.chartContainer)
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  // Define line generator
  const lineGenerator = d3
    .line()
    .x(d => xScale(`${d.year}-${monthNames[d.month - 1]}`))
    .y(d => yScale(d.average))
    .curve(d3.curveMonotoneX);

  // Draw the line
  this.svg
    .append("path")
    .datum(filteredData)
    .attr("fill", "none")
    .attr("stroke", "#1f77b4")
    .attr("stroke-width", 2)
    .attr("d", lineGenerator);

  // Draw points on the line
  this.svg
    .selectAll(".point")
    .data(filteredData)
    .enter()
    .append("circle")
    .attr("class", "point")
    .attr("cx", d => xScale(`${d.year}-${monthNames[d.month - 1]}`))
    .attr("cy", d => yScale(d.average))
    .attr("r", 4)
    .attr("fill", "#1f77b4")
    .on("mouseover", (event, d) => {
      d3.select(this.tooltip)
        .style("opacity", 1)
        .html(`Month: ${d.year}-${monthNames[d.month - 1]}<br/>Average: ${d.average}`)
        .style("left", `${this.mouseX}px`)
        .style("top", `${this.mouseY - 20}px`);
    })
    .on("mouseout", () => {
      d3.select(this.tooltip).style("opacity", 0);
    });

  // Add X axis
  this.svg
    .append("g")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(xScale))
    .selectAll("text")
    .attr("transform", "rotate(-45)")
    .style("text-anchor", "end");

  // Add Y axis
  this.svg.append("g").call(d3.axisLeft(yScale));

  // Add axis labels
  this.svg
    .append("text")
    .attr("x", width / 2)
    .attr("y", height + margin.bottom - 10)
    .attr("text-anchor", "middle")
    .text(this.xaxislabel);

  this.svg
    .append("text")
    .attr("transform", "rotate(-90)")
    .attr("x", -height / 2)
    .attr("y", -margin.left + 15)
    .attr("text-anchor", "middle")
    .text(this.yaxislabel);
},
      showtable() {
        this.$emit("opendrillpopup", this.data);
      },
    },
  };
  </script>
  
  <style scoped>
  .stat-card-white {
    background: #fff;
    padding: 10px;
  }
  .chart-container {
    position: relative;
  }
  .tooltip {
    position: absolute;
    text-align: center;
    width: auto;
    height: auto;
    padding: 5px;
    background: #000;
    color: #fff;
    border: 1px solid #777;
    border-radius: 3px;
    pointer-events: none;
    opacity: 0;
  }
  </style>
  