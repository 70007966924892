<template>
  <div ref="component" class="stat-card-white">
    <div class="stat-header-white">
      <span>{{ chartLabel }}</span>
      <div v-show="!loader" class="d-flex align-items-center">
        <!-- <div class="expand-block px-2 mb-1 cursor-pointer" @click="showtable">
          <img src="../../assets/img/table_view.png" alt="Data Overview" />
        </div> -->
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock = true">
          <img src="../../assets/img/expand.png" alt="Expand block" />
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stat-body-white overflow-auto">
      <div ref="chartContainerWrapper" class="w-100">
        <svg ref="chartContainer"></svg>
        <div v-if="colorcodes.length!=0" class="d-flex">
      <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
        <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
        {{ colors.type }}
      </div>
    </div>
        <div ref="tooltip" class="tooltip"></div>
      </div>
    </div>
    <enlargerpopup v-if="expandBlock" @closeModal="expandBlock = false, createChart()">
      
      <div class="popupheight d-flex flex-column col-md-12">
        <svg ref="popupChartContainer"></svg>
        <div v-if="colorcodes.length!=0" class="d-flex">
      <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
        <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
        {{ colors.type }}
      </div>
    </div>
        <div ref="tooltip" class="tooltip"></div>
      </div>
    </enlargerpopup>
  </div>
</template>

<script>
import * as d3 from "d3";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
import html2pdf from 'html2pdf.js';
export default {
  name: "MonthlyLineChart",
  components: {
    enlargerpopup,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    chartLabel: {
      type: String,
      default: "Monthly Data Analysis",
    },
    custcolors: {
      type: Array,
      default: () => ["#e74c3c", "#2ecc71"], // Red for missed, green for completed
    },
    colorcodes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showVisitPopup: false,
      chartContainer: null,
      tooltip: null,
      expandBlock: false,
      mouseX:null,
      mouseY:null
    };
  },
  mounted() {
    window.addEventListener('mousemove', this.updateMousePosition)
    this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
    this.tooltip = this.$refs.tooltip;
    this.createChart();
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
  },
  watch: {
    data: {
      handler() {
        this.createChart();
      },
      immediate: true,
      deep: true,
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          console.log("Entered")
          this.createChart();
        });
      }
    }
  },
  methods: {
    exporttopdf() {
      // Create a temporary container
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.component.cloneNode(true);
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '100%';

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: `${this.chartLabel}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    updateMousePosition(event) {
      // Get the bounding rectangle of the component
      const rect = this.$refs.component.getBoundingClientRect();
      if (!rect) {
        console.error("Component reference is not defined or not yet mounted.");
        return;
      }
    
      // Calculate the mouse X and Y positions relative to the component
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    },
    createChart() {
      this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
  if (!this.chartContainer) return;

  d3.select(this.chartContainer).selectAll("*").remove();

  // Validate data
  if (!Array.isArray(this.data)) {
    console.warn("Data is not an array:", this.data);
    return;
  }
  const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  // Extract monthly data
  const monthlyData = this.data
    .map(d => d.monthlyData || [])
    .flat()
    .map(m => ({
      // monthYear: `${m.year}-${String(m.month).padStart(2, "0")}`,
      monthYear: `${m.year}-${monthShortNames[parseInt(m.month, 10) - 1]}`,
      missed: m.missed || 0,
      completedOnTime: m.completedOnTime || 0,
    }));

  if (monthlyData.length === 0) {
    console.warn("No valid data for chart.");
    return;
  }

  // Chart dimensions
  const margin = { top: 20, right: 60, bottom: 50, left: 60 };
  const width = 600 - margin.left - margin.right;
  const height = 380 - margin.top - margin.bottom;

  // Scales
  const xScale = d3
    .scalePoint()
    .domain(monthlyData.map(d => d.monthYear))
    .range([0, width])
    .padding(0.5);

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(monthlyData, d => Math.max(d.missed, d.completedOnTime))])
    .nice()
    .range([height, 0]);

  const svg = d3
    .select(this.chartContainer)
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom +10)
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  // Line generators
  const lineMissed = d3
    .line()
    .x(d => xScale(d.monthYear))
    .y(d => yScale(d.missed))
    .curve(d3.curveMonotoneX);

  const lineCompleted = d3
    .line()
    .x(d => xScale(d.monthYear))
    .y(d => yScale(d.completedOnTime))
    .curve(d3.curveMonotoneX);

  // Add lines
  svg.append("path")
    .datum(monthlyData)
    .attr("fill", "none")
    .attr("stroke", "#e74c3c") // Red for missed
    .attr("stroke-width", 2)
    .attr("d", lineMissed);

  svg.append("path")
    .datum(monthlyData)
    .attr("fill", "none")
    .attr("stroke", "#2ecc71") // Green for completed
    .attr("stroke-width", 2)
    .attr("d", lineCompleted);

  // Add dots for missed
  svg.selectAll(".dot-missed")
    .data(monthlyData)
    .enter()
    .append("circle")
    .attr("class", "dot-missed")
    .attr("cx", d => xScale(d.monthYear))
    .attr("cy", d => yScale(d.missed))
    .attr("r", 5)
    .attr("fill", "#e74c3c") // Red
    .on("mouseover", (event, d) => {
      d3.select(this.tooltip)
        .style("opacity", 1)
        .html(`Missed: ${d.missed}<br/>Month: ${d.monthYear}`)
        .style("left", `${this.mouseX + 5}px`)
        .style("top", `${this.mouseY - 28}px`);
    })
    .on("mouseout", () => d3.select(this.tooltip).style("opacity", 0));

  // Add dots for completedOnTime
  svg.selectAll(".dot-completed")
    .data(monthlyData)
    .enter()
    .append("circle")
    .attr("class", "dot-completed")
    .attr("cx", d => xScale(d.monthYear))
    .attr("cy", d => yScale(d.completedOnTime))
    .attr("r", 5)
    .attr("fill", "#2ecc71") // Green
    .on("mouseover", (event, d) => {
      d3.select(this.tooltip)
        .style("opacity", 1)
        .html(`Completed: ${d.completedOnTime}<br/>Month: ${d.monthYear}`)
        .style("left", `${this.mouseX + 5}px`)
        .style("top", `${this.mouseY - 28}px`);
    })
    .on("mouseout", () => d3.select(this.tooltip).style("opacity", 0));

  // Add X-axis
  svg.append("g")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(xScale).tickFormat(d => d))
    .selectAll("text")
    .attr("transform", "rotate(-45)")
    .style("text-anchor", "end");

  // Add Y-axis
  svg.append("g").call(d3.axisLeft(yScale));

  // Add axis labels
  svg.append("text")
    .attr("x", width / 2)
    .attr("y", height + margin.bottom + 10)
    .attr("text-anchor", "middle")
    .text("Month-Year");

  svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("x", -height / 2)
    .attr("y", -margin.left + 15)
    .attr("text-anchor", "middle")
    .text("Count");
},

  },
};
</script>

<style scoped>
@import "../../custom_lib/AiCharts/charts.css";
.chart-container {
  width: 100%;
  height: auto;
}
.tooltip {
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  padding: 5px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 3px;
  pointer-events: none;
  font-size: 12px;
  opacity: 0;
  z-index: 10;
}
.legandbox {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}
</style>
