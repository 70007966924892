<template>

            <!-- Navbar Section -->
            <div class="row border-bottom white-bg">
                <nav class="navbar navbar-static-top" role="navigation">
                  <div class="landing-nav-header"><img src="../../assets/img/IDSLogo.png" alt="Logo" />
                  </div >
                        <ul class="d-flex justify-content-end align-items-center flex-grow-1 py-0 px-2 my-0" role="list">
                            <!-- <li>
                                <a href="login.html">
                                    <i class="fa fa-sign-out"></i> Log out
                                </a>
                            </li> -->
                            <li
                              class="px-2 px-md-0 user-profile cursor-pointer"
                              aria-label="User profile"
                              tabindex="0"
                            >
                              <a
                                class="user-block px-0"
                                @click.prevent="displayProfile()"
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                <img
                                  :src="usersdetails.picture"
                                  class="img-fluid profile-pic"
                                  alt="User Profile Image"
                                />
                                <div class="tool-tip">
                                  {{ $t("navigations.user profile") }}
                                </div>
                              </a>
                              <div class="profile-block animate fadeIn text-start">
                                <div
                                  class="login-user-details d-flex justify-content-start align-items-center"
                                >
                                  <div class="login-user-details--image">
                                    <img
                                      class="profile-pic me-2"
                                      :src="usersdetails.picture"
                                      alt="User Image"
                                    />
                                  </div>
                                  <div class="login-user-details--details d-flex flex-column">
                                    <span
                                      ><b>{{ usersdetails.given_name }}</b></span
                                    >
                                    <span>{{ usersdetails.name }}</span>
                                  </div>
                                </div>
                                <div class="user-details d-flex flex-column">
                                  <div
                                    class="theme-change-block d-flex justify-content-start align-items-center"
                                    @click.prevent="$router.push('/profileSettings')"
                                  >
                                    <img
                                      class="me-2"
                                      src="../../assets/img/user_profile.png"
                                      alt="User Profile"
                                    />
                                    <span>{{ $t("navigations.profile") }}</span>
                                </div>
                                  <div
                                    class="theme-change-block d-flex justify-content-start align-items-center"
                                    @click="expandSidebar()"
                                  >
                                    <img
                                      class="me-2"
                                      src="../../assets/img/user_themes.png"
                                      alt="User Themes"
                                    />
                                    <span class="theme-change-block">{{
                                      $t("navigations.themes")
                                    }}</span>
                                    <div class="theme-menu animated fadeIn">
                                      <div class="title">Themes</div>
                                      <div
                                        class="setings-item default-skin"
                                        @click="switchTheme('theme-default')"
                                      >
                                        <span class="skin-name">
                                          <a href="#" class="md-skin"> Default </a>
                                        </span>
                                      </div>
                                      <!-- <div class="setings-item blue-skin" @click="themeBlack()">
                                            <span class="skin-name ">
                                              <a href="#" class="s-skin-1">
                                                Black light
                                              </a>
                                            </span>
                                          </div> -->
                                      <div
                                        class="setings-item teal-skin"
                                        @click="switchTheme('theme-green')"
                                      >
                                        <span class="skin-name">
                                          <a href="#" class="md-skin"> Teal Green </a>
                                        </span>
                                      </div>
                                      <div
                                        class="setings-item dusty-skin"
                                        @click="switchTheme('theme-violet')"
                                      >
                                        <span class="skin-name">
                                          <a href="#" class="md-skin"> Dusty Lavender </a>
                                        </span>
                                      </div>
                                      <div
                                        class="setings-item ocean-skin"
                                        @click="switchTheme('theme-ocian')"
                                      >
                                        <span class="skin-name">
                                          <a href="#" class="md-skin"> Ocean Green </a>
                                        </span>
                                      </div>
                                      <div
                                        class="setings-item stormy-skin"
                                        @click="switchTheme('theme-stormy')"
                                      >
                                        <span class="skin-name">
                                          <a href="#" class="md-skin"> Stormy Cloud </a>
                                        </span>
                                      </div>
                                      <div
                                        class="setings-item pastel-skin"
                                        @click="switchTheme('theme-pastel')"
                                      >
                                        <span class="skin-name">
                                          <a href="#" class="md-skin"> Pastel Russet </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <span class="dropdown-divider"></span>
                                  <div
                                    class="theme-change-block d-flex justify-content-start align-items-center"
                                    @click.prevent="logout"
                                  >
                                    <img
                                      class="me-2"
                                      src="../../assets/img/logout.png"
                                      alt="Logout"
                                    />
                                    <span>{{ $t("navigations.logout") }}</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                        </ul>
                </nav>
            </div>
            <!-- Main Content Section -->
            <div class="wrapper wrapper-content animated fadeInRight">
                <div class="ibox-content lp_ic pb-0">
                    <div class="row">
                        <!-- AI/ML Box -->
                        <div class="col-12 col-lg-3 col-md-6">
                          <router-link to="/PatientScreeningReport" @click.prevent="setstatus('AI/Ml')">
                              <div class="ibox">
                                  <div class="ibox-content landing-box">
                                      <div class="landing-imitation">
                                          <img src="../../assets/img/ai-ml-patient-enrollment.png" alt="AI/ML">
                                      </div>
                                      <div class="landing-desc">
                                          <h4 class="text-muted">{{ $t('navigations.ai/ml') }}</h4>
                                          <p class="m-t-xs">
                                              AI/ML for predictive modeling, trend analysis and decision-making.
                                          </p>
                                      </div>
                                  </div>
                              </div>
                            </router-link>
                        </div>
                        <!-- EDC Box -->
                        <div class="col-12 col-lg-3 col-md-6">
                            <router-link to="/home">
                                <div class="ibox">
                                    <div class="ibox-content landing-box">
                                        <div class="landing-imitation">
                                            <img src="../../assets/img/edc_system.png" alt="EDC">
                                        </div>
                                        <div class="landing-desc">
                                            <h4 class="text-muted">{{ $t('navigations.edc') }}</h4>
                                            <p class="m-t-xs">
                                                Electronic Data Capture (EDC) offers a complete solution for trial data.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                              </router-link> 
                        </div>
                         <!-- IDS Stats Box -->
                        <div class="col-12 col-lg-3 col-md-6">
                            <a href="https://ids-stats.datamatica.uk/">
                                <div class="ibox">
                                    <div class="ibox-content landing-box">
                                        <div class="landing-imitation">
                                            <img src="../../assets/img/IDS_Stat_Logo.png" alt="IDS Stats">
                                        </div>
                                        <div class="landing-desc">
                                            <h4 class="text-muted">{{ $t('navigations.ids stats') }}</h4>
                                            <p class="m-t-xs">
                                                Validated statistical modules using SAS, R and Python for data analysis.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                              </a>
                        </div>
                        <!-- Analytical Reports Box -->
                        <div class="col-12 col-lg-3 col-md-6">
                          <router-link to="/AnalyticalDashboard" @click.prevent="setstatus('Analytical')">
                                <div class="ibox">
                                    <div class="ibox-content landing-box">
                                        <div class="landing-imitation">
                                            <img src="../../assets/img/analytical_dashboard_icon.png" alt="Analytical Reports">
                                        </div>
                                        <div class="landing-desc">
                                            <h4 class="text-muted">{{ $t('navigations.analytical reports') }}</h4>
                                            <p class="m-t-xs">
                                                Analytical Dashboard with real-time metrics and enables faster, data-driven decisions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                          </router-link>
                        </div>
                       
                        <div class="col-12 col-lg-3 col-md-6">
                          <router-link to="/studymonitoring" @click.prevent="setstatus('clinical')">
                                <div class="ibox">
                                    <div class="ibox-content landing-box">
                                        <div class="landing-imitation">
                                            <img src="../../assets/img/trial_monitoring.png" alt="IDS Clinical Monitoring">
                                        </div>
                                        <div class="landing-desc">
                                            <h4 class="text-muted">{{ $t('navigations.clinical trial monitoring') }}</h4>
                                            <p class="m-t-xs">
                                              Streamlines monitoring, ensures compliance and tracks trial progress in real-time.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script src="./landingPage.js"></script>
<style>
.profile-block {
  position: absolute;
  color: var(--bs-dark);
  font-family: var(--bs-font-sans-serif);
  background-color: #ffffff;
  width: max-content;
  border: medium none;
  border-radius: 3px;
  display: none;
  font-size: 12px;
  top: 60px;
  right: 10px;
  text-shadow: none;
  z-index: 1000;
  box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15),
    0 4.5px 13.5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15),
    0 4.5px 13.5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15),
    0 4.5px 13.5px rgba(0, 0, 0, 0.08);
}
.user-profile:hover .tool-tip{
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.landing-nav-header{
  padding:14px 25px;
  background-color: #f2f2f2;
}
</style>