/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
const idtoken = store.getters.getIdToken;
export default {
  name: "templatelist",
  components: {
    VPagination,
  },
  data() {
    return {
      searchdata: {
        templateName: "",
        createdDate: "",
        status: ""
      },
      showSidePopup: [false],
      showFilter: false,
      dropdownOpen: false,
      selectedcolumnobj:{},
      defaultdate: "",
      templatearray: [],
      selectedversion: "",
      selectedtemplate: "",
      selectedstudy: "",
      selectedmodelvisit: "",
      modelvisits: "",
      modelstudy: "",
      templateList: [],
      formName: "",
      searchFormName: "",
      pageindex: 1,
      totalitems: 0,
      totalpages: 0,
      enableNext: true,
      enablePrevious: true,
      currentPage: 1,
      errors: [false, false],

      baseUrl: `${process.env.VUE_APP_Service_URL}/forms/template/`,
      baseapi: process.env.VUE_APP_Service_URL,
      headerValue: {},
      rollsprivilages :"",
    };
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into " + event.detail.storage);
      // window.location.reload();
      this.idToken = store.getters.getIdToken;
      this.GetData(event.detail.storage);
      //this.getstudy();
      this.filter();
      this.rollsprivilages = store.getters.getRolesprivilegeData;
    });
    const idtoken = store.getters.getIdToken;
    this.headerValue = {
      headers: {
        Authorization: "Bearer " + idtoken,
        "Content-Type": "application/json",
      },
    };
    this.searchFormName = this.formName;
    console.log("in mounted");
    this.GetData(store.getters.getStudyIs);
    //this.getstudy();
    this.filter();
    this.rollsprivilages = await store.getters.getRolesprivilegeData;

  },
  watch: {
    templateList: {
      handler() {
        this.initializeColumns()
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    initializeColumns() {
      if (this.templateList.length > 0) {
        const allowedKeys = [
         "templateName",
         "language",
         "version",
         "createdDate",
         "status"
        ]
        this.getColumns= Object.keys(this.templateList[0])
        .filter(column => allowedKeys.includes(column));       
        const fieldLabels = {
         templateName:"Template Name",
         language:"Language",
         version:"Version",
         createdDate:"Created Date",
         status:"Status"
        };

        const readableData = allowedKeys.map(key => fieldLabels[key] || key);
         
        this.getColumns=readableData
        this.selectedColumns = [...this.getColumns];
        this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {});
      }
    },
    async onChange(event) {
      // alert(event.target.value);
      let stdid = event.target.value;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/forms/template/paggedstudytemplate?Study=${stdid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          // console.log(res);
          await this.setselectedtemplate(res.data.data);
          // this.modelstudy = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async createNewVersion(templateFormId, version, sourceTemplateId, templateId) {
      localStorage.setItem('isFormTemplateRoute', true);
      localStorage.setItem('templateFormId', templateFormId);
      localStorage.setItem('version', version);
      localStorage.setItem('sourceTemplateId', sourceTemplateId);
      localStorage.setItem('latestTempId', templateId)
      localStorage.setItem('DesignFormPrvRoute',"formTemplates");
      this.$router.push(`/formdesigner`);
    },
    async editForm(templateFormId, version, sourceTemplateId) {
      localStorage.setItem('isFormTemplateRoute', true);
      localStorage.setItem('templateFormId', templateFormId);
      localStorage.setItem('version', version);
      localStorage.setItem('sourceTemplateId', sourceTemplateId);
      localStorage.setItem('DesignFormPrvRoute',"formTemplates");
      localStorage.setItem('isFormtempEdit','true')
      this.$router.push(`/formdesigner`);
    },
    async createTemplate(templateFormId, version, sourceTemplateId) {
      localStorage.setItem('isFormCreateTemplateRoute', true);
      localStorage.setItem('templateFormId', templateFormId);
      localStorage.setItem('version', version);
      localStorage.setItem('sourceTemplateId', sourceTemplateId);
      localStorage.setItem('DesignFormPrvRoute',"formTemplates");
      this.$router.push(`/formdesigner`);
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate)
      }
    },
    async studychange(studyvalue) {
      this.getvisites(studyvalue);
    },
    async getvisites(currentstudy) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/visit/getallvisit?studyId=${currentstudy}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.modelvisits = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async GetData() {
      const idtoken = store.getters.getIdToken;
      const studyId = store.getters.getStudyIs;
      await axios
        .get(this.baseUrl + `listtemplate?StudyID=${studyId}&pageindex=` + this.pageindex + `&pagesize=10&SortOrder=0&api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data) {
            this.totalitems = Math.ceil(res.data.totalCount / 10);
            this.templateList = res.data.results;
            console.log("Template List", this.templateList)
          } else {
            console.log("no temlate found");
          }

        })
        .catch((err) => {
          console.log(err);
          this.enableNext = false;
          this.enablePrevious = false;
        });

    },
    async setsearchdata(){
      this.pageindex=1;
      this.currentPage = 1;
      this.searchData();

    },
    async searchData() {
      const studyId = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          this.baseUrl + "listtemplate?StudyID=" + studyId + "&TemplateName=" + this.searchdata.templateName + "&Status=" + this.searchdata.status + "&createdDate=" + this.searchdata.createdDate + "&pageindex=" + this.pageindex + "&pagesize=10&SortOrder=0&api-version=1.0",
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data) {
            this.totalitems = Math.ceil(res.data.totalCount / 10);
            this.templateList = res.data.results;
          } else {
            console.log("no study found");
          }
        })
        .catch((err) => {
          console.log(err);
          this.enableNext = false;
          this.enablePrevious = false;
        });
    },
    async getPreviouspage() {
      if (this.pageindex > 1) {
        if (this.formName == "") {
          this.pageindex = this.pageindex - 1;
          this.GetData();
        }
        else {
          this.pageindex = this.pageindex - 1;
          this.searchData();
        }
      }
    },
    async getNextpage() {
      if (this.pageindex < this.totalitems) {
        if (this.formName == "") {
          this.pageindex = this.pageindex + 1;
          this.GetData();
        }
        else {
          this.pageindex = this.pageindex + 1;
          this.searchData();
        }
      }
    },
    async viewforms(templateId) {
      this.$router.push(`/viewTemplate/studytemplate/${templateId}`);
    },
    validateIsNull() {
      if (this.selectedmodelstudy == "") {
        this.errors[0] = true;
      }
      else {
        this.errors[0] = false;
      }

      if (this.selectedmodelvisit == "") {
        this.errors[1] = true;
      }
      else {
        this.errors[1] = false;
      }
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageindex = page;
      this.searchData();
    }
  },
};