<template>
  <div class="stat-card-white" ref="captureArea">
    <div class="stat-header-white d-flex justify-content-between">
      <span>{{ chartLabel }}</span>
      <div v-show="!loader" class="d-flex align-items-center w-100 justify-content-end">
        <!-- <div class="expand-block px-2 mb-1 cursor-pointer" @click="showtable">
          <img src="../../assets/img/table_view.png" alt="Data Overview" />
        </div> -->
        <label class="mb-0 mr-1">Site Code</label>
        <select class="graph-select py-0 col-md-6" v-model="SiteCode" id="fieldSelect" @change="emitsiteid()">
          <option v-for="site in sites" :key="site.siteID" :value="site.siteID">
              {{ site.siteCode }}
            </option>
        </select>
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock = true">
          <img src="../../assets/img/expand.png" alt="Expand block" />
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="stat-body-white d-flex flex-column" :class="{ 'h-100': loader }">
      <div v-if="loader" class="loader"></div>
      <div v-if="dataArray && dataArray.length > 0" class="col-md-12 d-flex">
          <div class="col-md-6 font-weight-bold d-flex align-items-center">
            <span>% of Missed Visits:</span>
            <small class="mv_label ml-1">
              {{ dataArray[0].totalMissedPercentile != null ? dataArray[0].totalMissedPercentile.toFixed(2) + '%' : 'N/A' }}
            </small>
          </div>
          <div class="col-md-6 font-weight-bold d-flex align-items-center">
            <span>% of Completed Visits:</span>
            <small class="cv_label ml-1">
              {{ dataArray[0].totalCompletedPercentile != null ? dataArray[0].totalCompletedPercentile.toFixed(2) + '%' : 'N/A' }}
            </small>
          </div>
        </div>
      <div ref="barChart" class="chart-container" v-show="!loader"></div>
      <div ref="tooltip" class="tooltip"></div>
    </div>
    <div v-if="colorcodes.length!=0" class="d-flex">
      <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
        <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
        {{ colors.type }}
      </div>
    </div>
    <enlargerpopup v-if="expandBlock" @closeModal="expandBlock = false, createBarChart()">
      
      <div class="popupheight d-flex flex-column col-md-12">
        <div v-if="dataArray && dataArray.length > 0" class="col-md-12 d-flex">
          <div class="col-md-6 font-weight-bold d-flex align-items-center">
            <span>% of Missed Visits:</span>
            <small class="mv_label ml-1">
              {{ dataArray[0].totalMissedPercentile != null ? dataArray[0].totalMissedPercentile.toFixed(2) + '%' : 'N/A' }}
            </small>
          </div>
          <div class="col-md-6 font-weight-bold d-flex align-items-center">
            <span>%Completed Visits:</span>
            <small class="cv_label ml-1">
              {{ dataArray[0].totalCompletedPercentile != null ? dataArray[0].totalCompletedPercentile.toFixed(2) + '%' : 'N/A' }}
            </small>
          </div>
        </div>
        <div ref="popupChartContainer" class="chart-container popupconatiner"></div>
      </div>
    </enlargerpopup>
  </div>
</template>

<script>
import * as d3 from "d3";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
import html2pdf from 'html2pdf.js';
import axios from "axios";
import store from '@/store';
export default {
  name: "HorizontalBarChart",
  components: {
    enlargerpopup,
  },
  props: {
    dataArray: {
      type: Array,
      default: () => [],
    },
    chartLabel: {
      type: String,
      default: "Data Overview",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    colorcodes: {
      type: Array,
      default: () => [],
    },
    yaxislabelsobj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      basetestUrl: process.env.VUE_APP_Service_URL,
      showVisitPopup: false,
      expandBlock: false,
      tooltip: null,
      mouseX:null,
      mouseY:null,
      sites: "",
      SiteCode: "", 
    };
  },
  watch: {
    dataArray: {
      handler(newData) {
        console.log("getqueryreports",this.dataArray[0]);
        if (Array.isArray(newData) && newData.length > 0) {
          this.createBarChart();
        }
      },
      immediate: true,
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          console.log("Entered")
          this.createBarChart();
        });
      }
    }
  },
  async mounted() {
    await this.getsites();
    window.addEventListener('mousemove', this.updateMousePosition)
    this.container = this.$refs.barChart;
    this.tooltip = this.$refs.tooltip;
    this.createBarChart();
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
  },
  methods: {
    exporttopdf() {
      // Create a temporary container
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.captureArea.cloneNode(true);
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '100%';

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: `${this.chartLabel}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    updateMousePosition(event) {
      // Get the bounding rectangle of the component
      const rect = this.$refs.captureArea.getBoundingClientRect();
      if (!rect) {
        console.error("Component reference is not defined or not yet mounted.");
        return;
      }
    
      // Calculate the mouse X and Y positions relative to the component
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    },
    showtable() {
      this.$emit("opendrillpopup", this.dataArray);
    },
    async getsites() {
      const currentstudy = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      
      await axios
        .get(
          `${this.basetestUrl}/management/site/getallsitesbyuser?studyId=${currentstudy}&sortProperty=siteCode&IsDes=false&getAll=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sites = res.data;
          this.SiteCode = this.sites[0].siteID;
          this.$emit("sitecodeemit", this.SiteCode);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emitsiteid(){
      this.$emit("sitecodeemit", this.SiteCode);
    },
    createBarChart() {
      this.container = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.barChart;

      if (!this.container || this.container.clientWidth === 0) {
        setTimeout(this.createBarChart, 100);
        return;
      }

      d3.select(this.container).selectAll("*").remove();

      // Extract the data for the chart
      const plotData = this.dataArray.map((d) => [
        { name: "Total Count", value: d.totalCount },
        { name: "Total Missed", value: d.totalMissed },
        { name: "Total Completed", value: d.totalCompleted },
      ]).flat();

      const margin = { top: 20, right: 20, bottom: 30, left: 150 };
      const width = this.container.clientWidth - margin.left - margin.right;
      const height = 280;

      const x = d3.scaleLinear().range([0, width]).domain([0, d3.max(plotData, (d) => d.value)]);
      const y = d3.scaleBand().range([0, height]).padding(0.1).domain(plotData.map((d) => d.name));
      

      const colors = d3.scaleOrdinal(["#3498db", "#e74c3c", "#2ecc71"]);

      const svg = d3
        .select(this.container)
        .append("svg")
        .attr("viewBox", `80 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Add x-axis
      svg.append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x).ticks(5));

      // Add y-axis
      // svg.append("g").attr("class", "y axis").call(d3.axisLeft(y));
      svg.append("g")
  .attr("class", "y axis")
  .call(d3.axisLeft(y))
  .selectAll("text") 
  .style("text-anchor", "end") 
  .attr("transform", "rotate(-45)") 
  .attr("dx", "-0.8em") 
  .attr("dy", "0.15em") 
  .text((d) => {
    const dataPoint = plotData.find((item) => item.name === d);
    // return `${d} (${dataPoint ? dataPoint.value : 0} visits)`;
    if(d in this.yaxislabelsobj){
    return this.yaxislabelsobj[d];
    }
    else{
      return d;
    }
  });

      svg.append("text")
    .attr("x", width / 2)
    .attr("y", height + margin.bottom)
    .attr("text-anchor", "middle")
    .text("No Of Visits");

      // Add bars
      svg.selectAll(".bar")
        .data(plotData)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", 0)
        .attr("y", (d) => y(d.name))
        .attr("width", (d) => x(d.value))
        .attr("height", y.bandwidth())
        .attr("fill", (d, i) => colors(i))
        .on("mouseover", (event, d) => {
          d3.select(this.tooltip)
            .style("opacity", 1)
            .html(`${d.name}: ${d.value}`)
            .style("left", `${this.mouseX}px`)
            .style("top", `${this.mouseY - 28}px`);
        })
        .on("mouseout", () => {
          d3.select(this.tooltip).style("opacity", 0);
        });
    },
  },
};
</script>

<style scoped>
@import "../../custom_lib/AiCharts/charts.css";
.chart-container {
  width: 100%;
  height: auto;
}

.bar {
  fill-opacity: 0.8;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}
.mv_label{
  border-radius: 10px;
  background-color: #ec7063;
  padding: 4px 6px;
  font-weight: 700;
  font-size: 13px;
  color:#ffffff;
}
.cv_label{
  border-radius: 10px;
  background-color: #58d68d;
  padding: 4px 6px;
  font-weight: 700;
  font-size: 13px;
  color:#ffffff;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tooltip {
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  padding: 5px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 3px;
  pointer-events: none;
  font-size: 12px;
  opacity: 0;
  z-index: 10;
}
.legandbox {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}
</style>
