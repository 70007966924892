/* eslint-disable */
import axios from "axios";
import moment from 'moment';
import store from "../../store/index";
import jwt_decode from "jwt-decode";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import AddApproverLibrary from "../AddApproverLibrary/AddApproverLibrary.vue";
import listApproverPopup from "../listApprover/listApproverPopup.vue";
import DocumentPopup from "../DocumentPopup/DocumentPopup.vue";
// import from 'jstree'
export default {

    components: {
     // TreeBrowser,
      VPagination,
      DocumentPopup,
      AddApproverLibrary,
      listApproverPopup,
      },
  data() {
    return {
      studyname:"",
      siteCount:"",
      pendingapprovallist:[],
      recentstudylist:[],
      approvedCount:"",
      pendingapprovalcount:"",
      generaldocscount:"",
      documentTypeId:"",
      general:"general",
      dstudyid:"",
      calldetails: "update",
      doceditid: "",
      documentPopupId: "",
      Description: "",
      uploadpopupmodal: false,
      ShowDocName: true,
      currentdocId: "",
      Document: "",
      loaddata: false,
      documentName: "",
      versionNumber: "",
      currentPage: 1,
      doccurrentPage:1,
      docpageindex:1,
      pageindex: 1,
      totalPage: 1,
      doctotalPage: 1,
      popactive: false,
      popupmodal: false,
      values: {
        FolderName: "",
        Approver: [],
        AncestorIds: [],
        ParentId: "",
        storageId: "",
      },
      SearchData: {
        DocName: "",
        FolderId: "",
        DocsearchName: "",
        CreatedDate: "",
        UploadedBy: "",
        DocumentTypeId: "",
        pageNo: 1,
        pageSize: 10,
      },
      doclist: [],
      showFolder: false,
      pagesize: 10,
      totalCount:0,


      sites:[],
      fileuploadurl: process.env.VUE_APP_File_upload_URL+"/document",
      baseurl: process.env.VUE_APP_Service_URL,
      baseapi: process.env.VUE_APP_Service_URL,
      
      patientLists:[],
      visitobj: {},
      defaultdate: "",
      roleprivileges: "",
      pageformindex: 1,
      pageformsize: 10,
      currentformPage: 1,
      totalformCount: 0,
      totalformPages : 0,
      roleprivileges:"",      
      currentRecentformPage:1,
      totalformsPages:0,
      recentpageformindex:1,
      recentpageformsize:10,
      SortProperty:0,
      SortOrder:0,
      pendingSortOrder:0,
      showFilter:false,
      dropdownOpen:false,
      selectedcolumnobj:{},
      showRecentFilter:false,
      dropdownRecentOpen:false,
      selectedcolumnRecentobj:{},
      showDocumentsFilter:false,
      dropdownDocumentsOpen:false,
      selectedcolumnDocumentsobj:{},
    };
  }, 


  async mounted() {
    console.log("StudyIDChanged into");
   // this.defaultdate = localStorage.getItem("dateformat");
    window.addEventListener("studyIdChanged", async(event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      this.roleprivileges = store.getters.getRolesprivilegeData;
      await this.getrecentstudylist();
      this.initializeRecentColumns()
      this.getsites();
      await this.getDocuments();
      this.initializeDocumentsColumns()
      this.getstudyname();
      await this.getPendingapprovalcount();
      this.initializeColumns()
      this.getApprovedcount();
      this.getGeneralDocumentscount();
      this.getSiteCount();
      
      
    });
    this.roleprivileges = await store.getters.getRolesprivilegeData;
    await this.getrecentstudylist()
    this.initializeRecentColumns()
    await this.setroleprivileges();
    this.getsites();
    await this.getDocuments();
    this.initializeDocumentsColumns()
    this.getstudyname();
    await this.getPendingapprovalcount();
    this.initializeColumns()
    this.getApprovedcount();
    this.getGeneralDocumentscount();
    this.getSiteCount();
    
    
  },
  methods: {
    async initializeColumns() {
      if (this.pendingapprovallist.length > 0) {
        const allowedKeys = [ 
          "documentName",
          "approvedByName",
          "assignedByName",
          "assignedDateTime"
        ];
        this.getColumns= Object.keys(this.pendingapprovallist[0])
        .filter(column => allowedKeys.includes(column));       
        const fieldLabels = {
          documentName:"Document",
          approvedByName:"Approver",
          assignedByName:"Assigned By",
          assignedDateTime:"Assigned Date"
        }

        const readableData = allowedKeys.map(key => fieldLabels[key] || key);
          
        this.getColumns=readableData
        this.selectedColumns = [...this.getColumns];
        this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {});
      }
    },
    async initializeRecentColumns() {
      if (this.recentstudylist.length > 0) {
        const allowedKeys = [ 
          "documentName",
          "documentType",
          "typeValue",
          "createdUserName",
          "CreatedAt"
        ];
        this.getColumns= Object.keys(this.recentstudylist[0])
        .filter(column => allowedKeys.includes(column));       
        const fieldLabels = {
          documentName:"Document",
          documentType:"Document Type",
          typeValue:"Document Value",
          createdUserName:"Created By",
          CreatedAt:"Created On"
        }

        const readableData = allowedKeys.map(key => fieldLabels[key] || key);
          
        this.getRecentColumns=readableData
        this.selectedRecentColumns = [...this.getRecentColumns];
        this.selectedcolumnRecentobj = this.selectedRecentColumns.reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {});
      }
    },
    async initializeDocumentsColumns() {
      if (this.doclist.length > 0) {
        console.log("Entered")
        const allowedKeys = [ 
          "documentName",
          "createdUser",
          "CreatedAt"
        ];
        this.getDocumentsColumns= Object.keys(this.doclist[0])
        .filter(column => allowedKeys.includes(column));       
        const fieldLabels = {         
          documentName:"Document",
          createdUser:"Created By",
          CreatedAt:"Created Date"
        }

        const readableData = allowedKeys.map(key => fieldLabels[key] || key);
          
        this.getDocumentsColumns=readableData
        this.selectedDocumentsColumns = [...this.getDocumentsColumns];
        this.selectedcolumnDocumentsobj = this.selectedDocumentsColumns.reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {});
      }
    },
    async getstudyname(){
      const currentstudyid = store.getters.getStudyIs;
      console.log("study id is" + currentstudyid);
      const idtoken = store.getters.getIdToken;
     
      await axios
				.get(
          `${this.baseapi}/management/study/getbyid/${currentstudyid}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
        .then((res) => {
          this.studyname = res.data.studyRef;
          this.DocumentTypeId = res.data.studyId
        console.log("IS study data binded",  res.data.studyRef );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async uploadmethodactivity(){     
      this.uploadpopupmodal = false;
      this.getDocuments();
      //this.$router.go(0);
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged", sortProp);
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.getPendingapprovalcount();
    },
    SortSelected1(sortProp, id) {
      console.log("SortPropChanged", sortProp);
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.getrecentstudylist();
    },
    SortSelected2(sortProp, id) {
      console.log("SortPropChanged", sortProp);
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.getDocuments();
    },
    async getrecentstudylist(){
      this.recentstudylist ="";
      const idtoken = store.getters.getIdToken;
      const currentstudyid = store.getters.getStudyIs;
      await axios
				.get(
          //`https://localhost:5001/document/getrecentlistdocument?documentTypeId=`+currentstudyid+
         `${this.baseapi}/management/document/getrecentlistdocument?documentTypeId=`+currentstudyid+
         "&pagesize=" +
          this.pagesize+
          "&SortProperty=" +
          this.SortProperty+
          "&SortOrder="+
          this.SortOrder,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
        .then((res) => {
          console.log(res)
          this.recentstudylist = res.data;
        console.log("IS recent data binded",  this.recentstudylist );
        })
        .catch((err) => {
          console.log(err);
        });

    },
    async getGeneralDocumentscount()
    {
     const idtoken = store.getters.getIdToken;
      await axios
				.get(
          `${this.baseapi}/management/document/listdocument?documentType=`+this.general+
          "&SortProperty=" +
          this.SortProperty+
          "&pagesize="+this.pagesize+
          "&SortOrder="+
          this.SortOrder,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
        .then((res) => {
          this.generaldocscount = res.data.totalCount;
        console.log("IS genrl data binded",  this.generaldocscount );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getPendingapprovalcount(){
      this.pendingapprovallist=[];
      const currentstudyid = store.getters.getStudyIs;
      this.dstudyid =  store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
				.get(
          `${this.baseapi}/management/document/pendingapprovallist?studyId=${currentstudyid}&pageindex=${this.pageindex}&pagesize=${this.pagesize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
        .then((res) => {
          console.log("resulttttttttt", res);
          this.pendingapprovallist=res.data.entities;
          this.pendingapprovalcount = res.data.totalCount;
          this.totalCount = res.data.totalCount;

          this.totalPage = parseInt(this.totalCount / this.SearchData.pageSize);
          if (this.totalCount % this.SearchData.pageSize != 0) {
            this.totalPage = this.totalPage + 1;
          }
          console.log("IS dataQQQQ binded",this.pendingapprovallist);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getApprovedcount(){
      const currentstudyid = store.getters.getStudyIs;
      this.dstudyid =  store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
				.get(
				  `${this.baseapi}/management/document/approvedlist?studyId=${currentstudyid}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
        .then((res) => {
          this.approvedCount = res.data.totalCount;
        console.log("IS the data binded", this.approvedCount);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getsites(){
      const currentstudyid = store.getters.getStudyIs;
      this.dstudyid =  store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
				.get(
          //`https://localhost:5001/site/getallsitesbyuser?studyId=${currentstudyid}&getAll=false`,
				  `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudyid}&getAll=false`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
        .then((res) => {
          this.sites = res.data;
          console.log("Sites are",this.sites)
          console.log("Current study is",currentstudyid)
          console.log(idtoken)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getpatient(siteId,index) {
      console.log("index is = "+ index);
      this.documentTypeId = siteId;
      this.patientLists[siteId]=[];
      console.log("Site id is",siteId)
      const idtoken = store.getters.getIdToken;
      const currentstudyid = store.getters.getStudyIs;
      await store.dispatch("setStorageId",siteId );
      await axios
        .get(
          `${this.baseapi}/management/patient/listpatient?SiteId=${siteId}&Studyid=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          let data = res.data.results;
                await data.forEach(patientdata => {
                  this.visitobj[patientdata.patientId] = [];
                  // console.log("patient list is",data)
                });
          this.patientLists[siteId] = data;
        });
        await this.getDocuments();
        await this.initializeDocumentsColumns()
        // await this.setvisitobj(this.patientLists[index]);
    },
    async getSiteCount(){
      const currentstudyid = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
				.get(
				  `${this.baseapi}/management/site/getsitescount?studyId=${currentstudyid}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
        .then((res) => {
          this.siteCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async setvisitobj(data){
      console.log("set visit function");
      console.log("patient list is",data);
      data.forEach(patientdata => {
          this.visitobj[patientdata.patientId] = [];
      });
      console.log(this.visitobj);
    },
    async getVisits(patientId,index)
    { 
      this.documentTypeId = patientId;
            console.log("get visit function");
      //       this.visitobj[patientId][index] = [];
      //       const idtoken = store.getters.getIdToken;
      //       console.log("patient ID is",patientId)
      
      // await axios
      // .get(
      //   `${this.baseapi}/management/patientschedule/getpatientshedule?patientID=${patientId}`,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + idtoken,
      //       "Content-Type": "application/json",
      //     },
      //   }
      // )
      // .then((res) => {
         await this.getDocuments();
         await this.initializeDocumentsColumns()
      //   this.visitobj[patientId][index] = res.data.data;
      //   console.log("The visit list is",this.visitobj)
      // })
      // .catch((err) =>{
      //     console.log("error is" , err);
      // })
      // this.displayTable=true
    },
    async getForms()
    { 
      const idtoken = store.getters.getIdToken;
      const currentstudyid = store.getters.getStudyIs;
      await axios
      .get(
        `${this.baseapi}/management/visit/listvisit?StudyId=${currentstudyid}&pageindex=${this.pageformindex}&pagesize=${this.pageformsize}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
          this.formList = res.data.results;
          console.log("The form list is",this.formList)
          this.totalformCount = res.data.totalCount;
          this.totalformPages=parseInt(this.totalformCount / this.pageformsize)
           if((this.totalformCount % this.pageformsize) !=0){
            this.totalformPages = this.totalformPages+1
          }
      })
      .catch((err) =>{
          console.log("error is" , err);
      })
      this.displayTable=true
    },
    async getstudydata(){  
     
      this.documentTypeId = this.DocumentTypeId 
      this.getDocuments();
    },
    async getDocuments() {
      this.doclist = [];
      var storageId = "";
      if(this.documentTypeId!= ""){
         storageId = this.documentTypeId;
         console.log("document id",this.documentTypeId);
      }else{
      //storageId = store.getters.getStorageId;
     // storageId = store.getters.getStudyIs;
     storageId = store.getters.getStorageId;
     console.log("document id",storageId);
      }
      //const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      console.log("searchhhhhhh", this.Document);
      await axios
        .get(
            `${this.baseapi}/management/document/getdmsreportdocumentlist?documentName=` +
          this.SearchData.DocsearchName +
            "&uploadedDate=" +
            this.SearchData.CreatedDate +
            "&uploadedBy=" +
            this.SearchData.UploadedBy +
            "&documentTypeId=" +
            storageId +
            "&pageindex=" +
            this.docpageindex +
            "&pagesize=" +
            this.pagesize+
            "&SortProperty=" +
            this.SortProperty+
            "&SortOrder="+
            this.SortOrder,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res)
          //this.documentTypeId="";
          console.log("resulttttttttt", res.data);
          this.doclist = res.data.results;
          this.doctotalCount = res.data.totalCount;
          console.log("resulttttttttt fc",  this.doclist);
          this.doctotalPage = parseInt(this.doctotalCount / this.SearchData.pageSize);
          if (this.doctotalCount % this.SearchData.pageSize != 0) {
            this.doctotalPage = this.doctotalPage + 1;
          }
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async editdocument(docId) {
      this.calldetails = "edit";
      this.doceditid = docId;
      this.documentPopupId = 0;
      this.Description = "";
      this.uploadpopupmodal = true;
    },
    filter(data) {
      if (data) {
        return moment(data).format("DD/MM/YYYY");
      }
    },
    async deleteAzureDocument(docId) {
      this.ShowDocName = false;
      console.log("get doc to delete", this.currentdocId);
      await this.getDocumentById(docId);
      console.log("value", this.Document.documentReferenceId);
      if (
        confirm(
          this.$t("navigations.are you sure you want to delete this document?")
        )
      ) {
        await axios
          .delete(
            `${this.fileuploadurl}/deletedocument?id=${this.Document.documentReferenceId}`,
            this.headerValues
          )
          .then((response) => {
            console.log(response.data);
            alert(this.$t("navigations.document deleted successfully"));
            console.log("yes");
            this.deleteDocument(docId);
          })
          .catch((err) => {
            console.log("nope", err);
          });
      }
    },
    async getDocumentById(docId) {
      this.currentdocId = docId;
      console.log("docccccccccccccc..", docId);
      console.log("docccccccccccccc..", this.currentdocId);
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/document/getdocumentbyid?id=` + docId,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.Document = res.data;
          this.loaddata = true;
          this.documentName = this.Document.documentName;
        });
    },
    async deleteDocument(docId) {
      const idtoken = store.getters.getIdToken;
      console;
      await axios
        .delete(`${this.baseurl}/management/document/delete?Id=` + docId, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          //this.getfolder(this.userid);
          this.getDocuments();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async viewDocument(docId) {
      this.ShowDocName = false;
      await this.getDocumentById(docId);
      await this.getdmsdocument(docId);
      const versionId =
        this.Document.documentVersions[
          this.Document.documentVersions.length - 1
        ].documentVersionId;
      const filename =
        this.Document.documentVersions[
          this.Document.documentVersions.length - 1
        ].fileName;
      this.$router.push(
        `/documentview/${this.Document.documentReferenceId}/${filename}/${versionId}`
      );
    },
    async getdmsdocument() {
      console.log("document download", this.Document);
      await axios
        .get(
          `${this.fileuploadurl}/getallfiles/${this.Document.documentReferenceId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,

              "Content-Type": "application/json",
              responseType: "blob",
            },
          }
        )
        .then((res) => {
          this.documentById = res.data;
          console.log("Docdownld............", this.documentById);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async AddApproverPopuup(doc, versionId, versionNumber, approvallength) {
      this.documentPopupId = doc;
      this.PopupVersionId = versionId;
      this.PopupVersionNumber = versionNumber;
      console.log("Hellooooiii", doc, versionId, approvallength);
      if (approvallength.length == 0 || approvallength == null) {
        this.popactive = true;
      } else {
        this.popupmodal = true;
      }
    },
    async downloadfile(docId) {
      this.ShowDocName = false;
      await this.getDocumentById(docId);
      await this.getdmsdocument(docId);
      const fileName =
        this.Document.documentVersions[
          this.Document.documentVersions.length - 1
        ].documentVersionId;
      console.log("get version to dwnld", fileName);
      var a = document.createElement("a");
      a.href = `${this.fileuploadurl}/file/${this.Document.documentReferenceId}?versionid=${fileName}`;
      a.download = "FILENAME";
      a.click();
    },
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
      
    },
    loadPage: function (page) {
      this.doccurrentPage = page;
      this.docpageindex = page;
      this.getDocuments();
    },
    loadPendingPage: function (page) {
      this.currentPage = page;
      this.pageindex = page;
      this.getPendingapprovalcount();
    },
  },
};
