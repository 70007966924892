<template>

  <div :class="radiogroupclass">
      {{label}}<sup v-if="requiredname == 'required'"><i class="fa fa-asterisk imp"></i></sup>
    <div class="consentradiogroupclass">
  <concestbaseradio
  v-for="option in options"  :key="option"
  :value="option.codevalue"
  :privilege="privilege || fromarchive == 'true'"
  :disable="disable"
  :label="option.optionname"
  :name="groupname"
  :modelValue="modelValue"
  @update:modelValue="$emit('update:modelValue',$event)"
  />
  </div>
</div>
</template>
<script>
/* eslint-disable */
import concestbaseradio from "./concestbaseradio";
export default{
  components: {
    concestbaseradio
  },
  props:{
    options:{
      type: Array,
      Required: true
    },
    groupname:{
      type: String,
      Required:true
    },
    label:{
      type: String,
      Required: true
    },
    modelValue:{
      type: [String, Number],
      Required: true
    },
      classname:{
        type: String,
        default: 'form-control'
      },
      requiredname:{
        type: String,
        default: ''
      },
       privilege:{
        type: String,
        default: ''
      },
       fromarchive: {
        type: String,
        default: 'false'
      },
      disable:{
        type: Boolean,
        default: false
      }
  },
   data() {
        return {
          radiogroupclass: "consentradiogroupouter mainbgchange"
        }
   },
   watch : {
               classname(val) {
                   if(val == "form-control errorlist"){
                  this.radiogroupclass = "consentradiogroupouter errorradio mainbgchange";
                   }
                   else {
                  this.radiogroupclass = "consentradiogroupouter mainbgchange";
                   }
               },
   }
}
</script>
<style scoped>
.errorradio{
  border-width: 2px !important;
  border-color: red !important;
}
.consentradiogroupclass{
  margin-left: 10px;
  display: flex;

}
.hightlight{
  /* border-color: red !important; */
  border-bottom: 6px solid #f8ac59;
  
}
.imp {
    color: #ff0000;
    font-size: 7px;
}
</style>
