<template>
  <div ref="component" class="stat-card-white">
    <div class="stat-header-white">
      <span>{{ chartLabel }}</span>
    <div class="col-md-10 d-flex justify-content-end align-items-center">
      <div class="d-flex align-items-center">
        <label class="mb-0 mr-1" for="x-axis-select">Country/Region:</label>
        <select class="graph-select mr-2" v-model="selectedCountryCode" id="x-axis-select" @change="createChart()">
          <option value="All">All</option>
          <option  v-for="options in uniquecountryCodes" :key="options" :value="options">{{ options }}</option>
        </select>
        </div>
      <div class="d-flex align-items-center">
        <label class="mb-0 mr-1" for="x-axis-select">Site code:</label>
        <select class="graph-select mr-2" v-model="selectedSiteCode" id="x-axis-select" @change="createChart">
          <option value="All">All</option>
          <option  v-for="site in uniqueSiteCodes" :key="site" :value="site">{{ site }}</option>
        </select>
        </div>
        <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
            <img @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
            <div class="more-expand">
              <span>{{$t("navigations.data overview")}}</span>
            </div>
          </div>
      <div v-if="!loader" class="d-flex align-items-center">
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock = true">
          <img src="../../assets/img/expand.png" alt="Expand block" />
        </div>
        <div v-on:clickout="showVisitPopup = false">
          <div class="cursor-pointer biggerfont ml-2" @click.prevent="showVisitPopup = !showVisitPopup">
            <i class="fa fa-ellipsis-v"></i>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="stat-body-white">
      <!-- Dropdown for siteCode -->

      <div v-if="loader" class="loader"></div>
      <div ref="chartContainerWrapper" class="w-100">
        <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
        <svg ref="chartContainer" v-show="!loader"></svg>
        <div v-if="colorcodes.length!=0" class="d-flex">
          <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
          <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
          {{ colors.type }}
          </div>
        </div>
        <div ref="tooltip" class="tooltip"></div>
      </div>
    </div>
    <enlargerpopup v-if="expandBlock" @closeModal="expandBlock = false; createChart()">
      <div class="stat-body-white d-flex flex-column">
        <svg ref="popupChartContainer"></svg>
      </div>
    </enlargerpopup>
  </div>
</template>
  
  <script>
  import * as d3 from 'd3';
import axios from "axios";
import store from "../../store/index";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue"
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
export default {
  name: "comparativeLineChart",
  components: {
    enlargerpopup,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    xAxisValue: {
      type: String,
      default: "createdAt",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    yAxisValue: {
      type: String,
      default: "",
    },
    groupCategoryValue: {
      type: String,
      default: "",
    },
    chartLabel: {
      type: String,
      default: "Comparative Analysis",
    },
    optionstochose: {
      type: Array,
      default: () => ["siteCode", "country"],
    },
    custcolors: {
      type: Array,
      default: () => ["#62B2FD", "#9BDFC4", "#ABF0FF", "#C1D1FF", "#FBD164", "#FDAD73"],
    },
    colorcodes:{
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      svg: null,
      chosenoption:"siteCode",
      selectedXAxis: this.xAxisValue || "",
      selectedYAxis: this.yAxisValue || "",
      selectedGroupCategory: this.groupCategoryValue || "",
      selectedCountryCode: "All",
      selectedSiteCode: "All", // Dropdown filter for siteCode
      uniqueSiteCodes: [], // Store unique site codes for the dropdown
      uniquecountryCodes:[],
      expandBlock: false,
      tooltip: null,
      chartContainer: null,
      sortdata: [],
      filterkeyword:"",
      filterarray:[],
      selectedGroups: [],
      selectedKeywords: [],
      mouseX: 0,
      mouseY: 0,
    };
  },
  watch: {
    data: {
      handler() {
        this.cancelselection();
        this.extractUniqueSiteCodes(); // Update dropdown options when data changes
        this.createChart();
      },
      deep: true,
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
  },
  mounted() {
    window.addEventListener('mousemove', this.updateMousePosition)
    this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
    this.tooltip = this.$refs.tooltip;
    this.extractUniqueSiteCodes(); // Extract site codes on mount
    this.createChart();
  },
  methods: {
    showtable() {
      this.showMore = false
      this.$emit("opendrillpopup", this.data);
    },
    async cancelselection() {
  this.selectedGroups = [];
  this.sortdata = [];
  this.createChart();
},
backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
    updateMousePosition(event) {
      if (this.$refs.component) {
      const rect = this.$refs.component.getBoundingClientRect();
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    }
    },
    async changedoption(){
      await this.extractUniqueSiteCodes();
      this.selectedSiteCode = "All";
      await this.createChart();
    },
    extractUniqueSiteCodes() {
      // Extract unique site codes from the data
      this.uniqueSiteCodes = Array.from(new Set(this.data.map(d => d['siteCode']))).sort();
      this.uniquecountryCodes = Array.from(new Set(this.data.map(d => d['country']))).sort();
    },
    createChart() {
  this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
  const margin = { top: 20, right: 60, bottom: 80, left: 60 };
  const width = 600 - margin.left - margin.right;
  const height = 340 - margin.top - margin.bottom;
  
  // Filter data based on selected siteCode
  let filteredData = this.selectedSiteCode != "All"
    ? this.data.filter(d => d['siteCode'] === this.selectedSiteCode)
    : this.data;
      
    filteredData = this.selectedCountryCode != "All"
    ? filteredData.filter(d => d['country'] === this.selectedCountryCode)
    : filteredData;

  const aggregatedData = this.aggregateData(filteredData);
  if (aggregatedData.length === 0) return;

  const subCategories = Array.from(new Set(
    aggregatedData.flatMap(d => d.values.map(v => v.category).filter(v => v != null))
  ));

  // Calculate the "All Queries" line data
  const allQueryStatusData = aggregatedData.map(d => ({
    key: d.key,
    value: d3.sum(d.values, v => v.value), // Sum all values for "All Queries"
  }));

  const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Map YYYY-MM to YYYY-MonthShort
  const formattedKeys = allQueryStatusData.map(d => {
    const [year, month] = d.key.split("-");
    return `${year}-${monthShortNames[parseInt(month, 10) - 1]}`;
  });
  // Define the color scale
  const colorScale = d3.scaleOrdinal()
    .domain([...subCategories, "All Queries"])
    .range(this.custcolors);

  // Use "All Queries" for xScale and yScale
  const xScale = d3.scalePoint()
    .domain(formattedKeys) // Use keys from "All Queries"
    .range([0, width])
    .padding(0.5);

  const yScale = d3.scaleLinear()
    .domain([0, d3.max(allQueryStatusData, d => d.value)]) // Scale y-axis based on "All Queries"
    .nice()
    .range([height, 0]);

  // Clear previous chart
  d3.select(this.chartContainer).selectAll("*").remove();

  // Create the SVG container
  this.svg = d3.select(this.chartContainer)
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const lineGenerator = d3.line()
    .x((_, i) => xScale(formattedKeys[i])) // Map index to formatted keys
    .y(d => yScale(d.value))
    .curve(d3.curveMonotoneX);

  // Plot all lines including "All Queries"
  [...subCategories, "All Queries"].forEach(category => {
    const lineData = category === "All Queries"
      ? allQueryStatusData
      : aggregatedData.map(d => ({
          key: d.key,
          value: (d.values.find(v => v.category === category) || {}).value || 0,
        }));

    this.svg.append("path")
      .datum(lineData)
      .attr("fill", "none")
      .attr("stroke", colorScale(category))
      .attr("stroke-width", category === "All Queries" ? 3 : 2) // Highlight "All Queries"
      .attr("d", lineGenerator);

    this.svg.selectAll(`circle-${category}`)
      .data(lineData)
      .enter()
      .append("circle")
      .attr("cx", (_, i) => xScale(formattedKeys[i]))
      .attr("cy", d => yScale(d.value))
      .attr("r", 4)
      .attr("fill", colorScale(category))
      .attr("opacity", (d) =>
        this.selectedGroups.length !== 0
          ? this.selectedKeywords.includes(d.key + category)
            ? "1"
            : ".3"
          : "1"
      )
      .on("mouseover", (event, d) => {
        d3.select(this.tooltip).style("opacity", 0.7)
          .html(`Category: ${category}<br/>Value: ${d.value}`)
          .style("left", `${this.mouseX + 20}px`)
          .style("top", `${this.mouseY - 28}px`);
      })
      .on("mouseout", () => d3.select(this.tooltip).style("opacity", 0))
      .on("click", (event, d) => {
        this.clicklinechart(d.originalData, d.key, category);
      });
  });

  // Add x-axis
  this.svg.append("g")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(xScale))
    .selectAll("text") 
  .style("text-anchor", "end") 
  .attr("transform", "rotate(-45)");

  // Add y-axis
  this.svg.append("g")
    .call(d3.axisLeft(yScale));

  // Add x-axis label
  this.svg.append("text")
    .attr("x", width / 2)
    .attr("y", height + margin.bottom -20)
    .attr("text-anchor", "middle")
    .text("Time (YYYY-MM)");

  // Add y-axis label
  this.svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("x", -height / 2)
    .attr("y", -margin.left + 15)
    .attr("text-anchor", "middle")
    .text("Query Count");
},
    async clicklinechart(filteredData, filteredkeyword,category) {
    if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedKeywords = this.selectedKeywords.filter((k) => k !== filteredkeyword+category);
        this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
        let result = this.sortdata.filter(item => !filteredData.includes(item));
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
        this.filterarray = filterarraydeleted;
        this.filterkeyword = `${this.selectedXAxis}(${this.selectedGroups})`;
      } else {
    this.selectedGroups.push(filteredkeyword);
    this.selectedKeywords.push(filteredkeyword+category);
        this.filterarray.push(`(${filteredkeyword})`)
        this.filterkeyword = `${this.selectedXAxis}(${this.selectedGroups})`;
        this.sortdata = this.sortdata.concat(filteredData);
      }
      this.createChart();
  },
  recreatechart() {
      // this.$emit("opendrillpopup", this.sortdata);
      const dataToEmit = {
    sortedData: this.sortdata,
    filterkeyword: this.filterkeyword
  };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
drilldownfuntion(value){
      const dataToEmit = {
        sortedData: value.fullData,
        filterkeyword: `${this.selectedXAxis}(${value.key})`
      };
      this.$emit("drilldowncurrentchart", dataToEmit);
      console.log('Data array for clicked bar:', value);
    },
    aggregateData(data) {
  const formattedData = data.map(d => ({
    ...d,
    [this.selectedXAxis]: d[this.selectedXAxis].split("T")[0].slice(0, 7), // Extract 'YYYY-MM'
  }));

  const nestedData = d3.group(
    formattedData,
    d => d[this.selectedXAxis], // Group by 'YYYY-MM'
    d => d[this.selectedGroupCategory]
  );

  return Array.from(nestedData, ([key, values]) => ({
    key, // 'YYYY-MM'
    values: Array.from(values, ([category, records]) => ({
      category,
      value: d3.sum(records, r => r[this.selectedYAxis] || 1),
      originalData: records, // Attach the original records to the category
    })),
  }));
},
  },
};

  </script>
  
  <style scoped>
  .legandbox{
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
  .filter-wrapper {
  margin-bottom: 10px;
}

.filter-wrapper select {
  padding: 5px;
  font-size: 14px;
}
  .line-chart-wrapper {
    overflow-x: auto;
    width: 100%;
  }
  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .tooltip {
    position: absolute;
    text-align: center;
    width: auto;
    height: auto;
    padding: 5px;
    background: rgb(0, 0, 0);
    border: 1px solid rgb(117, 116, 116);
    border-radius: 3px;
    pointer-events: none;
    opacity: 0;
    z-index: 10;
  }

  </style>
  