<template>
  <div class="wrapper wrapper-content pt-0">
    <div id="getdfcontent">
      <!-- <div class="row">
        <div class="col-12 col-sm-4 col-md-4  col-lg-8 text-center mb-2"><h3 class="form-header">{{formHeader}}</h3></div>
    </div> -->
      <div id="formElements mt-3 ">
        <form>
          <div class="form-group" v-for="input in htmlarray" :key="input.folder_Id">
            <div v-for="(item, key) in input.pages" :key="key">
              <!-- <h1>{{item.pageName}}</h1> -->
              <div v-if="item.pageNo == pageno" class="pageContainer mt-2 mainbgchange">
                <div>
                  <div class="groupsdiv" v-for="(groupno, key) in groups[pageno]" :key="key">

                    <!-- <div class="groupno" v-for="group in groups[pageno]"  :key=group">
              {{group}}
            </div> -->
                    <div class="flexContainer mainbgchange">
                      <template v-for="(newfield, key) in item.field" :key="key">
                        <div class="flex-sub-container mainbgchange" v-if="newfield.groupOrderNo == groupno">
                          <!-- headder starts -->
                          <div v-if="newfield.type == 'header'" class="headerdiv p-1">
                            <h5>{{ newfield.label }}</h5>
                          </div>
                          <!-- headder ends -->
                          <!-- input starts -->
                          <div v-else-if="inputElements.includes(newfield.type)" class="flex-item" @load="testfun()">
                            <baseinput v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]"
                              :label="newfield.label" :inputtype="newfield.type"
                              :disable="signStatus != null && signStatus != ''" type="text"
                              v-on:click="unsignfunction()" />
                            <span v-if="isvalidate" class="errormsg">{{ errormsg[newfield.fieldName] }}</span>
                          </div>
                          <!-- input ends -->
                          <!-- image starts -->
                          <div v-else-if="newfield.type == 'image'" class="flex-item displayflex">
                            <div>
                              <div class="labelclass marginauto">
                                {{ newfield.label }}
                              </div>
                              <input class="form-control" type="text"
                                v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]" />
                            </div>
                            <img v-on:click="unsignfunction()" class="templatepreview" :src="`https://eidsa.dev.datamatica.uk/v1/dms/document/file/${newfield.imagePath.split(',')[0]
                              }?versionid=${newfield.imagePath.split(',')[1]
                              }`" />
                          </div>
                          <!-- image ends -->
                          <!-- scales starts -->
                          <div v-else-if="newfield.type == 'scales'" class="flex-item">
                            <div class="labelclass">
                              please select a value
                            </div>
                            <div v-on:click="unsignfunction()" class="wrapper">
                              <input type="range" :min="newfield.minval" :max="newfield.maxval" step="1"
                                :disabled="signStatus != null && signStatus != ''"
                                v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]" />
                              <input class="form-control scaleval" type="number"
                                v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]" />

                              <span v-text="total"></span>
                            </div>
                            <span class="errormsg">{{
                              errormsg[newfield.fieldName]
                              }}</span>
                          </div>
                          <!-- scales ends -->
                          <!-- time element starts -->
                          <div v-else-if="newfield.type == 'time'" class="flex-item bgblue">
                            <div class="labelclass">
                              {{ newfield.label }}
                            </div>
                            <input v-on:click="unsignfunction()" class="form-control" classname="time"
                              :label="newfield.label" type="time" :disabled="signStatus != null && signStatus != ''"
                              v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]" />
                          </div>
                          <!-- time element ends -->
                          <!-- date element starts -->
                          <div v-else-if="newfield.type == 'date'" class="flex-item" @load="testfun()">
                            <basedate v-on:click="unsignfunction()" :classname="errorclasslist[newfield.fieldName]"
                              v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                                " :label="newfield.label" :disable="signStatus != null && signStatus != ''" type="text" />
                            <span class="errormsg">{{
                              errormsg[newfield.fieldName]
                              }}</span>
                          </div>
                          <!-- date element ends -->
                          <!-- dateandtime element starts -->
                          <div v-else-if="newfield.type == 'dateandtime'" class="flex-item bgblue">
                            <div class="labelclass">
                              {{ newfield.label }}
                            </div>
                            <input v-on:click="unsignfunction()" :disabled="signStatus != null && signStatus != ''"
                              class="form-control" classname="date" :label="newfield.label" type="datetime-local"
                              v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                                " />
                            <span class="errormsg">{{
                              errormsg[newfield.fieldName]
                              }}</span>
                          </div>
                          <!-- date element ends -->
                          <!-- dropdown menu starts -->
                          <div v-else-if="newfield.type == 'dropdown'" class="flex-item">
                            <baseselect v-on:click="unsignfunction()" :options="newfield.options"
                              v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]" :label="newfield.label"
                              :disable="signStatus != null && signStatus != ''" />
                            <span v-if="isvalidate" class="errormsg">{{ errormsg[newfield.fieldName] }}</span>
                          </div>
                          <!-- dropdown menu ends -->
                          <!-- summery starts -->
                          <div class="elem" v-else-if="newfield.type == 'summary'">
                            <div class="consentnotesstyle">
                              {{ newfield.label }}
                            </div>
                          </div>
                          <!-- summery ends -->
                          <!-- text area starts -->
                          <div class="elem" v-else-if="
                            ((newfield.type == 'description') |
                              (newfield.type == 'textarea'))">
                            <div class="txtarealabel">
                              <label :for="newfield.fieldName" class="textsize">{{
                                newfield.label
                                }}</label>
                            </div>
                            <div class="txtar">
                              <textarea v-on:click="unsignfunction()" :id="newfield.fieldName"
                                :name="newfield.fieldName" class="textbox" v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                                  " :disabled="signStatus != null && signStatus != ''" />

                              <span v-if="isvalidate" class="errormsg">{{
                                errormsg[newfield.fieldName]
                                }}</span>
                            </div>
                          </div>
                          <!-- text area ends -->
                          <!-- notes starts -->
                          <div class="elem" v-else-if="
                            (newfield.type == 'notes')">
                            <div class="notesstyle">
                              heloooo{{ newfield.label }}
                            </div>
                          </div>
                          <!-- notes ends -->
                          <!-- file upload starts -->
                          <div v-else-if="newfield.type == 'uploadfile'" class="flex-item"
                            v-on:click="unsignfunction()">
                            <div class="labelclass">
                              {{ newfield.label }}
                            </div>
                            <div class="twoitemflex">
                              <input class="form-control" :label="newfield.label" type="file"
                                :disabled="signStatus != null && signStatus != ''" />
                            </div>
                          </div>
                          <!-- file upload ends -->
                          <!-- year starts -->
                          <div v-else-if="newfield.type == 'year'" class="flex-item">
                            <div class="labelclass">
                              {{ newfield.label }}
                            </div>
                            <input v-on:click="unsignfunction()" class="form-control" :label="newfield.label"
                              type="number" min="1900" max="2099" step="1"
                              :disabled="signStatus != null && signStatus != ''" v-model="formdata[`pageNo${item.pageNo}`][
                                newfield.fieldName
                                ]
                                " />
                            <span class="errormsg">{{
                              errormsg[newfield.fieldName]
                              }}</span>
                          </div>
                          <!-- year ends -->
                          <!-- qr code starts -->
                          <div class="elem" v-else-if="newfield.type == 'qrcode'" v-on:click="unsignfunction()">
                            <div hidden>
                              {{
                                qrcodefun(
                                  newfield.fieldName,
                                  newfield.options[0]
                                )
                              }}
                            </div>
                            <iframe :src="qrcodetxt[newfield.fieldName]" title="qr code">
                            </iframe>
                          </div>
                          <!-- qr code ends -->
                          <!-- numberanddate starts -->
                          <div v-else-if="newfield.type == 'numberanddate'" class="flex-item">
                            <div class="labelclass">
                              {{ newfield.label }}
                            </div>
                            <div class="twoitemflex">
                              <input v-on:click="unsignfunction()" class="form-control" :label="newfield.label"
                                type="text" :disabled="signStatus != null && signStatus != ''"
                                v-model="newfield.options[0]" />
                              <input v-on:click="unsignfunction()" class="form-control" :label="newfield.label"
                                type="date" :disabled="signStatus != null && signStatus != ''" v-model="formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                  ]
                                  " />
                            </div>
                            <span class="errormsg">{{
                              errormsg[newfield.fieldName]
                              }}</span>
                          </div>
                          <!-- numberanddate ends -->
                          <!-- checkboxes starts -->
                          <div v-else-if="newfield.type == 'checkbox'" class="form-group sticktobottom">

                            <label class="labelstyle">&nbsp;</label>
                            <div class="checkboxentry">
                              <input v-on:click="unsignfunction()" type="checkbox" class="form-check-input"
                                :id="newfield.fieldName" :name="newfield.fieldName"
                                :disabled="signStatus != null && signStatus != ''" v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                                  " /><span class="checklabel"> {{ newfield.label }} </span>
                            </div>
                            <span v-if="isvalidate" class="errormsg">{{ errormsg[newfield.fieldName] }}</span>
                          </div>
                          <!-- checkboxes ends -->
                          <!-- radio button starts -->
                          <div v-else-if="newfield.type == 'radio'" class="form-group sticktobottom">
                            <div>
                              <consentradiogroup v-on:click="unsignfunction()" :label="newfield.label"
                                :name="newfield.fieldName" :options="newfield.options"
                                :disable="signStatus != null && signStatus != ''"
                                v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]">
                              </consentradiogroup>
                            </div>
                            <span v-if="isvalidate" class="errormsg">{{ errormsg[newfield.fieldName] }}</span>
                          </div>
                          <!-- radio button ends -->
                          <!-- subHeader starts -->
                          <div v-else-if="newfield.type == 'subHeader'" class="subheadder p-1">
                            <h4>{{ newfield.label }}</h4>
                          </div>
                          <!-- subHeader ends -->
                          <!-- {{fields}} -->
                        </div>
                      </template>
                    </div>
                  </div>
                  <!------ pagination -------->
                  <!-- <div class="row mx-0 paginationpadding">
            <div class="col-lg-12 mx-0 px-0">
              <div class="dataTables_paginate paging_simple_numbers pagination float-right">
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                    v-model="pageno"
                    :pages="totalpages"
                    :range-size="1"
                    active-color="#618bd7"
                  />
                </div>
              </div>
            </div>
          </div> -->
                  <div class="buttonContainer justify-content-center">
                    <div class="leftbutton">
                      <button v-if="pageno > 1" type="button" class="save_btn py-1 px-3" @click.prevent="previousFun">
                        Previous
                      </button>
                    </div>
                    <div class="px-3"> Page {{ pageno }}</div>
                    <div class="rightbutton">
                      <button v-if="(pageno == totalpages) && (signStatus == null || signStatus == '')" type="button"
                        class="save_btn py-1 px-3" @click.prevent="savefun">
                        Save
                      </button>
                    </div>

                    <div class="rightbutton">
                      <button v-if="pageno < totalpages" type="button" class="save_btn py-1 px-3"
                        @click.prevent="updateFun">
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Document upload modal starts -->
  <div class="modal-wrapper-upload">
    <div class="modal-container-upload">
      <div class="titleclass">
        <h4 class="ml-lg-2">{{ $t('navigations.document upload') }}</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i class="fa fa-times" @click.prevent="CloseUploadModal()"></i>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <div class="mb-3 p-2">
          <div class="form-group row my-lg-2">

            <div class="col-lg-12 my-2">
              <textarea name="description" id="description" class="form-control" v-model="azuredata.DocumentDescription"
                :placeholder="$t('navigations.description')">
            </textarea>
            </div>

            <div class="col-lg-12 my-2">
              <input accept=".xls,.xlsx,.jpg,.jpeg,.png,.pdf" id="InPutFile" type="file" @change="previewFiles"
                class="form-control" />
            </div>
            <span class="errorClass" v-if="docValidations == false">{{
              $t("navigations.please select a file")
            }}</span>

            <div class="d-flex my-2 mb-lg-0">
              <div class="assignbtncontainer">
                <button class="btn btn-primary px-5 mx-1" @click.prevent="CreateDocumentDMS()">
                  {{ $t('navigations.upload') }}
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  </div>
  <!-- Document upload modal ends -->

  <!-- Document view modal starts -->
  <div class="modal-wrapper-view">
    <div class="modal-container-view">
      <div class="titleclass">
        <h4 class="ml-lg-2">{{ $t('navigations.documents') }}</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i class="fa fa-times" @click.prevent="ClosemodalView()"></i>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <div class="ibox-content">
          <div class="table-responsive">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th> {{ $t('navigations.file name') }}</th>
                  <th> {{ $t('navigations.date') }}</th>
                  <th> {{ $t('navigations.view') }}</th>
                  <th> {{ $t('navigations.download') }}</th>
                  <th> {{ $t('navigations.delete') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="document in documentlist" :key="document.fileName">
                  <td> {{ document.documentName }}</td>
                  <td> {{ filter(document.CreatedAt) }} </td>
                  <td class="text-center">
                    <i class="fa fa-eye edit-delete" @click="viewDocument(document.id)"></i>
                  </td>
                  <td class="text-center">
                    <i class="fa fa-download edit-delete" @click="downloadfile(document.id)"></i>
                  </td>
                  <td class="text-center">
                    <i class="fa fa-trash edit-delete" style="color: #a13b7c"
                      @click="deleteAzureDocument(document.id)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Document view modal ends -->
  <signaturePopUp v-if="signpopUp == true" :status="signatuestatus" :formId="signatureformId" :modalText="modalStatus"
    @signatureclosemodel="CloseModelCall()">
  </signaturePopUp>
</template>

<script src="./consentform.js"></script>

<style scoped>
/* @import '../style.css'; */
@import "../../../assets/style.css";

.labelclass {
  text-align: left;
  font-size: 14px;
}

.pageContainer {
  margin-top: 30px;
}

.titleclass {
  background-color: #6198fc;
}

.modal-container-view {
  background: #fff;
  width: min(50%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all .3s ease-in-out;
  height: auto !important;
  margin-top: 200px;

}
</style>
