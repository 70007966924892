/* eslint-disable */
import axios from "axios";
import store from "../../store/index";

export default {
    name: "uploadFile",
    components: {
    },
    data() {
        return {
            baseurl: process.env.VUE_APP_Service_URL,
            // Data hub code starts
            dataTOken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJUeXBlIjoiQXBwbGljYXRpb24iLCJPcmdhbmlzYXRpb24iOiJTdHVkeUltcG9ydCIsIkFwcE5hbWUiOiJEYXRhRXh0cmF0IiwiZXhwIjo0ODg4NTU0MDk5fQ.MzdW7sv6VevOJkyRDIeziSrChlDJf3sr9WEfGRJXaFA",
            showupload: false,
            showLoading: false,
            studyFile: "",
            studyFiles: [],
            dataInput: "",
            showValidation: false
            // Data hub code ends
        };
    },
    methods: {
        // study data excel automation Starts
        async closeModal() {
            this.$emit("closeModal")
            this.studyFile = "";
            this.showValidation = false;
        },
        async previewFiles(event) {
            console.log("event ", event.target.files);
            this.studyFiles = Array.from(event.target.files);
            console.log("study files", this.studyFiles)
        },
        async studyDataExport() {
            if (this.studyFiles.length == 0) {
                this.showValidation = true
            }
            else {
                this.showLoading = true;
                this.showValidation = true;
                await this.uploadData();
            }
        },
        async uploadData() {
            this.showValidation = false;
            console.clear();
            const formData = new FormData();
            this.studyFiles.forEach((file) => {
                formData.append("files", file);
            });
            try {
                const response = await axios.post(
                    `${this.baseurl}/dataservices/centraldatahub/upload`, formData,
                    {
                        headers: {
                            Authorization: `Bearer ${this.dataTOken}`
                        },
                    }
                );
                console.log("Files upload...", formData);
                console.log("Files uploaded successfully...", response.data);
                await this.webHook();
            } catch (err) {
                console.log("Error in file upload...", err);
            }
        },
        // study data excel automation Ends

        async webHook() {
            try {
                const response = await axios.get(
                    `https://hook.eu2.make.com/yd2s588drfanqvqf79d1ekttiovsr5u2?FileName=${this.studyFiles[0].name}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.dataTOken}`
                        },
                    }
                );
                console.log("Added successfully...", response.data);
                this.dataInput = response.data;
                await this.dataService();
            } catch (err) {
                console.log("Error in file upload...", err);
            }
        },
        async dataService() {
            try {
                const url = `${this.baseurl}/dataservices/centraldatahub/download/${this.dataInput}`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${this.dataTOken}`,
                        Accept: '*/*'
                    },
                    responseType: 'blob' // Ensures the file is handled as binary data
                });

                // Create a URL for the blob and download it
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                const downloadName = this.studyFiles[0].name.replace(/\.pdf$/, '')
                link.download = `${downloadName}.xlsx`; // Specify the file name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.showLoading = false;
                this.$emit("closeModal")
            } catch (error) {
                console.error('Error downloading the file:', error);
            }
        },
    },
};