<template>
  <div class="study_profile_modal__wrapper">
    <div class="study_profile_modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 v-if="selectedvisit == 0" class="my-0">
          {{ $t("navigations.create visit") }}
        </h4>
        <h4 v-else class="my-0">{{ $t("navigations.create study") }}</h4>
        <div class="cursor-pointer" :title="$t('close')"  @click.prevent="closeModal">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="ibox-content p-0">
        <div class="modal-body pb-0">
          <div class="form-group row">
            <div class="col-lg-4 my-0">
              <label class="col-form-label">
                {{ $t("navigations.study id") }}<sup><i class="fa fa-asterisk imp"></i></sup>
              </label>
              <input type="text" class="form-control" maxlength="1000" v-model="values.studyRef" />
              <span class="errormsg ml-0" v-if="errors[1] == false"
                >{{ $t("navigations.please enter a study id") }}</span
              >
            </div>
            <div class="col-lg-4 my-0">
              <label class="col-form-label">
                {{ $t("navigations.study name") }}
                <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                type="text"
                class="form-control"
                v-model="values.studyName"
                maxlength="1000"
              />
              <span class="errormsg ml-0" v-if="errors[0] == false"
                >{{ $t("navigations.please enter a study name") }}</span
              >
            </div>
            <div class="col-lg-4 my-0">
              <label class="col-form-label">{{ $t("navigations.sponsor") }} </label>
              <input
                type="text"
                class="form-control"
                v-model="values.sponsor"
                maxlength="1000"
              />
            </div>
            <div class="col-lg-12 my-0">
              <label class="col-form-label">{{ $t("navigations.study type") }}</label>
              <select class="form-select" v-model="selectedStudyType">
                 <option value="" disabled selected>{{ $t("navigations.select") }}</option>
                <template v-for="classification in classficationList">
                  <template
                    v-if="classification.classifier === 'Study Design (Allocation)'"
                  >
                  
                    <option
                      v-for="studyType in classification.classifications"
                      :key="studyType"
                    >
                      {{ studyType }}
                    </option>
                  </template>
                </template>
              </select>
            </div>

            <div class="col-lg-12 my-0">
              <label class="col-form-label">{{ $t("navigations.estimated enrollment") }}</label>
              <input type="text" maxlength="1000" class="form-control" v-model="enrolment" />
            </div>

            <div class="col-lg-12 my-0 mt-3 mb-2"></div>

            <div class="col-lg-6 my-0">
              <label class="col-form-label">{{ $t("navigations.allocation") }}</label>
              <select class="form-select"
              v-model="allocation">
               <option value="" disabled selected>{{ $t("navigations.select") }}</option>
                
                <template v-for="classification in classficationList">
                  <template v-if="classification.classifier === 'Allocation'">
                    <option
                      v-for="allocation in classification.classifications"
                      :key="allocation"
                    >
                      {{ allocation }}
                    </option>
                  </template>
                </template>
              </select>
            </div>

            <div class="col-lg-6 my-0">
              <label class="col-form-label"> {{ $t("navigations.intervention model") }}</label>
              <select class="form-select" v-model="intervention">
                 <option value="" disabled selected>{{ $t("navigations.select") }}</option>
                <template v-for="classification in classficationList">
                  <template
                    v-if="
                      classification.classifier === 'Nature of Interventions or Exposures'
                    "
                  >
                    <option
                      v-for="intervention in classification.classifications"
                      :key="intervention"
                    >
                      {{ intervention }}
                    </option>
                  </template>
                </template>
              </select>
            </div>

            <div class="col-lg-12 my-0 mt-3 mb-2"></div>

            <div class="col-lg-6 my-0">
              <label class="col-form-label"> {{ $t("navigations.masking") }}</label>
             
              <select class="form-select" v-model="masking">
                  <option value="" disabled selected>{{ $t("navigations.select") }}</option>
                <template v-for="classification in classficationList">
                  <template
                    v-if="
                      classification.classifier === 'Masking'
                    "
                  >
                    <option
                      v-for="masking in classification.classifications"
                      :key="masking"
                    >
                      {{ masking }}
                    </option>
                  </template>
                </template>
              </select>
            </div>
            <div class="col-lg-6 my-0">
              <label class="col-form-label">{{ $t("navigations.primary purpose") }}</label>
              <select class="form-select" v-model="purpose">
                 <option value="" disabled selected>{{ $t("navigations.select") }}</option>
                <template v-for="classification in classficationList">
                  <template
                    v-if="
                      classification.classifier === 'Primary Purpose'
                    "
                  >
                    <option
                      v-for="purpose in classification.classifications"
                      :key="purpose"
                    >
                      {{ purpose }}
                    </option>
                  </template>
                </template>
              </select>
            </div>


            <div class="col-lg-12 my-0">
              <label class="col-form-label">{{ $t("navigations.official title") }}</label>
              <input type="text" class="form-control" maxlength="1000" v-model="title"/>
            </div>

            <div class="col-lg-12 mt-1 d-flex justify-content-lg-end">
              <button class="save_btn py-1 px-4" type="button" @click.prevent="save">
               {{ $t("navigations.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./createStudyPopup.js"></script>
<style scoped>
@import "../../assets/css/style.css";

.study_profile_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}

.study_profile_modal__container {
  position: fixed;
  width: min(50%, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}

.modal__title {
  background-color: #618bd7;
  color: #fff;
}

.save_btn {
  text-align: center;
  background-color: #145faf !important;
  border-color: #145faf !important;
  color: white !important;
  padding: 0.43rem 0.4rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out !important;
}

.save_btn:hover,
.save_btn:focus:focus-visible {
  background-color: #145faf !important;
  border-color: #145faf !important;
}
</style>
