/* eslint-disable */
import axios from "axios";
import managePatientModal from "../managePatientModal/managePatientModal.vue";
import manageVisitModal from "../manageVisitModal/manageVisitModal.vue";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import CreateSitePopUp from "../CreateSitePopUp/CreateSitePopUp.vue";
import EditStudyPopUp from "../EditStudyPopUp/EditStudyPopUp.vue";
import createnewversion from "../newversion/createnewversion.vue";
import retireFormPopUp from "../retireFormPopUp/retireFormPopUp.vue";
import importForms from "../importForms/importForms.vue";
import Multiselect from "@vueform/multiselect";

export default {
  name: "studyProfile",
  components: {
    VPagination,
    managePatientModal,
    manageVisitModal,
    CreateSitePopUp,
    EditStudyPopUp,
    createnewversion,
    retireFormPopUp,
    Multiselect,
    importForms
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      showSiteModal: false,
      showPatientModal: false,
      showFormsModal: false,
      token: store.getters.getIdToken,
      head: "",
      // -- pagination -- 
      pageSize: 10,
      totalTemplatePages: 1,
      currentTemplatePage: 1,
      totalVisitPages: 1,
      currentVisitiPages: 1,

      showSidePopup: [false],
      showVisitPopup: [false],
      studyDetails: "",
      studyTemplates: "",
      defaultdate: "",
      visitList: "",
      studyId: "",
      visitId: "",
      siteModal: false,
      showAssignTemplate: false,
      studyEditModal: false,
      newtemplate: "",
      creatversionpopup: false,
      newversionformid: "",
      templatelist: [],
      selectedTemplateIds: [],
      selectedvisitid: "",
      sourceId: "",
      sourceVersion: "",
      retireFormModal: false,
      rollsprivilages: "",
      AIpopup: false,
      formItems: [],
      values: {
        modelName: "StudyDIsName",
        description: "",
        threshold: 0.7
      },
      showLoadingAnimation: false,
      clicked: false,
      showVisitForms: false,
      formsArray: [],
    };
  },

  async mounted() {
    this.head = {
      headers: {
        Authorization: "Bearer " + this.token,
        "Content-Type": "application/json",
      },
    },

      this.defaultdate = localStorage.getItem("dateformat");
    this.rollsprivilages = await store.getters.getRolesprivilegeData;
    window.addEventListener("studyIdChanged", async (event) => {
      this.studyId = store.getters.getStudyIs
      await this.getStudyDetails();
      await this.listStudyTemplate();
      await this.templateList();
      await this.listVisit();
    });
    this.studyId = store.getters.getStudyIs
    if (this.studyId == null || this.studyId == "") {
      alert(this.$t('navigations.no study selected'))
    }
    else {
      await this.getStudyDetails();
      await this.listStudyTemplate();
      await this.templateList();
      await this.listVisit();
    }

  },
  computed: {
    selectedmodeltemplate() {
      return this.selectedTemplateIds.map(id => {
        return this.templatelist.find(template => template.templateId === id);
      });
    }
  },
  methods: {
    // study data excel automation Starts
    filterDate(date) {
      if (date) {
        return moment(date).format(this.defaultdate);
      }
    },
    loadTemplate: function (page) {
      this.currentTemplatePage = page;
      this.listStudyTemplate();
      this.templateList();
    },
    loadVisit: function (page) {
      this.currentVisitiPages = page;
      this.listVisit();
    },
    openretireModal(id, versionNo) {
      this.retireFormModal = true
      this.sourceId = id
      this.sourceVersion = versionNo
    },
    async getStudyDetails() {
      axios.get(`${this.baseurl}/management/study/getbyid/${this.studyId}`, this.head)
        .then((res) => {
          console.log("study details ", res.data);
          this.studyDetails = res.data;
        })
        .catch(err => console.log("error in get study", err));
    },
    async listStudyTemplate() {
      axios.get(`${this.baseurl}/forms/template/listtemplate?Status=Approved&StudyID=${this.studyId}&pageindex=${this.currentTemplatePage}&pagesize=${this.pageSize}&SortProperty=templateName&SortOrder=0`, this.head)
        .then((res) => {
          console.log("template details ", res.data);
          this.studyTemplates = res.data.results;

          let totalCount = res.data.totalCount;
          this.totalTemplatePages = parseInt(totalCount / this.pageSize);
          if (totalCount % this.pageSize != 0) {
            this.totalTemplatePages = this.totalTemplatePages + 1;
          }
        })
        .catch(err => console.log("error in get study", err));
    },
    async templateList() {
      axios.get(`${this.baseurl}/forms/template/listtemplate?Status=Approved&StudyID=${this.studyId}&pagesize=100&SortProperty=templateName&SortOrder=0`, this.head)
        .then((res) => {
          console.log("template details ", res.data);
          this.templates = res.data.results;
        })
        .catch(err => console.log("error in get template list", err));
    },
    async getPagesByTemplateId(id, version) {
      await axios.get(`${this.baseurl}/forms/templatedesign/getpagesbyid?formId=${id}&version=${version}`, this.head)
        .then((res) => {
          console.log("get pages...", res.data);
          this.newtemplate = res.data;
        })
        .catch(err => console.log("error in get pages", err))
    },
    async visitPopup(visitIdEdit) {
      this.showSiteModal = true
      this.visitId = visitIdEdit
    },
    async listVisit() {
      axios.get(`${this.baseurl}/management/visit/listvisit?StudyId=${this.studyId}&pageindex=${this.currentVisitiPages}&pagesize=${this.pageSize}`, this.head)
        .then((res) => {
          console.log("visit list", res.data.results);
          this.visitList = res.data.results;

          let totalCount = res.data.totalCount;
          this.totalVisitPages = parseInt(totalCount / this.pageSize);
          if (totalCount % this.pageSize != 0) {
            this.totalVisitPages = this.totalVisitPages + 1;
          }
        })
        .catch(err => console.log("error in list visit", err));
    },
    async deleteForms(id) {
      if (confirm(this.$t('navigations.are you sure you want to delete this form?'))) {
        await axios.delete(
          `${this.baseurl}/forms/templatedesign/deleteform?formId=${id}`, this.head
        )
          .then((res) => {
            alert(this.$t("navigations.form deleted successfully"))
            this.listStudyTemplate();
            this.templateList();
          })
          .catch((err) => {
            alert(this.$t("navigations.failed due to internal error"))
            console.error(err);
          });
      }
      this.showSidePopup = [false];
    },
    async deleteVist(id) {
      if (confirm(this.$t('navigations.are you sure you want to delete this visit?'))) {
        await axios.delete(`${this.baseurl}/management/visit/delete?id=${id}`, this.head)
          .then((res) => {
            alert(this.$t("navigations.visit deleted successfully"));
            this.listVisit();
          })
          .catch((err) => {
            alert(this.$t("navigations.failed due to internal error"))
            console.error(err);
          });
      }
      this.showVisitPopup = [false];
    },
    async viewtemplatefun(templateFormId, version) {
      store.dispatch("setViewtemplateid", templateFormId);
      store.dispatch("setViewformid", templateFormId);

      this.$router.push(`/viewform/${templateFormId}`);
    },
    async createNewVersion(templateFormId, version, sourceTemplateId, templateId) {
      console.log(" in create new version study profile", version)
      localStorage.setItem('isStudyProfileRoute', true);
      localStorage.setItem('templateFormId', templateFormId);
      localStorage.setItem('version', version);
      localStorage.setItem('sourceTemplateId', sourceTemplateId);
      localStorage.setItem('latestTempId', templateId)
      localStorage.setItem('DesignFormPrvRoute', "studyProfile");
      this.$router.push({
        path: '/formdesigner',
        query: {
          templateId: templateId
        }
      });
    },
    async gettemplatedata() {
      await axios
        .get(`${this.baseurl}/forms/template/listtemplate?Status=Approved&StudyID=${this.studyId}&SortProperty=templateName&SortOrder=0`, this.head)
        .then((res) => {
          this.templatelist = (res.data.results || []).sort((a, b) =>
            a.templateName.localeCompare(b.templateName)
          );
          console.log("list template data is...", this.templatelist)
        })
        .catch((err) => {
          console.log("error in get template details ", err);
        });
    },
    async assignTemplate() {
      if (this.selectedTemplateIds.length == 0) {
        alert(this.$t('navigations.please select a form'))
      }
      else {
        this.templateNameArray = []
        console.log("Value is", this.selectedmodeltemplate)
        this.selectedmodeltemplate.forEach((item, index) => {
          this.formsArray[index] = {
            "template_ID": "",
            "templateName": "",
            "version": ""
          },
            this.formsArray[index].template_ID = item.templateId
          this.formsArray[index].templateName = item.templateName
          this.formsArray[index].version = item.version
        })
        await axios
          .put(`${this.baseurl}/management/visit/assigntemplate`,
            {
              "visitID": this.selectedvisitid,
              "forms": this.formsArray
            }, this.head
          )
          .then((res) => {
            alert(this.$t('navigations.form assigned successfully'));
            console.log(res.data);
            this.formsArray = [],
              this.selectedmodeltemplate = []
            this.listVisit();
          })
          .catch((err) => {
            console.log("error in assign template", err);
          });
        this.closeTemplateModal();
      }
    },
    async createTemplate() {
      localStorage.setItem('isFormCreateTemplateRoute', true);
      this.$router.push(`/formdesigner`);
      localStorage.setItem('DesignFormPrvRoute', "studyProfile");
    },

    closecreatenewversion() {
      this.newversionformid = "";
      this.creatversionpopup = false;
    },
    async closeModal() {
      this.showSiteModal = false;
      await this.listVisit();
    },
    async closeRetireModal() {
      this.retireFormModal = false;
      await this.listStudyTemplate();
      await this.templateList();
      await this.listVisit();
    },
    async openTemplateModal(visitid) {
      console.log("open template modal")
      this.selectedvisitid = visitid;
      this.showAssignTemplate = true
      await this.gettemplatedata();
      await this.getAllForms(visitid);
      if (this.formItems != null) {
        console.log("Inside map function")
        this.selectedTemplateIds = this.formItems.map(item => item.template_ID);
        console.log("selected template ids...", this.selectedTemplateIds)
      }

    },
    async closeTemplateModal() {
      this.selectedTemplateIds = [];
      this.showAssignTemplate = false;
    },
    async openFormsModal(visitid, patientId) {
      console.log("from fill form list", patientId)
      localStorage.setItem("patID", patientId)
      this.selectedvisitid = visitid;
      await this.getAllForms(visitid);
      this.showVisitForms = true;

    },
    async closeModalForms() {
      this.showVisitForms = false;
    },
    async getAllForms(visitId) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/visit/getallforms?studyId=${this.studyId}&visitId=${visitId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.formItems = res.data[0];
          console.log("visit form list is...", this.formItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async onClickButton() {
      this.showLoadingAnimation = true;
      this.clicked = true;
      await this.getStudyDescription();
      this.showLoadingAnimation = false;
      this.clicked = false;
    },
    async getStudyDescription() {

      const studyId = store.getters.getStudyIs;
      try {
        const response = await axios.get(`${this.baseurl}/management/study/getbyid/${studyId}`, this.head);

        this.StudyDescription = response.data;
        console.log("StudyDescription", this.StudyDescription);
        await this.formGeneration();

        if (Array.isArray(this.StudyDescription.studyProperties)) {
          this.FromStudy = this.StudyDescription.studyProperties;

        } else {
          console.log("No arrays found in studyProperties");
          this.FromStudy = [];
        }
      } catch (error) {
        console.log("Error in getting study:", error);
      }

    },
    async formGeneration() {
      // this.FromStudy.push(this.values.description)
      axios.get(`${this.baseurl}/forms-ml/mlmodel/getvalues?model_name=${this.values.modelName}&data=${this.StudyDescription}&threshold=${this.values.threshold}`)
        .then((res) => {
          this.formList = res.data;

          console.log("Arrays extracted from studyProperties and stored in FromStudy:", this.FromStudy);
          console.log("Form list", res.data)

          this.$router.push({
            path: '/FormSuggestions',
            query: {
              formList: encodeURIComponent(JSON.stringify(this.formList))
            }
          });
        })
        .catch(err => console.log("error in get study", err));
      await store.dispatch("setformList", JSON.stringify(this.formList))
    },
    async viewForm(id) {
      this.$router.push(`/viewTemplate/studyProfile/${id}`);
    },
  },
};