<template>
  <div ref="component" class="stat-card-white">
    <div class="stat-header-white">
      <span>{{ chartLabel }}</span>
      <div v-if="!loader" class="d-flex align-items-center">
        <div class="expand-block px-2 mb-1 cursor-pointer position-relative">
          <img
            class="cursor-pointer"
            @click="showtable()"
            src="../../assets/img/table_view.png"
            alt="More block"
          />
          <div class="more-expand">
            <span>{{ $t("navigations.data overview") }}</span>
          </div>
        </div>
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock = true">
          <img src="../../assets/img/expand.png" alt="Expand block" />
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="exporttoexcell()">Export to Excel</div>
            <div @click.prevent="exporttopdf()">Export to PDF</div>
          </div>
        </div>
      </div>
    </div>
    <div class="stat-body-white overflow-auto">
      <div ref="chartContainerWrapper" class="w-100">
        <div v-if="!loader && currentLevel === 2" class="d-flex flex-row-reverse gap-2">
          <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="backtooriginal()" alt="Cancel"/>
        </div>
        <svg ref="chartContainer" v-show="!loader"></svg>
        <div v-if="colorcodes.length!=0" class="d-flex w-100">
      <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
        <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
        {{ colors.type }}
      </div>
    </div>
      </div>
      <div ref="tooltip" class="tooltip"></div>
      
    </div>
    
    <enlargerpopup v-if="expandBlock" @closeModal="expandBlock = false; createChart()">
      <div class="stat-body-white d-flex flex-column">
        <svg ref="popupChartContainer"></svg>
      </div>
    </enlargerpopup>
  </div>
</template>

<script>
import * as d3 from "d3";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
import * as XLSX from "xlsx";
import html2pdf from "html2pdf.js";

export default {
  name: "comparativeBarChart",
  components: {
    enlargerpopup,
  },
  props: {
    data: {
      type: Array,
      required: false,
      default: () => []
    },
    xAxisValue: { type: String, default: "" },
    yAxisValue: { type: String, default: "" },
    customisationfields: { type: Array, default: () => [] },
    xaxislabels: { type: Array, default: () => [] },
    chartLabel: { type: String, default: "Comparative Analysis" },
    loader: { type: Boolean, default: false },
    width: { type: Number, default: 1000 },
    height: { type: Number, default: 400 },
    custcolors: { type: Array, default: () => ["#FFA500", "#D32F2F", "#ABF0FF", "#C1D1FF", "#FBD164", "#FDAD73"] },
    colorcodes: { type: Array, default: () => [] },
    type: { type: String, default: "" },
    sort: { type: String, default: "" },
    fromPage: { type: String, default: "" },
    barcategory: { type: Array, default: () => ["updates", "total"] },
    firstlevelkeys:{ type: Array, default: () => ["templateName"] },
    secondlevelkeys:{ type: Array, default: () => ["month","year"] },
    firstlevelxaxislabel:{ type: String, default: "Forms" },
    secondlevelxaxislabel:{ type: String, default: "Month and Year" },
    firstlevelyaxislabel:{ type: String, default: "No of Fields" },
    secondlevelyaxislabel:{ type: String, default: "No of Fields" },
  },
  data() {
    return {
      originalData: [],
      templatedata: [],
      expandBlock: false,
      tooltip: null,
      showVisitPopup: false,
      sortdata: [],
      filterkeyword: "",
      filterarray: [],
      chartContainer: null,
      mouseX: 0,
      mouseY: 0,
      currentLevel: 1, // 1 for top-level, 2 for second-level
      selectedTemplateName: null,
    };
  },
  mounted() {
    this.tooltip = this.$refs.tooltip; // Ensure we have tooltip reference
    this.originalData = Array.isArray(this.data) ? this.data : [];
    this.templatedata = this.prepareLevelData();
    if (this.templatedata && this.templatedata.length > 0) {
      this.createChart();
    }
    window.addEventListener("mousemove", this.updateMousePosition);
  },
  watch: {
    data: {
      handler(newVal) {
        this.originalData = Array.isArray(newVal) ? newVal : [];
        // Once data is available, reset to top-level and re-draw
        this.backtooriginal();
      },
      deep: true,
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("mousemove", this.updateMousePosition);
  },
  methods: {
    createChart() {
  if (!this.templatedata || this.templatedata.length === 0) {
    console.warn("No data available to plot.");
    d3.select(this.chartContainer).selectAll("*").remove();
    return;
  }

  this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
  const margin = { top: 20, right: 30, bottom: 70, left: 80 };
  const width = this.width - margin.left - margin.right;
  const height = this.height - margin.top - margin.bottom;

  const aggregatedData = this.aggregateData();
  if (!aggregatedData || aggregatedData.length === 0) {
    console.warn("No aggregated data to plot.");
    d3.select(this.chartContainer).selectAll("*").remove();
    return;
  }

  const categories = this.barcategory;
  const tooltipEl = this.tooltip; // Store tooltip reference locally

  const x0 = d3
    .scaleBand()
    .domain(aggregatedData.map((d) => d.key))
    .range([0, width])
    .padding(0.2);

  const x1 = d3
    .scaleBand()
    .domain(categories)
    .range([0, x0.bandwidth()])
    .padding(0.05);

  const maxVal = d3.max(aggregatedData, (d) => d3.max(d.values, (v) => v.value)) || 0;
  const y = d3
    .scaleLinear()
    .domain([0, maxVal])
    .nice()
    .range([height, 0]);

  const colorScale = d3
    .scaleOrdinal()
    .domain(categories)
    .range(this.custcolors);

  // Clear any previous svg
  d3.select(this.chartContainer).selectAll("*").remove();

  const svg = d3
    .select(this.chartContainer)
    .attr("width", this.width)
    .attr("height", this.height)
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  const groups = svg
    .append("g")
    .selectAll("g")
    .data(aggregatedData)
    .enter()
    .append("g")
    .attr("transform", (d) => `translate(${x0(d.key)},0)`);

  groups
    .selectAll("rect")
    .data((d) => d.values)
    .enter()
    .append("rect")
    .attr("x", (d) => x1(d.category))
    .attr("y", (d) => y(d.value))
    .attr("height", (d) => height - y(d.value))
    .attr("width", x1.bandwidth())
    .attr("fill", (d) => colorScale(d.category))
    .on("click", (event, d) => {
      d3.select(tooltipEl).style("opacity", 0);
      this.drilldowndata(d.parentKey);
    })
    .on("mouseover", (event, d) => {
      d3.select(tooltipEl)
        .style("opacity", 1)
        .html(`<strong>${d.parentKey}</strong><br><strong>${d.category}</strong>: ${d.value}`)
        .style("left", `${this.mouseX + 10}px`)
        .style("top", `${this.mouseY + 10}px`);
    })
    .on("mousemove", () => {
      d3.select(tooltipEl)
        .style("left", `${this.mouseX + 10}px`)
        .style("top", `${this.mouseY + 10}px`);
    })
    .on("mouseout", () => {
      d3.select(tooltipEl).style("opacity", 0);
    });

  // X Axis
  svg.append("g")
    .attr("transform", `translate(0,${height})`)
    .call(d3.axisBottom(x0))
    .selectAll("text")
    .attr("transform", "rotate(-20)")
    .style("text-anchor", "end")
    .text(function(d) {
      // Display truncated text, but keep 'd' as the original full label data
      return d.length > 10 ? d.substring(0, 10) + "..." : d;
    })
    .on("mouseover", (event, d) => {
      // If label is truncated, show full label in tooltip
      if (d.length > 10) {
        d3.select(tooltipEl)
          .style("opacity", 1)
          .html(`<strong>${d}</strong>`)
          .style("left", `${this.mouseX + 10}px`)
          .style("top", `${this.mouseY + 10}px`);
      }
    })
    .on("mousemove", () => {
      d3.select(tooltipEl)
        .style("left", `${this.mouseX + 10}px`)
        .style("top", `${this.mouseY + 10}px`);
    })
    .on("mouseout", () => {
      d3.select(tooltipEl).style("opacity", 0);
    });

  // Y Axis
  svg.append("g").call(d3.axisLeft(y));

  // X-Axis Label
  svg
    .append("text")
    .attr("x", width / 2)
    .attr("y", height + margin.bottom - 10)
    .attr("text-anchor", "middle")
    .style("font-size", "12px")
    .text(
      this.currentLevel === 1
        ? this.firstlevelxaxislabel
        : this.secondlevelxaxislabel
    );

  // Y-Axis Label
  svg
    .append("text")
    .attr("transform", "rotate(-90)")
    .attr("x", -(height / 2))
    .attr("y", -margin.left + 20)
    .attr("text-anchor", "middle")
    .style("font-size", "12px")
    .text(
      this.currentLevel === 1
        ? this.firstlevelyaxislabel
        : this.secondlevelyaxislabel
    );
},
    prepareLevelData() {
      if (!Array.isArray(this.originalData) || this.originalData.length === 0) {
        return [];
      }

      if (this.currentLevel === 1) {
        let topData = [];
        this.originalData.forEach(d => {
          if (d.forms && Array.isArray(d.forms)) {
            d.forms.forEach(f => {
              topData.push({
                templateName: d.templateName,
                ...f
              });
            });
          }
        });
        return topData;
      } else {
        const selectedTemplate = this.originalData.find(d => d.templateName === this.selectedTemplateName);
        if (!selectedTemplate || !Array.isArray(selectedTemplate.forms)) return [];
        
        return selectedTemplate.forms.map(f => ({
          templateName: selectedTemplate.templateName,
          ...f
        }));
      }
    },
    drilldowndata(parentKey) {
      if (this.currentLevel === 1) {
        this.selectedTemplateName = parentKey;
        this.currentLevel = 2;
        this.templatedata = this.prepareLevelData();
        this.createChart();
      } 
    },
    backtooriginal() {
      this.selectedTemplateName = null;
      this.currentLevel = 1;
      this.templatedata = this.prepareLevelData();
      this.createChart();
    },
    aggregateData() {
      if (!this.templatedata || this.templatedata.length === 0) return [];
      let groupKeys = this.currentLevel === 1 ? this.firstlevelkeys : this.secondlevelkeys;
      const groupedData = d3.group(
        this.templatedata,
        (d) => groupKeys.map(k => d[k]).join('-')
      );

      return Array.from(groupedData, ([key, records]) => {
        let values = this.barcategory.map((cat) => {
          const val = d3.sum(records, r => r[cat] || 0);
          return {
            category: cat,
            value: val,
            parentKey: this.currentLevel === 1 ? records[0].templateName : key
          };
        });
        return { key, values };
      });
    },
    showtable() {
      this.$emit("opendrillpopup", this.templatedata);
    },
    async exporttoexcell() {
      this.$emit("exporttoexcell");
    },
    exporttopdf() {
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = "none";
      const pdfContainer = document.createElement("div");

      const captureArea = this.$refs.component.cloneNode(true);
      pdfContainer.appendChild(captureArea);

      pdfContainer.style.width = "100%";
      pdfContainer.style.display = "block";
      const opt = {
        margin: 0,
        filename:  `${this.chartLabel}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "pt", format: "a4", orientation: "landscape" },
      };

      html2pdf().set(opt).from(pdfContainer).save();
    },
    updateMousePosition(event) {
      const rect = this.$refs.component.getBoundingClientRect();
      if (!rect) {
        console.error("Component reference is not defined or not yet mounted.");
        return;
      }
    
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    },
  },
};
</script>

<style scoped>
.legandbox {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}
.stat-card-white {
  background-color: #fff;
  padding: 1em;
  margin: 1em 0;
  position: relative; /* Ensure tooltip can position correctly */
}
.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 1000;
}
</style>
