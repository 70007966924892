<template>
    <!-- testwrapper -->
    <!-- <div v-if="defaultdashboard != 'home'" class="row wrapper border-bottom white-bg page-heading">
<div class="col-lg-12 text-right">
<h2><button type="button" class="btn btn-outline-success fontprop" @click="setasdefault()">Set as default</button></h2>
</div>
</div> -->
<!-- testwrapper -->
  <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-10 align-self-center">
          <h2>{{ $t('navigations.nurse dashboard')}}</h2>
      </div>
      <div class="col-lg-2 text-end align-self-center" v-if="defaultdashboard != 'nursesummary'">
        <button type="button" class="btn btn-setdefault fontprop" @click="setasdefault()">{{ $t('navigations.set as default')}}</button>
      </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">  
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="dash-widget cursor-pointer" @click.prevent="$router.push('/listform')">
            <div class="row">
              <div class="col-8 text-left">
                  <span> <h4>{{ $t('navigations.forms')}}</h4></span>
                      <h1 class="font-bold">{{formcountstd}}</h1>
              </div>
              <div class="col-4 justify-content-center align-self-center">
                  <img src="../../assets/img/db_newforms.png" alt="New Forms">
              </div>
            </div>
        </div>
      </div>  
      <!-- <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="ibox-body cursor-pointer" @click.prevent="$router.push('/listform')">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/new_forms.png" alt="New Forms">
              </div>
              <h5>{{ $t('navigations.forms')}}</h5>
          </div>
          <div class="ibox-content">
              <h1 class="no-margins">{{formcountstd}}</h1>
              <small>{{ $t('navigations.forms')}}</small>
          </div>
        </div>
      </div>   -->
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="dash-widget cursor-pointer" @click.prevent="$router.push('/allQueryList')">
            <div class="row">
              <div class="col-8 text-left">
                  <span> <h4>{{ $t('navigations.open queries')}}</h4></span>
                      <h1 class="font-bold">{{querycountstd}}</h1>
              </div>
              <div class="col-4 justify-content-center align-self-center">
                  <img src="../../assets/img/db_open_queries.png" alt="New Forms">
              </div>
            </div>
        </div>
      </div> 
      <!-- <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="ibox-body cursor-pointer" @click.prevent="$router.push('/allQueryList')">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/total_users.png" alt="Total queries">
              </div>
              <h5>{{ $t('navigations.open queries')}}</h5>
          </div>
          <div class="ibox-content">
              <h1 class="no-margins">{{querycountstd}}</h1>
              <small>{{ $t('navigations.open queries')}}</small>
          </div>
        </div>
      </div>   -->
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="dash-widget cursor-pointer" @click.prevent="$router.push('/sitelist')">
            <div class="row">
              <div class="col-8 text-left">
                  <span> <h4>{{ $t('navigations.total sites')}}</h4></span>
                      <h1 class="font-bold">{{sitecountstd}}</h1>
              </div>
              <div class="col-4 justify-content-center align-self-center">
                  <img src="../../assets/img/db_totalsites.png" alt="New Forms">
              </div>
            </div>
        </div>
      </div> 
      <!-- <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="ibox-body cursor-pointer" @click.prevent="$router.push('/sitelist')">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/total_sites.png" alt="Total Sites">
              </div>
              <h5>{{ $t('navigations.total sites')}}</h5>
          </div>
          <div class="ibox-content">
              <h1 class="no-margins">{{sitecountstd}}</h1>
          <small>{{ $t('navigations.total sites')}}</small>  
          </div>
        </div>
      </div>   -->
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="dash-widget cursor-pointer" @click.prevent="$router.push('/patientlist')">
            <div class="row">
              <div class="col-8 text-left">
                  <span> <h4>{{ $t('navigations.total subjects')}}</h4></span>
                      <h1 class="font-bold">{{patientscountstd}}</h1>
              </div>
              <div class="col-4 justify-content-center align-self-center">
                  <img src="../../assets/img/db_totalsubjects.png" alt="New Forms">
              </div>
            </div>
        </div>
      </div> 
      <!-- <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="ibox-body cursor-pointer" @click.prevent="$router.push('/patientlist')">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/total_patients.png" alt="Total Patients">
              </div>
              <h5>{{ $t('navigations.total subjects')}}</h5>
          </div>
          <div class="ibox-content">
              <h1 class="no-margins">{{patientscountstd}}</h1>
              <small>{{ $t('navigations.total subjects')}}</small>
          </div>
        </div>
      </div>    -->
        <!-- {{sendData.data.datasets[0].data}} and {{sendData.data.datasets[1].data}} and queries {{seconsData.data.datasets[0].data}} -->
        <div class="col-lg-6 pr-0 pl-1">
            <div class="ibox-body">
                <div class="ibox-title style_2 d-flex justify-content-between py-1">
                  <h5 class="mt-2">{{ $t('navigations.form activities')}}</h5>
                  <div class="graphbtncontainer">
                    <div class="graphbutton"><img src="../../assets/images/bar_clr.png" @click.prevent="firstchartchange('bar')"></div>
                    <div class="graphbutton"><img src="../../assets/images/horiz_bar_clr.png" @click.prevent="firstchartchange('barhorizondal')"></div>
                    <div class="graphbutton"><img src="../../assets/images/Line_clr.png" @click.prevent="firstchartchange('line')"></div>
                    <div class="graphbutton"><img src="../../assets/images/Pie_clr.png" @click.prevent="firstchartchange('pie')"></div>
                    <div class="graphbutton"><img src="../../assets/images/bubble_clr.png" @click.prevent="firstchartchange('scatter')"></div>
                  </div>
                </div>
                <div class="ibox-content">
                    <div>
                      <apexchart v-if="firstchart.charttype != 'pie' && firstchart.charttype != 'barhorizondal'" height="250px" width="100%" :type="firstchart.charttype" :options="firstchart.chartOptions" :series="firstchart.dataseries"></apexchart>
                      <apexchart v-if="firstchart.charttype == 'barhorizondal'" height="250px" width="100%" type="bar" :options="firstbarchart.chartOptions" :series="firstbarchart.dataseries"></apexchart>
                      <apexchart v-if="firstchart.charttype == 'pie'" height="250px" width="100%" :type="firstchart.charttype" :options="firstpiechartoptions" :series="firstchart.piedataseries"></apexchart>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 pr-0 pl-1">
            <div class="ibox-body">
                <div class="ibox-title style_2 d-flex justify-content-between py-1">
                  <h5 class="mt-2">{{ $t('navigations.queries')}}</h5>
                  <div class="graphbtncontainer">
                    <div class="graphbutton"><img src="../../assets/images/bar_clr.png" @click.prevent="secondchartchange('bar')"></div>
                    <div class="graphbutton"><img src="../../assets/images/horiz_bar_clr.png" @click.prevent="secondchartchange('barhorizondal')"></div>
                    <div class="graphbutton"><img src="../../assets/images/Line_clr.png" @click.prevent="secondchartchange('line')"></div>
                    <div class="graphbutton"><img src="../../assets/images/Pie_clr.png" @click.prevent="secondchartchange('pie')"></div>
                    <div class="graphbutton"><img src="../../assets/images/bubble_clr.png" @click.prevent="secondchartchange('scatter')"></div>
                  </div>
                </div>
                <div class="ibox-content">
                    <div>
                      <apexchart v-if="secondchart.charttype != 'pie'" height="250px" width="100%" :type="secondchart.charttype" :options="secondchart.chartOptions" :series="secondchart.dataseries"></apexchart>
                      <apexchart v-if="secondchart.charttype == 'pie'" height="250px" width="100%" :type="secondchart.charttype" :options="secondchart.piechartOptions" :series="secondchart.piedataseries"></apexchart>
                        <!-- <canvas id="barChart" height="140"></canvas> -->
                    </div>
                </div>
            </div>
        </div>
      <!--new form table-->
      <div class="col-lg-12 my-1 pl-1 pr-0">
        <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
            <h5 class="my-0">{{ $t('navigations.uploaded forms')}}</h5>
            <div class="position-relative cursor-pointer" v-if="formlists.length!=0" @click.prevent="showFilter = !showFilter"><img
              src="../../assets/img/filter.png" width="17" height="17" alt="filter table" />
            <div class="tool-tip">{{ $t("navigations.filter") }}</div>
          </div>
          </div>
          <div class="ibox-content">
            <div class="d-flex justify-content-end px-0 mb-2" v-if="showFilter" >
              <div class="col-3 px-0" v-on:clickout="dropdownOpen=false">
                <div class=" table-multi-select-dropdown" @click.stop="dropdownOpen = !dropdownOpen">
                  <div class="selected-options">
                    <span>Select Columns</span>
                  </div>
                  <div class="dropdown-arrow"></div>
                </div>
                <div v-if="dropdownOpen" class="table-dropdown-content" @click.stop>
                    <label v-for="(column, index) in getColumns" :key="index">
                      <input type="checkbox" v-model="selectedcolumnobj[column]" :value="true" /> {{ column }}
                    </label>
                  </div>
                </div>             
            </div>
            <div class="text-center" v-if="formlists.length===0">
              No data available
            </div>
            <div v-else class="table-responsive">
                <table class="table table-striped table-bordered dataTables" >
                    <thead>
                    <tr>
                        <th v-if="selectedcolumnobj['Site Code']">{{ $t('navigations.site code')}}</th>
                        <th v-if="selectedcolumnobj['Subject ID']">{{ $t('navigations.subject id')}}</th>
                        <th v-if="selectedcolumnobj['Visit Name']">{{ $t('navigations.visit name')}}</th>
                        <th v-if="selectedcolumnobj['Form Name']">{{ $t('navigations.form name')}}</th>
                        <th v-if="selectedcolumnobj['Status']">{{ $t('navigations.status')}}</th>
                        <th v-if="selectedcolumnobj['Uploaded By']">{{ $t('navigations.uploaded by')}}</th>
                        <th v-if="selectedcolumnobj['Uploaded Date']">{{ $t('navigations.uploaded date')}}</th>
                        <th class="text-center" v-if="roleprivileges.includes('Forms View Form')">{{ $t('navigations.view')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr  v-for="list in formlists" :key="list.formId">
                            <td v-if="selectedcolumnobj['Site Code']">{{list.siteId}}</td>
                            <td v-if="selectedcolumnobj['Subject ID']">{{list.subjectId}}</td>
                            <td v-if="selectedcolumnobj['Visit Name']">{{list.visitName}}</td>
                            <td v-if="selectedcolumnobj['Form Name']">{{list.formName }}</td>
                            <td v-if="selectedcolumnobj['Status']">{{list.formStatus}}</td>
                            <td v-if="selectedcolumnobj['Uploaded By']">{{list.createdUserName}}</td>
                            <td v-if="selectedcolumnobj['Uploaded Date']">{{filter(list.createdAt.slice(0,10))}}</td>
                            <td class="text-center" v-if="roleprivileges.includes('Forms View Form')"><i class="fa fa-eye editicon" @click.prevent="viewforms(list.formId,list.patientId)"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
            <!--new form table-->
      </div>


    </div>
    
  </div>
</template>
<script src="./nurse_dashboard.js"></script>
<style scoped>
.widget {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.widgetmargin {
  margin-top: -13px !important;
}

.style1 {
  background-color: black !important;
  color: #ffffff;
}
.btn-view {
  color: #fff !important;
  background-color: #bb458f !important;
  border-color: #bb458f !important;
}
.btn-view:hover {
  color: #fff;
  background-color: #666ccb;
  border-color: #666ccb;
}
.btn-view:active {
  color: rgb(255, 255, 255) !important;
  background-color: #dda2c7 !important;
  border-color: #dda2c7 !important;
}

.titlemsg {
  border-color: #e7eaec;
  margin-bottom: 0;
  padding: 20px 20px;
  position: relative;
  clear: both;
  right: 0;
  margin-right: 0px;
  margin-left: 0px !important;
  margin-top: 0px !important;
  padding-left: 10px;
}
.padding_bottom {
  padding-bottom: 2rem;
}
.ibox-title h5 {
  height: 0rem;
  padding: 0rem;
}
.ibox-title h5:hover {
  background-color: inherit;
  padding: 0;
  cursor: not-allowed;
}
.titlemsg {
  font-size: 15px;
  font-weight: bold;
  color: var(--primary-color);
}
.switchbtn {
  background-color: #bb458f !important;
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
}
.switchbtn:active {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.switchbtn:hover {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.queryhover:hover {
  color: #3c4192 !important;
  -webkit-transition: color ease-in 400ms;
  transition: color ease-in 400ms;
  cursor: pointer;
}

.widgetminheight {
  min-height: 102px;
}
.graphbutton{
width: fit-content;
padding: 3px;
cursor:pointer;
}
.graphbtncontainer{
display: flex;
gap: 5px;
}
img{
  margin-left: 0px !important;
}
.selectdefault{
  left: 0;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-setdefault {
  position: relative;
  padding:0.2rem 0.4rem;
  text-align: center;
  font-size:0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius:3px;
}
.ibox-content h1{
  font-weight:400;
  color:#676a6c
}
</style>
