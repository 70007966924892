<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.raise query") }}</h2>
    </div>
    <!-- <div class="col-lg-2">
      <i class="fa fa-times close my-0" @click="$router.go(-1)"></i>
    </div> -->
    <div class="col-lg-2">
  <span class="tooltip-wrapper pointer" style="float:right">
    <i class="fa fa-times close" @click="$router.go(-1)"></i>
    <span class="custom-tooltip">Close</span>
  </span>
</div>
  </div>

  <div class="wrapper wrapper-content">
    <div class="ibox-content p-2">
      <div class="form-group row my-lg-2">
        <div class="col-lg-3 mb-1">
          <label class="col-form-label"
            >{{ $t("navigations.site code") }}
          </label>
          <input
            type="text"
            class="form-control mb-1 my-lg-0"
            v-model="values.SiteCode"
            disabled
          />
        </div>

        <div class="col-lg-3 mb-1">
          <label class="col-form-label"
            >{{ $t("navigations.subject id") }}
          </label>
          <input
            type="text"
            class="form-control mb-1 my-lg-0"
            v-model="values.SubjectId"
            disabled
          />
        </div>

        <div v-if="routeQuery === 'fieldQuery'" class="col-lg-3 mb-1">
          <label class="col-form-label"
            >{{ $t("navigations.visit name") }}
          </label>
          <input
            type="text"
            class="form-control mb-1 my-lg-0"
            v-model="values.VisitName"
            disabled
          />
        </div>

        <div v-if="routeQuery === 'fieldQuery'" class="col-lg-3 mb-1">
          <label class="col-form-label"
            >{{ $t("navigations.field name") }}
          </label>
          <input
            type="text"
            class="form-control mb-1 my-lg-0"
            v-model="values.FieldName"
            placeholder=""
            disabled
          />
        </div>
        <div v-if="routeQuery === 'formQuery'" class="col-lg-6 mb-1">
          <label class="col-form-label"
            >{{ $t("navigations.visit name") }}
          </label>
          <input
            type="text"
            class="form-control mb-1 my-lg-0"
            v-model="values.VisitName"
            placeholder=""
            disabled
          />
        </div>
        <div v-if="routeQuery === 'pageQuery'" class="col-lg-3 mb-1">
          <label class="col-form-label"
            >{{ $t("navigations.visit name") }} 
          </label>
          <input
            type="text"
            class="form-control mb-1 my-lg-0"
            v-model="values.VisitName"
            placeholder=""
            disabled
          />
        </div>
        <div v-if="routeQuery === 'pageQuery'" class="col-lg-3 mb-1">
          <label class="col-form-label">{{
            $t("navigations.page name")
          }}</label>
          <input
            type="text"
            class="form-control mb-1 my-lg-0"
            v-model="values.PageName"
            placeholder=""
            disabled
          />
        </div>

        <div class="col-lg-12 mb-1">
          <label class="col-form-label">
            {{ $t("navigations.query subject") }}
            <sup><i class="fa fa-asterisk required"></i></sup>
          </label>
          <input type="text" class="form-control" maxlength="1000" v-model="values.Subject" />
          <span class="errorClass" v-if="errors[0]">
            {{ $t("navigations.please enter query subject") }}</span
          >
        </div>

        <div class="col-lg-12 mb-1">
          <label class="col-form-label">
            {{ $t("navigations.query message") }}
            <sup><i class="fa fa-asterisk required"></i></sup>
          </label>
          <textarea
            class="form-control mb-1 my-lg-0"
            rows="4"
            cols="150"
            v-model="values.Message"
          >
          </textarea>
          <span class="errorClass" v-if="errors[1]">{{
            $t("navigations.please enter query message")
          }}</span>
        </div>

        <div class="col-lg-4 mb-1">
          <label class="col-form-label">{{ $t("navigations.priority") }}</label>
          <select
            class="form-control m-b"
            name="account"
            v-model="values.Priority"
          >
            <option value="">{{ $t("navigations.low") }}</option>
            <option value="Medium">{{ $t("navigations.medium") }}</option>
            <option value="High">{{ $t("navigations.high") }}</option>
          </select>
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label"
            >{{ $t("navigations.role") }}
            <sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <Multiselect
            @close="onroleClosed()"
            @clear="closebtnclicked()"
            v-model="multiRoleSelectList"
            mode="multiple"
            valueProp="roleID"
            :placeholder="$t('navigations.select roles')"
            label="roleName"
            :options="roleList"
            :hideSelected="false"
            :closeOnSelect="false"
            :searchable="true"
            trackBy="roleName"
            class="multiselect-theme"
          >
            <template v-slot:option="{ option }">
              {{ option.roleName }}
            </template>
          </Multiselect>
          <span class="errorClass" v-if="errors[3]">{{
            $t("navigations.please select role")
          }}</span>
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label"
            >{{ $t("navigations.recipients") }}
            <sup><i class="fa fa-asterisk required"></i></sup>
          </label>

          <Multiselect
            @close="onRecipientsClosed()"
            @clear="recepientclosebtnclicked()"
            v-model="values.Recipients"
            mode="multiple"
            valueProp="email"
            :placeholder="$t('navigations.select recipients')"
            label="recipients"
            :options="recipients"
            :hideSelected="false"
            :closeOnSelect="false"
            :searchable="true"
            trackBy="email"
            class="multiselect-theme"
          >
            <template v-slot:option="{ option }">
              {{ option.email }}
            </template>
          </Multiselect>
          <span class="errorClass" v-if="errors[2]">{{
            $t("navigations.please select recipient")
          }}</span>
        </div>

        <div class="col-lg-12 my-2 d-flex">
          <div class="col-lg-4">
            <div class="form-group">
              <label>
                <input
                  aria-expanded="true"
                  v-model="chksendmail"
                  aria-controls="collapseExample"
                  type="checkbox"
                  @click="check()"
                />
                {{ $t("navigations.send an email alert") }}
              </label>
            </div>
            <div class="ibox" id="collapseOne" v-if="checkboxclick">
              <table
                class="
                  table table-striped table-bordered table-hover
                  dataTables-example
                  dataTable
                "
              >
                <tbody>
                  <tr v-for="options in emailrecipients" :key="options.id">
                    <td>
                      <input
                        type="checkbox"
                        checked="checked"
                        :value="options.isChecked"
                        v-model="options.isChecked"
                        :id="options.id"
                      />
                      {{ options.evalu }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-12 my-1 d-flex">
          <div class="col-lg-12 text-right">
            <button
              class="cancel_btn py-1 px-4 mx-3"
              type="submit"
              @click="ClearData()"
            >
              {{ $t("navigations.clear") }}
            </button>
            <button
              class="save_btn py-1 px-4"
              type="submit"
              @click="CreateQuery()"
            >
              {{ $t("navigations.submit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import auth0 from "auth0-js";
//import Multiselect from 'vue-multiselect'
import Multiselect from "@vueform/multiselect";
export default {
  name: "raiseQuery",
  components: { Multiselect },

  props: { FormId: String, routeQuery: String, pageno: Number },
  data() {
    return {
      chksendmail: "",
      options: {
        isChecked: "",
      },
      baseUrl: process.env.VUE_APP_Service_URL,
      checkboxclick: false,
      values: {
        studyId: "",
        siteId: "",
        SiteCode: "",
        SubjectId: "",
        VisitName: "",
        FieldName: "",
        formName: "",
        Subject: "",
        Message: "",
        Sender: "",
        Priority: "",
        Recipients: [],
        PageNo: 0,
        PageName: "",
        QueryFor: "",
      },
      formId: "",
      feilName: "",
      formData: "",
      arrayofdata: [],
      recipients: [],
      subject: "",
      site: "",
      userName: "",
      errors: [false, false, false, false],
      newModel: {
        fullNAme: "",
        email: "",
      },
      listNewModel: [],
      roleList: [],
      multiRoleSelectList: [],
      emailrecipients: [],
      selectedemailrecipients: [],
      passstudyname: "",
    };
  },
  async mounted() {
    window.addEventListener("studyIdChanged", (event) => {
      //  console.log("StudyIDChanged into" + event.detail.storage);
      // window.location.reload();
      this.getUserName();
      this.splitdata(this.FormId);
      this.GetFormData();
      this.getPatient(this.formData.subjectId);
      this.getSites(this.formData.siteId);
      this.bindData();
      this.setRoleList();
      this.getStudyname();
      // this.getRecipients();
      this.values.studyId = store.getters.getStudyIs;
    });
    await this.getStudyname();
    await this.getUserName();
    await this.splitdata(this.FormId);
    await this.GetFormData();
    await this.getPatient(this.formData.subjectId);
    await this.getSites(this.formData.siteId);
    await this.bindData();
    // await this.getRecipients();
    await this.setRoleList();
    this.values.studyId = store.getters.getStudyIs;
  },
  methods: {
    async CreateQuery() {
      console.log("priority is", this.values.Priority);

      //  console.log("recpnt are these",this.values.Recipients);
      await this.validateIsNull();
      if (
        !this.errors[0] &&
        !this.errors[1] &&
        !this.errors[2] &&
        !this.errors[3]
      ) {
        await this.getselectedemailrecepients();
        const idtoken = store.getters.getIdToken;
        //  console.log("id token is ", idtoken);
        await axios
          .post(
            `${this.baseUrl}/forms/query/create`,
            //`https://localhost:5002/query/create`,
            {
              queryId: "",
              formId: this.arrayofdata[0],
              studyId: this.values.studyId,
              folderId: this.formData.folderId,
              siteId: this.formData.siteId,
              subjectId: this.formData.subjectId,
              visitId: this.formData.visitName,
              queryFor: this.values.QueryFor,
              query_Subject: this.values.Subject,
              priority:
                this.values.Priority == "" ? "Low" : this.values.Priority,
              pageNo: this.pageno,
              fieldName: this.values.FieldName,
              queryOpenStatus: "true",
              queryResponseStatus: true,
              closeBy: "",
              emailRecepients: this.selectedemailrecipients,
              sitecode: this.values.SiteCode,
              formname: this.values.formName,
              pagename: this.values.PageName,
              patientid: this.values.SubjectId,
              studyname: this.passstudyname,
              messages: [
                {
                  queryMessage: this.values.Message,
                  raisedBy: this.values.Sender,
                  raisedDate: new Date().toISOString(),
                  messagesRecipients: [
                    {
                      recipients: this.values.Recipients,
                    },
                  ],
                },
              ],
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("create Query ", idtoken);
          })
          .catch((err) => console.log("error", err));
        alert(this.$t("navigations.query generated successfully"));
        await this.ClearData();
        //this.$router.push(`/showform/forms/`+this.arrayofdata[0]);
        const archive = false;
        this.$router.push(`/showform/forms/` + this.arrayofdata[0] + "/" +  archive);
      }

      //this.$router.push(`/showform/${this.arrayofdata[0]}`);
    },
    async ClearData() {
      (this.chksendmail = ""), (this.checkboxclick = false);
      (this.multiRoleSelectList = []),
        // this.emailrecipients=[],
        //options.isChecked.checked=false,
        await this.onroleClosed(),
        //    console.log("clear Query..!");
        (this.values = {
          SiteCode: "",
          SubjectId: "",
          VisitName: "",
          FieldName: "",
          formName: "",
          Subject: "",
          Message: "",
          Sender: "",
          Priority: "",
          Recipients: [],
          PageNo: 0,
          QueryFor: "",
        });
      await this.bindData();
    },
    async getStudyname() {
      const studyname = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      console.log("form passing studyanme .......", studyname);
      await axios
        .get(
          `${this.baseUrl}/management/study/getbyid/${studyname}?api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.passstudyname = res.data.studyName;
          console.log("form data .......", this.passstudyname);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async onclosed() {
      //this.$router.push(`/showform/forms/` + this.arrayofdata[0]);
       const archive = false;
        this.$router.push(`/showform/forms/` + this.arrayofdata[0] + "/" + archive);
    },
    async GetFormData() {
      const idtoken = store.getters.getIdToken;
      console.log(idtoken);
      await axios
        .get(`${this.baseUrl}/forms/forms/getbyid/` + this.arrayofdata[0], {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.formData = res.data;
          //  console.log("form data .......", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getPatient(patientId) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseUrl}/management/patient/getpatientbyid/` + patientId, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => (this.subject = res.data))
        .catch((err) => console.log(err));
    },
    async getSites(siteId) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseUrl}/management/site/getbyid?id=` + siteId, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => (this.site = res.data))
        .catch((err) => console.log(err));
    },
    async bindData() {
      this.values.SiteCode = this.site.siteCode;
      this.values.SubjectId = this.subject.subjectId;
      this.values.VisitName = this.formData.visitName;
      this.values.Sender = this.userName;
      //console.log("pages...", this.formData.pages[0].pageName);
      this.values.PageName = this.formData.pages[this.pageno - 1].pageName;
      this.values.formName = this.formData.formName;
      if (this.routeQuery === "formQuery") {
        this.values.QueryFor = "Form";
      } else if (this.routeQuery === "pageQuery") {
        this.values.QueryFor = "Page";
      } else if (this.routeQuery == "fieldQuery") {
        this.values.QueryFor = "Field";
        this.values.FieldName = this.arrayofdata[1];
      }
    },
    async splitdata(id) {
      this.arrayofdata = id.split("modelNameIs_", 2);
    },
    async jwtDecrypt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    async getUserName() {
      const idToken = store.getters.getIdToken;
      const jwtDecodedValue = await this.jwtDecrypt(idToken);
      this.userName = jwtDecodedValue.name;
      //  console.log("token result", this.userName);
    },
    async validateIsNull() {
      if (this.values.Subject == "") {
        this.errors[0] = true;
      } else {
        this.errors[0] = false;
      }

      if (this.values.Message == "") {
        this.errors[1] = true;
      } else {
        this.errors[1] = false;
      }

      if (this.values.Recipients == "") {
        this.errors[2] = true;
      } else {
        this.errors[2] = false;
      }

      if (this.multiRoleSelectList == "") {
        this.errors[3] = true;
      } else {
        this.errors[3] = false;
      }
    },
    async getUserName() {
      const idToken = store.getters.getIdToken;
      const jwtDecodedValue = await this.jwtDecrypt(idToken);
      this.userName = jwtDecodedValue.name;
      console.log("token result", this.userName);
    },
    async setRoleList() {
      //  console.log("roles here.");
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseUrl}/account-core/roles/rolelist`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.roleList = res.data;
          console.log("roles are..", this.roleList);
        });
    },
    async onroleClosed() {
      this.emailrecipients.splice(0);
      this.recipients.splice(0);
      this.values.Recipients.splice(0);
      //  console.log("dClosEd",this.multiRoleSelectList);
      await this.getrolebasedrecepients();
    },
    async onRecipientsClosed() {
      this.selectedemailrecipients.splice(0);
       this.values.Recipients.forEach((recipient) => {
        const exists = this.emailrecipients.find((emailRecipient) => emailRecipient.evalu === recipient);
        if (!exists) {
          let eusers = {
            id: String,
            evalu: String,
            isChecked: Boolean,
          };
          eusers.evalu = recipient;
          eusers.isChecked=true;
          this.emailrecipients.push(eusers);
        }
      });
       this.emailrecipients.forEach((emailRecipient) => {
        const exists = this.values.Recipients.find((recipient) => emailRecipient.evalu === recipient);
        if (!exists) {
          const index = this.emailrecipients.indexOf(emailRecipient);
          this.emailrecipients.splice(index,1);
        }
      });
    },

    async closebtnclicked() {
      this.emailrecipients.splice(0);
      this.recipients.splice(0);
      this.values.Recipients.splice(0);
    },
    async recepientclosebtnclicked() {
      console.log("RECP CLOSED", this.multiRoleSelectList);
      this.emailrecipients.splice(0);
    },

    async getrolebasedrecepients() {
      console.log("In get role based recipients");
      const valnow = await store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      var rcount = this.multiRoleSelectList.length;
      if (rcount > 0) {
        var fullroleids = "";
        for (var i = 0; i < rcount; i++) {
          fullroleids = fullroleids + this.multiRoleSelectList[i] + ",";
        }
        console.log("formdata", this.formData.siteId);
        await axios
          .get(
            // `https://localhost:5001/user/getusersbyrole?roleids=`+fullroleids+`&&studyid=`+valnow,{
            `${this.baseUrl}/account-core/user/getusersbyrole?roleids=` +
              fullroleids +
              `&studyid=` +
              valnow +
              `&siteId=` +
              this.formData.siteId,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("recipients list is ..", this.recipients);
            this.recipients = [];
            res.data.forEach((element) => {
              if (element.email != this.userName) {
                this.recipients.push(element);
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.recipients = [];
      }
    },

     async check(){
        console.log("is check clicked",this.checkboxclick)  
              if(this.checkboxclick)
              { 
                this.checkboxclick =false;
                this.emailrecipients = [];
              }else{  
                  this.checkboxclick =true;
                  this.emailrecipients = [];
                  this.values.Recipients.forEach((element) => {
                    let eusers = {
                      id: String,
                      evalu: String,
                      isChecked: Boolean,
                    };
                  
                      eusers.evalu = element;
                      eusers.isChecked=true;
                      this.emailrecipients.push(eusers);
                    
                  });
              }
          
     },
    async getselectedemailrecepients() {
      if (this.checkboxclick == true) {
        //this.selectedemailrecipients =this.emailrecipients.check;
        this.emailrecipients.forEach((element) => {
          console.log("IIFREselectedemailrecipients", element);
          console.log("OOREselectedemailrecipients", element.evalu);
          if (element.isChecked) {
            console.log("BFREselectedemailrecipients", element);
            this.selectedemailrecipients.push(element.evalu);
          }
        });
      } else {
        console.log("Unchecked!");
      }
      console.log("selectedemailrecipients", this.selectedemailrecipients);
    },
  },
};
</script>
<style scoped>
@import "../../assets/savepages.css";
.multiselect-theme {
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
}
.form-control {
  height: auto !important;
}
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  visibility: hidden;
  width: 60px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 110%; /* Position the tooltip above the icon */
  left: 10%;
  margin-left: -30px; /* Use half of the width to center-align */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip-wrapper:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
}

</style>