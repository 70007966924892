<template>
    <div class="wrapper wrapper-content pb-1">
      <div class="px-3 py-2">
        <h3>Study Overview</h3>
        <hr class="mt-0" />
        <div class="row">
          <div class="study-info-block col-12 col-md-7">
            <div class="text-center" v-if="isDescLoading">
              <img src="../../assets/img/loading.webp" class="loading-image" width="20" alt="Waiting for video" />
              <p>Loading</p>
            </div>
            <div v-else>
              {{ description }}
            </div>
          </div>
          <div class="col-12 col-md-5">
            <h3 class="mt-0">Watch Videos</h3>
            <hr class="mt-0" />
            <div class="text-center" v-if="isLoading">
              <img src="../../assets/img/loading.webp" class="loading-image" width="20" alt="Waiting for video" />
              <p>Loading</p>
            </div>
            <div v-else-if="videolist.length === 0" class="d-flex flex-column justify-content-center align-items-center">
              <img src="../../assets/img/icons/watch_overview.png" alt="No videos" />
              <h4>No videos found</h4>
            </div>
            <div v-else class="videos-block row mx-0 mb-3" :class="{ 'height-scroll': addClass }">
              <div class="col-12 col-md-6 text-center" :class="{ 'mb-2': addClass }" v-for="video in videolist"
                :key="video.id">
                <div class="videos-block-tile text-center p-4 mb-2 cursor-pointer"
                  @click="openModal('video', '', '', video.id)">
                  <img src="../../assets/img/icons/watch_overview.png" alt="Video tile" />
                </div>
                {{ video.videoName }}
              </div>
            </div>
            <div class="document-block">
              <h3>Read Documents</h3>
              <hr class="mt-0" />
              <div class="text-center" v-if="isVideoLoading">
                <img src="../../assets/img/loading.webp" class="loading-image" width="20" alt="Waiting for video" />
                <p>Loading</p>
              </div>
              <div v-else-if="documentlist.length === 0"
                class="d-flex flex-column justify-content-center align-items-center">
                <img src="../../assets/img/icons/Read_documents.png" alt="No documents" />
                <h4>No documents found</h4>
              </div>
              <div v-else>
                <div class="doc-list px-2" :class="{ 'document-height-scroll': addScrollClass }">
                  <div v-for="document in documentlist" :key="document.id"
                    class="doc-block d-flex cursor-pointer py-2 px-3 mb-2" @click="
                      openModal(
                        'document',
                        document.id,
                        document.documentVersions[0].documentVersionId,'',document.documentReferenceId
                      )
                      ">
                    <img class="px-2" src="../../assets/img/icons/Read_documents.png" width="41" alt="Read document" />
                    <p class="mb-0">{{ document.documentName }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4>Terms and Condition</h4>
        <hr class="mt-0" />
        <div class="d-flex">
          <input type="checkbox" v-model="checkedValue" :value="checkedValue" :checked="patientLogin.hasTrained == true"
            :disabled="patientLogin.hasTrained == true" @change="checkedValue ? false : true" name="terms-checkbox"
            id="termsCheckbox" />
          <label class="checkbox-rounded" for="termsCheckbox">By selecting this box, you confirm your agreement to the IDS
            Terms and
            Conditions, Privacy Policy, and our cookie usage.</label>
        </div>
        <div class="button-container">
          <button type="button" class="btn-outline btn-default py-1 px-5 mr-3"
            @click.prevent="$router.push(`/patientupdate/${patientLoginId}`)">Back</button>
          <button type="button" class="save_btn py-1 px-5" @click.prevent="nextPage()" :disabled="checkedValue == false">
            Continue
          </button>
        </div>
      </div>
      <button class="video-call-block" type=""  @click.prevent="CreateVideoCallSchedulePopUp = true">
        <img src="../../assets/img/video_call.png" title="Video Call" alt="Video call" />
      </button>
    </div>
    <teleport to="#app" v-if="showModal">
      <Modal
        @closeModal="showModal = false"
        :videoId="modelvideoId"
        :version="versionId"
        :modalId="Id"
        :modalReferenceId="ReferenceId"
        :modaltype="modalType"
      />
    </teleport>
    <CreateVideoCallSchedulePopUp
v-if="CreateVideoCallSchedulePopUp"
@closeModal="CreateVideoCallSchedulePopUp = false"
  :email="userEmail"
      :isFromTrainingMaterials="true"
/>
  
    <!-- patient vedio call -->
    <div class="patient__login__modal__wrapper" v-if="showPopup == true">
      <div class="patient__login__modal__container">
        <div class="modal__title d-flex justify-content-between py-2 px-3">
          <h4 class="my-0 align-self-center">{{ $t("navigations.request video call") }}</h4>
          <span type="button" class="align-self-center" data-dismiss="modal" @click="closemodal()" aria-hidden="true"><i
              class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="p-4">
           <!-- <div>
              <label>Date and time</label>
              <input class="btn-outline btn-default w-100 align-self-center py-2 px-3 mr-3 mr-5" type="datetime-local"
                placeholder="Date" v-model="dateAndTime" :min="getCurrentDateTime()" />
            </div> -->
          <div class="form-group">
            <div>
              <label>Date </label>
              <input class="btn-outline btn-default w-100 align-self-center py-2 px-3 mr-3 mr-5 mb-2" type="date"
                placeholder="Date" v-model="date" :min="getCurrentDate()"/>
            </div>
            <div>
              <label>Time </label>
              <input class="btn-outline btn-default w-100 align-self-center py-2 px-3 mr-3 mr-5" type="time"
                placeholder="Time" v-model="time"  />
            </div>
          </div>
          <div class="text-center">
            <button @click.prevent="requestVideoCall()" class="btn-outline btn-default align-self-end ml-2 py-2 px-4">
              ✔{{ $t("navigations.request") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- patient vedio call -->
  </template>
  <script src="./trainingMaterials.js"></script>
  <style scoped>
  input[type="checkbox"] {
    display: none;
  }
  
  input[type="checkbox"]:checked+.checkbox-rounded::before {
    background-color: #059f8c;
    border-color: #059f8c;
  }
  
  .checkbox-rounded::before {
    content: "\f00c";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 0.7rem;
    text-align: center;
    width: 18px;
    height: 18px;
    color: #fff;
    margin-right: 10px;
    border: 1px solid #bbb;
    border-radius: 50%;
    background-color: #fff;
  }
  
  .videos-block-tile,
  .doc-block {
    background: #ebebeb;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }
  
  .doc-block p {
    font-size: clamp(0.9rem, 2.5vw, 1rem);
  }
  
  .video-call-block {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  
  .patient__login__modal__wrapper {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 3000;
  }
  
  .patient__login__modal__container {
    position: fixed;
    width: min(500px, 90%);
    border-radius: 4px;
    background: #fff;
    overflow-x: hidden;
  }
  
  .modal__title {
    background-color: #059f8c;
    color: white;
  }
  
  .loading-image {
    mix-blend-mode: difference;
  }
  
  button:disabled {
    opacity: 0.5;
    color: #ddd;
    cursor: not-allowed;
  }
  
  .height-scroll {
    height: 130px;
    overflow-y: scroll;
  }
  
  .document-height-scroll {
    height: 200px;
    overflow-y: scroll;
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    width: 2px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
    width: 15px;
  }
  </style>
  