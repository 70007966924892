<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>{{ $t('navigations.add/edit site') }}</h2>
        </div>          
        <!-- <div class="col-lg-2">
           <i class="fa fa-times close my-2" title="Close" @click="onclosed()"></i>
        </div> -->
        <div class="col-lg-2">
  <span class="tooltip-wrapper pointer" style="float:right">
    <i class="fa fa-times close" @click="onclosed()"></i>
    <span class="custom-tooltip">Close</span>
  </span>
</div>
    </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content p-3">
      <div class="form-group row">
        <div class="col-lg-4 mb-1">
          <label class="col-form-label"
            >{{ $t('navigations.site code') }} <sup><i class="fa fa-asterisk imp"></i></sup>
          </label>
          <input type="text" class="form-control" v-model="value.siteCode"  maxlength="1000" :disabled="value.status === 'Enrolled'"/>
          <span class="errormsg animated bounce" v-if="errors[0]"> {{ $t('navigations.please enter site code') }} </span>
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label">{{ $t('navigations.site name') }} </label>
          <input type="text"  maxlength="1000" class="form-control" v-model="value.siteName" :disabled="value.status === 'Enrolled'" />
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label">{{ $t('navigations.site contact name') }} </label>
          <input
            type="text"
            class="form-control"
            v-model="value.siteContactName"  maxlength="1000"
          />
        </div>

        <div class="col-lg-4 mb-1">
          <label class="col-form-label">{{ $t('navigations.site contact email') }} </label>
          <input
            type="email"
            class="form-control"
            v-model="value.siteContactEmail" maxlength="1000"
          />
          <span class="errormsg" v-if="errors[1]">{{ $t('navigations.please enter a valid email') }}</span>
        </div>
        <div  class="col-lg-4 mb-1">
          <label class="col-form-label">{{ $t('navigations.status') }} </label>
          <select
            type="text"
            class="form-control"
            name="account"
             v-model="value.status" :disabled="value.status"
          >
            <option value="New">{{ $t('navigations.new') }}</option>
            <option v-if="disableStatus" value="Open">{{ $t('navigations.open') }}</option>
            <option v-if="disableStatus" value="Visit Completed">{{ $t('navigations.visit completed') }}</option>
            <option v-if="disableStatus" value="Archived">{{ $t('navigations.archived') }}</option>
            <option v-if="disableStatus" value="Closed">{{ $t('navigations.closed') }}</option>
            <option v-if="disableStatus" value="Enrolled">{{ $t('navigations.enrolled') }}</option>
            <option v-if="disableStatus" value="Terminated">{{ $t('navigations.terminated') }}</option>
            <option v-if="disableStatus" value="Completed">{{ $t('navigations.completed') }}</option>
            <option v-if="disableStatus" value="Close">{{ $t('navigations.close') }}</option>

          </select>
        </div>

        <div class="col-lg-4 mb-1">
          <label class="col-form-label">{{ $t('navigations.number of subjects') }} </label>
          <input
            type="number"
            class="form-control" maxlength="4"
            v-model="value.numberOfPatient"  @change.prevent="numberonlyvalidation('numberOfPatient')"
          />
            
          <span class="errormsg" v-if="errors[4]">{{ $t('navigations.invalid number') }}</span>
        </div>
        <div class="col-lg-12 mb-1">
          <label class="col-form-label">{{ $t('navigations.site notes')}} </label>
          <input type="text" class="form-control" v-model="value.siteNotes"  maxlength="1000"/>
          <span class="errormsg" v-if="errors[5]">{{ $t('navigations.invalid site note') }}</span>
        </div>
        <div class="col-lg-6 my-3 d-flex">
          <div class="input-group date">
            <div class="i-checks">
              <label class="mr-2">{{ $t('navigations.active') }}</label>
              <input
                type="checkbox"
                checked="checked"
                value=""
                v-model="value.active"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 my-3 d-flex justify-content-lg-end">
          <button
            class="cancel_btn py-1 px-4 mx-3"
            type="submit"
            @click="clear()"
          >
            {{ $t('navigations.clear') }}
          </button>
          <button class="save_btn py-1 px-4" type="submit" @click="save()">
            {{ $t('navigations.save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
export default {
  props: { userid: String },
  data() {
    return {
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/site/`,
      disableStatus: true,
      value: {
        accountId: "",
        studyId: "",
        siteCode: "",
        siteName: "",
        siteContactEmail: "",
        siteContactName: "",
        status: "",
        siteNotes: "",
        numberOfPatient: 0,
        active: true,
      },
      editValue: {
        siteID: "",
        accountId: "",
        studyId: "",
        siteCode: "",
        siteName: "",
        siteContactName: "",
        siteContactEmail: "",
        status: "",
        siteNotes: "",
        numberOfPatient: 0,
        active: true,
      },
      idToken: "",
      headerValues: {},
      errors: [false, false, false, false, false, false, false],
     
    };
  },
  async mounted() {
    this.idToken = store.getters.getIdToken;
    this.headerValues = {
      headers: {
        Authorization: "Bearer " + this.idToken,
        "Content-Type": "application/json",
      },
    };

    this.errors[6] = false;
        this.idToken = await store.getters.getIdToken;
    this.headerValues = {
      headers: {
        Authorization: "Bearer " + this.idToken,
        "Content-Type": "application/json",
      },
    };
    if (this.userid !== "0") {
      await this.getSite(this.userid);
      this.disableStatus = true;
      this.setValues();
    } else {
      console.log("will set disabled status to false");
      this.disableStatus = false;
    }
   
  },
  methods: {
    async clearEditValues() {
      this.editValue.accountId = "e75456d6-710d-4ad0-9eb7-2293623f889a";
      this.editValue.studyId = store.getters.getStudyIs;
      this.editValue.siteCode = "";
      this.editValue.siteName = "";
      this.editValue.siteContactEmail = "";
      this.editValue.siteContactName = "";
      //this.editValue.status = "";
      this.editValue.siteNotes = "";
      this.editValue.numberOfPatient = 0;
      this.editValue.active = true;

      this.errors[6] = false;
      this.value.accountId = "e75456d6-710d-4ad0-9eb7-2293623f889a";
      this.value.studyId = store.getters.getStudyIs;
      this.value.siteCode = "";
      this.value.siteName = "";
      this.value.siteContactEmail = "";
      this.value.siteContactName = "";
      //this.value.status = "";
      this.value.siteNotes = "";
      this.value.numberOfPatient = 0;
      this.value.active = true;
    },
    async clearValues() {
      this.errors[6] = false;
      this.value.accountId = "e75456d6-710d-4ad0-9eb7-2293623f889a";
      this.value.studyId = store.getters.getStudyIs;
      this.value.siteCode = "";
      this.value.siteName = "";
      this.value.siteContactEmail = "";
      this.value.siteContactName = "";
      this.value.status = "";
      this.value.siteNotes = "";
      this.value.numberOfPatient = 0;
      this.value.active = true;
    },
    ValidateAll() {
        //this.value.siteCode = this.value.siteCode.replace(/^\s*/, "");
        this.value.siteCode = this.value.siteCode.replace(/ +/g, ' ').trim();
        this.value.siteName = this.value.siteName.replace(/ +/g, ' ').trim();
        this.value.siteContactName = this.value.siteContactName.replace(/ +/g, ' ').trim();
        this.value.siteContactEmail = this.value.siteContactEmail.replace(/ +/g, ' ').trim();
        this.value.siteNotes = this.value.siteNotes.replace(/ +/g, ' ').trim();
        this.value.numberOfPatient = this.value.numberOfPatient.toString().replace(/\s/g, '')    
        this.value.numberOfPatient=+this.value.numberOfPatient   
      //Number of payients
      if(this.value.siteContactEmail){
          if (this.value.siteContactEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
          this.errors[1]=false
          } else {
            this.errors[1]=true
          }
      }
      if (this.value.siteCode == "") {
        this.errors[0] = true;
      } else {
        this.errors[0] = false;
      }
    },
    setEditValues() {
      // this.editValue.siteID = "";
      this.editValue.accountId = this.value.accountId;
      this.editValue.studyId = this.value.studyId;
      this.editValue.siteCode = this.value.siteCode;
      this.editValue.siteName = this.value.siteName;
      this.editValue.siteContactEmail = this.value.siteContactEmail;
      this.editValue.siteContactName = this.value.siteContactName;
      this.editValue.status = this.value.status;
      this.editValue.siteNotes = this.value.siteNotes;
      this.editValue.numberOfPatient = this.value.numberOfPatient;
      this.editValue.active = this.value.active;
    },
    setValues() {
      this.value.accountId = this.editValue.accountId;
      this.value.studyId = this.editValue.studyId;
      this.value.siteCode = this.editValue.siteCode;
      this.value.siteName = this.editValue.siteName;
      this.value.siteContactEmail = this.editValue.siteContactEmail;
      this.value.siteContactName = this.editValue.siteContactName;
      this.value.status = this.editValue.status;
      this.value.siteNotes = this.editValue.siteNotes;
      this.value.numberOfPatient = this.editValue.numberOfPatient;
      this.value.active = this.editValue.active;
      console.log("Values Set");
      console.log(this.value);
    },
    async createSite() {
      this.value.status = "New";
      const valnow = store.getters.getStudyIs;
      this.value.studyId = store.getters.getStudyIs;
      if(valnow == "" | valnow == null ){
        alert(this.$t('navigations.please select a study'));
      }
      else {
      await axios
       //.put("https://localhost:5001/site/update", this.editValue, this.headerValues)
        .post(this.baseUrl + "create", this.value, this.headerValues)
        .then((res) => {
          console.log(res);
          alert(this.$t('navigations.site saved successfully'));
          this.$router.push(`/sitelist`);
        })
        .catch((err) => {
           if (err.response.data.detail == "Site code already exists.")             
           {
            alert(this.$t('navigations.site code already exists'));
           }
        });
      }
    },
    async editSite() {
      console.log(this.editValue);

      await axios
        .put(this.baseUrl + "update", this.editValue, this.headerValues)
       // .put("https://localhost:5001/site/update", this.editValue, this.headerValues)
        .then((res) => {
         alert(this.$t('navigations.site updated successfully'));
         this.$router.push(`/sitelist`);
        })
       .catch((err) => {
           if (err.response.data.detail == "Site code already exists.")             
           {
            alert(this.$t('navigations.site code already exists'));
           }
        });
    },
    async getSite(id) {
      await axios
        .get(this.baseUrl + "getbyid?id=" + id, this.headerValues)
        .then((res) => {
          this.editValue = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async onclosed() {
      await this.$router.push(`/sitelist`);
    },
    async save() {
      this.ValidateAll();
      if (
        !this.errors[0] &&
        !this.errors[1] &&
        !this.errors[2] &&
        !this.errors[3] &&
        !this.errors[4]
      ) {
        if (this.userid === "0") {
          await this.createSite().then(() => {
          });
        } else {
          this.setEditValues();
          await this.editSite()
            .then((res) => {
            })
            .catch((err) => {
              alert(this.$t('navigations.something went wrong.'));
            });
        }
      }
    },
   async  clear() {
      if(this.userid === "0"){
        this.clearValues();
      }
      else{
        this.clearEditValues();
      }
    },
     async numberonlyvalidation(fieldname){
       //let isNumber = Number(this.values[fieldname])?true:false;
       let isNumber=true;
       this.value[fieldname] = this.value[fieldname].replace(/\s/g, '');
       let fieldval = this.value[fieldname];
       console.log("valtttttt is",fieldval);
       if(fieldval % 1 != 0)
       {
        isNumber = false;
       }
      console.log(this.value[fieldname]);
      console.log(isNumber);
      if(!isNumber){
        this.value[fieldname] = 0;
      
       }
       else{
         if(parseInt(this.value[fieldname]) >= 0){
         
         }
         else{
          this.value[fieldname] = 0;

         }
        
       }
    },
  },
};
</script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/savepages.css";
.wrapper-content {
  width: 100%;
}
textarea {
  width: 100%;
}
.imp {
  color: #ff0000;
  font-size: 7px;
}
.col-form-label {
  font-size: 14px;
}
.impcl {
  color: #ff4747;

  font-size: 25px;

  float: right;
}

.impcl:hover {
  color: #a30202;

  font-size: 25px;

  float: right;
}
.form-select{
  border-radius:0!important;
}
.form-select:focus{
  border-color:#145faf!important;
  box-shadow: 0 0 0!important;
}
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  visibility: hidden;
  width: 60px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 110%; /* Position the tooltip above the icon */
  left: 10%;
  margin-left: -30px; /* Use half of the width to center-align */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip-wrapper:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
}


</style>