<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.study") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row justify-content-lg-start my-2 my-lg-0">
          <div class="col-lg-5 my-1 my-lg-0 pl-lg-0 pr-lg-1">
            <div class="form-group mb-0">
              <label class="mb-0">{{ $t("navigations.study name/ id") }}</label>
              <input
                type="text"
                class="form-control my-lg-0"
                v-model="studyName"
              />
            </div>
          </div>
          <div class="col-lg-5 my-1 my-lg-0 pr-lg-1">
            <div class="form-group mb-0">
              <label class="mb-0">{{ $t("navigations.status") }}</label>
              <select
                class="form-select my-lg-0"
                name="account"
                placeholder="Search"
                v-model="searchStatus"
              >
                    <option value="All">All</option>
                    <option value="Archived">Archived</option>
                    <option value="Closed">Closed</option>
                    <option value="New">New</option>
                    <option value="Open">Open</option>
              </select>
             </div>
          </div>
            <span
            type="button"
              class="col-12 col-lg-2 save_btn w-100 align-self-end"
              @click.prevent="SearchButtonClicked()"
            >
              {{ $t("navigations.search") }}
            </span>            
        </div>
      </div>      
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5>{{ $t("navigations.study list") }}</h5>
        <div class="d-flex">
        <div class="position-relative align-self-center mr-3" v-if="!roleprivileges || roleprivileges.includes('Studylist Create Study')" aria-label="Create study" tabindex="0">
          <img class="cursor-pointer" src="../../assets/img/create.png"  alt="Create study" @click.prevent="createstudy(0)">
          <div class="tool-tip">{{ $t("navigations.create study") }}</div>
        </div>
        <div class="position-relative cursor-pointer" v-if="studyList.length!=0" @click.prevent="showFilter = !showFilter"><img
              src="../../assets/img/filter.png"  alt="filter table" />
            <div class="tool-tip">{{ $t("navigations.filter") }}</div>
          </div>
      
      </div>
      </div>    
      <div class="ibox-content">
        <div class="d-flex justify-content-end px-0 mb-2" >
              <div class="col-3 px-0" v-if="showFilter" v-on:clickout="dropdownOpen=false">
                <div class=" table-multi-select-dropdown" @click.stop="dropdownOpen = !dropdownOpen">
                  <div class="selected-options">
                    <span>{{ $t("navigations.select columns") }}</span>
                  </div>
                  <div class="dropdown-arrow"></div>
                </div>
                <div v-if="dropdownOpen" class="table-dropdown-content" @click.stop>
                    <label v-for="(column, index) in getColumns" :key="index">
                      <input type="checkbox" v-model="selectedcolumnobj[column]" :value="true" /> {{ column }}
                    </label>
                  </div>
                </div>             
            </div>
            <div class="text-center" v-if="studyList.length===0">
              No data available
            </div>
        <div v-else class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th v-if="selectedcolumnobj['Study']" class="sort_block" @click="SortSelected('studyRef',1)" id="1">
                 
                  <div class="d-flex flex-row justify-content-between">   
                      {{ $t("navigations.study") }}         
                    <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
                </th>
                <th v-if="selectedcolumnobj['Study Name']" class="sort_block" @click="SortSelected('studyName',2)" id="2">
                  <div class="d-flex flex-row justify-content-between"> 
                       {{ $t("navigations.study name") }}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Status']" class="sort_block" @click="SortSelected('status',3)" id="3">                  
                  <div class="d-flex flex-row justify-content-between">   
                      {{ $t("navigations.status") }}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Pre-Visit']" class="sort_block" @click="SortSelected('preVisit',4)" id="4">
                  <div class="d-flex flex-row justify-content-between"> {{ $t("navigations.pre-visit") }}    
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span></div>
                  </th>
                <th v-if="selectedcolumnobj['Post-Visit']" class="sort_block" @click="SortSelected('postVisit',5)" id="5">
                  <div class="d-flex flex-row justify-content-between">{{ $t("navigations.post-visit") }}  
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span></div>
                </th>
                <th v-if="selectedcolumnobj['No. of Sites']">{{ $t("navigations['no. of site']") }} </th>
                <th v-if="selectedcolumnobj['No. of Subjects']">{{ $t("navigations['no. of subjects']") }}</th>
                <th v-if="selectedcolumnobj['Duration']">{{ $t("navigations.duration") }}</th>
                <th class="text-center" v-if="!roleprivileges || roleprivileges.includes('Studylist Visit')">{{ $t("navigations.visit") }}</th>
                <th class="text-center">Active</th>
                <th class="text-center" v-if="!roleprivileges || roleprivileges.includes('Studylist Edit')">{{ $t("navigations.edit") }}</th>
                <th class="text-center" v-if="roleprivileges.includes('Audit Trail Studylist')">{{ $t("navigations.audit trail") }}</th>
                <th class="text-center" v-if="!roleprivileges || roleprivileges.includes('Studylist Delete')">{{ $t("navigations.delete") }}</th>
                <th class="text-center" v-if="roleprivileges.includes('Studylist Manage Folder & Document')"> {{ $t("navigations.document") }} </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="study in studyList" :key="study.studyId">
                <td v-if="selectedcolumnobj['Study']">{{ study.studyRef }}</td>
                <td v-if="selectedcolumnobj['Study Name']">{{ study.studyName }}</td>
                <td v-if="selectedcolumnobj['Status']">{{ study.status }}</td>
                <td v-if="selectedcolumnobj['Pre-Visit']">{{ study.preVisit }}</td>
                <td v-if="selectedcolumnobj['Post-Visit']">{{ study.postVisit }}</td>
                <td v-if="selectedcolumnobj['No. of Sites']">{{ study.numberOfSite }}</td>
                <td v-if="selectedcolumnobj['No. of Subjects']">{{ study.numberOfPatient }}</td>
                <td v-if="selectedcolumnobj['Duration']">{{ study.duration }}</td>
                <td class="text-center" v-if="!roleprivileges || roleprivileges.includes('Studylist Visit')">
                  <i class="fa fa-handshake-o edit-delete" @click.prevent="visit(study.studyId)"></i>
                </td>
                <td class="text-center">
                  <div class="i-checks">
                    <input type="checkbox" v-model="study.active" onclick="return false" />
                  </div>
                </td>
                <td class="text-center" v-if="!roleprivileges || roleprivileges.includes('Studylist Edit')">
                <i
                      class="fa fa-edit edit-delete"
                      @click.prevent="editstudy(study.studyId)"
                    ></i
                  >
                </td>
                <td class="text-center" v-if="roleprivileges.includes('Audit Trail Studylist')">
                  <i
                    class="fa fa-eye edit-delete"
                    @click="openModelaudit(study.studyId)"
                  ></i>
                </td>
                <td class="text-center" v-if="!roleprivileges || roleprivileges.includes('Studylist Delete')">
                 <i
                    class="fa fa-trash edit-delete"
                    @click.prevent="checkStudyHaveSites(study.studyId)"
                  ></i
                  >
                </td>
                 <td class="text-center" v-if="roleprivileges.includes('Studylist Manage Folder & Document')">
                  <i
                    @click="folderList(study.studyId,study.studyRef)"
                    title="Document"
                    class="fa fa-folder edit-delete"
                  >
                  </i>
                </td>
                
              </tr>
            </tbody>
          </table>
              <!------ pagination -------->
         
      </div>
      <div class="row mx-0 mt-2" v-if="studyList.length!=0">
            <div class="col-lg-12 mx-0 px-0">
              <div class="dataTables_paginate paging_simple_numbers pagination float-right">
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                    v-model="currentPageNumber"
                    :pages="totalIteems"
                    :range-size="1"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  /> 
                </div>
              </div>
            </div>             
          </div>       
        </div>
        <!-- Audit trail details modal starts -->
        <div v-if="auditpopup == true" class="audit__details__modal__wrapper">
        <div class="audit__details__modal__container">
          <div class="audit__details__modal__title d-flex justify-content-between py-2 px-3">
            <h4 class="my-0">{{ $t("navigations.audit details") }}</h4>
            <div class="cursor-pointer">
              <i class="fa fa-times" @click.prevent="closemodallll()"></i>
            </div>
          </div>
          <!-- Popup -->
        <div class="audit__details__modal__content p-3" :class="{heightBig:changeHeight}">
            <div class="table-responsive">
              <table class="table table-hover issue-tracker">
                <tbody>
                  <tr v-for="audit in auditLists" :key="audit.studyId" class="cursor-pointer">
                    <td @click="openModelauditOps(audit.id)">
                    <span class="d-block"><strong> {{ audit.loggedInUserName }}</strong></span> 
                    </td>
                    <td @click="openModelauditOps(audit.id)">
                      <div>  <small>{{ filter(audit.createdAt) }}</small> </div>
                      <div>  <small>{{ toTime(audit.createdAt) }}</small> </div>
                    </td>
                    <td v-if="audit.operation=='Update'" @click="openModelauditOps(audit.id)"><span class="badge badge-primary">{{ audit.operation }}</span></td>
                    <td v-if="audit.operation=='Delete'" @click="openModelauditOps(audit.id)"><span class="badge badge-danger">{{ audit.operation }}</span></td>
                    <td v-if="audit.operation=='Insert'" @click="openModelauditOps(audit.id)"><span class="badge badge-warning-light">{{ audit.operation }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--  popup -->
    </div>
    <div class="audit__history__modal__wrapper" v-if="audithistortpopup == true">
      <div class="audit__history__modal__container" >
        <div class="audit__history__modal__title d-flex justify-content-between py-2 px-3">
          <h4 class="my-0">{{ $t("navigations.audit trail") }}</h4>
          <div class="cursor-pointer ">
            <i class="fa fa-times" @click.prevent="closehistorymodal()"></i>
          </div>
        </div>
        <!-- test -->

        <!-- Popup -->
        <div class="audit__history__modal__contents p-3">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th><strong>{{ $t('navigations.field name')}}</strong></th>
                  <th><strong>{{$t("navigations.old value")}}</strong></th>
                  <th><strong>{{$t("navigations.new value")}}</strong></th>
                </tr>
                <tr>
                  <th><strong>{{ $t('navigations.study id')}}</strong></th>
                  <td v-if="auditHistoryLists.oldValue">{{auditHistoryLists.oldValue.StudyRef}}</td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">{{auditHistoryLists.newValue.StudyRef}}</td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th><strong>{{ $t('navigations.study name')}}</strong></th>
                  <td v-if="auditHistoryLists.oldValue">{{auditHistoryLists.oldValue.StudyName}}</td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">{{auditHistoryLists.newValue.StudyName}}</td>
                    <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th><strong>{{ $t('navigations.sponsor')}}</strong></th>
                  <td v-if="auditHistoryLists.oldValue">{{auditHistoryLists.oldValue.Sponsor}}</td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">{{auditHistoryLists.newValue.Sponsor}}</td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th><strong>{{ $t('navigations.status')}}</strong></th>
                  <td v-if="auditHistoryLists.oldValue">{{auditHistoryLists.oldValue.Status}}</td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">{{auditHistoryLists.newValue.Status}}</td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th><strong>{{ $t('navigations.study notes')}}</strong></th>
                  <td v-if="auditHistoryLists.oldValue">{{auditHistoryLists.oldValue.StudyNotes}}</td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">{{auditHistoryLists.newValue.StudyNotes}}</td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th><strong>{{ $t('navigations.pre visit')}}</strong></th>
                  <td v-if="auditHistoryLists.oldValue">{{auditHistoryLists.oldValue.PreVisit}}</td>
                    <td v-else>{{}}</td>
                <td v-if="auditHistoryLists.newValue">{{auditHistoryLists.newValue.PreVisit}}</td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th><strong>{{ $t('navigations.post visit')}}</strong></th>
                  <td  v-if="auditHistoryLists.oldValue">{{auditHistoryLists.oldValue.PostVisit}}</td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">{{auditHistoryLists.newValue.PostVisit}}</td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th><strong>{{ $t('navigations.number of sites')}}</strong></th>
                  <td v-if="auditHistoryLists.oldValue">{{auditHistoryLists.oldValue.NumberOfSite}}</td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">{{auditHistoryLists.newValue.NumberOfSite}}</td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th><strong>{{ $t('navigations.number of patients')}}</strong></th>
                  <td v-if="auditHistoryLists.oldValue">{{auditHistoryLists.oldValue.NumberOfPatient}}</td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">{{auditHistoryLists.newValue.NumberOfPatient}}</td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th><strong>{{ $t('navigations.duration')}}</strong></th>
                  <td v-if="auditHistoryLists.oldValue">{{auditHistoryLists.oldValue.Duration}}</td>
                      <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">{{auditHistoryLists.newValue.Duration}}</td>
                      <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th><strong>{{ $t('navigations.active')}}</strong></th>  
                  <td v-if="auditHistoryLists.oldValue">{{auditHistoryLists.oldValue.Active}}</td>
                      <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">{{auditHistoryLists.newValue.Active}}</td>
                      <td v-else>{{}}</td>
                </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script src="./studyListComponent.js"></script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
input :disabled{
  cursor:not-allowed;
  opacity:1;
}
.previousbtn,.nextbtn{
  margin:0;
  border-radius:0px;
  border:0.5px solid #eeeeee;
}
.page-links{
  border-radius:0px;
}
.form-select{
  border-radius:0!important;
}
.form-select:focus{
  border-color:#145faf!important;
  box-shadow: 0 0 0!important;
}
.position-relative img{
  width:20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip{
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.modal__container22 {
  width: 33%;
  max-width: 30%;
  position: fixed;
  height: 20% !important;
  margin-top: -550px !important;
  margin-left: 409px !important; 
  background: rgb(255, 255, 255);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
}
.modal__container23 {
  width: 66%;
    max-width: 62%;
    position: fixed;
    height: -14% !important;
    margin-top: -683px !important;
    margin-left: 140px !important;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    transition: all 0.3s ease-in-out 0s;
}
.scrollable3 {
  overflow-y: scroll;
  height: 250px;
}
.audit__details__modal__wrapper
{
  position: fixed;
  inset:0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index:3000
}
.audit__details__modal__container {
  width:min(90%,365px);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}
.audit__details__modal__title,
.audit__history__modal__title{
  background-color: var(--pop-up-background);
  color: #ffffff;
}
.heightBig
 {
  overflow-y: scroll;
  height: 250px;
}
.audit__history__modal__contents 
 {
  overflow-y: scroll;
  height: 404px;
}
.audit__history__modal__wrapper{
  position: fixed;
  inset:0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index:4000
}
.audit__history__modal__container
{
  width:min(45%,90%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}
</style>