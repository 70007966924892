
<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{
              $t("navigations.screens")
            }}</h2>
    </div>
  </div>
  <div>
    <div class="wrapper wrapper-content">
      <div class="ibox-body p-2">
        <div class="row">
          <div class="col-md-6">
            <div
              class="ibox-title style_2 d-flex justify-content-between align-items-center"
            >
              <h5 class="my-0">{{
              $t("navigations.screens")
            }}</h5>
              <div
                v-if="rollsprivilages.includes('Screen Add Screen')"
                class="position-relative align-self-center"
                aria-label="Add screen"
                tabindex="0"
              >
                <img
                  class="cursor-pointer"
                  src="../../assets/img/create.png"
                  alt="Add screen"
                  @click="openModel(false)"
                />
                <div class="tool-tip">{{
              $t("navigations.add screen")
            }}</div>
              </div>
            </div>
            <div class="ibox-content p-3">
              <div class="list-group scrollable">
                <a
                  class="list-group-item"
                  v-for="item in screen"
                  :key="item.id"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  @click="startEditMethod(item.id)"
                >
                  {{ item.name }}
                  <i
                    class="fa fa-angle-right float-right"
                    @click.prevent="startEditMethod(item.id)"
                  >
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal starts -->
  <div class="modal__add__wrapper" v-if="openModal">
    <div class="modal__add__container">
      <div class="title-class d-flex justify-content-between py-2 px-3">
        <h4 class="my-0" v-if="HeadingChange">{{ $t("navigations.add screen") }}</h4>
        <h4 class="my-0" v-else>{{ $t("navigations.edit screen") }}</h4>
        <div
          class="align-self-center cursor-pointer"
          @click.prevent="closemodall()"
        >
          <i class="fa fa-times" title="Close"></i>
        </div>
      </div>
      <div class="ibox-content">
        <!-- test -->

        <div class="ibox-content p-3">
          <div class="row">
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                v-model="screenName"
                :placeholder="$t('navigations.add screen')"
                maxlength="1000"
              />
              <span class="errormsg" v-if="errors[0]"
                >{{
              $t("navigations.enter a valid screen name")
            }}</span
              >
            </div>

            <div class="col-lg-1 my-1">
              <button
                v-if="rollsprivilages.includes('Screen Edit Screen') || rollsprivilages.includes('Screen Add Screen')"
                class="btn-circle my-1 position-relative"
                type="button"
                @click.prevent="postScreen"
              >
                <i class="fa fa-plus"></i>
                <div v-if="HeadingChange" class="tool-tip">
                  {{ $t("navigations.add screen") }}
                </div>
                <div v-else class="tool-tip">
                  {{ $t("navigations.edit screen") }}
                </div>
              </button>
            </div>
            <div class="col-lg-4" v-if="showDelete">
              <a
                v-if="rollsprivilages.includes('Screen Delete Screen')"
                class="editicon position-relative"
                @click="deleteScreen(item, modalscreenbyid.id)"
              >
                <i class="fa fa-trash edit-delete1 my-2"></i>
                <div class="tool-tip">
                  {{ $t("navigations.delete screen") }}
                </div></a
              >
            </div>
          </div>
        </div>
        <div class="ibox-title2 mt-2" v-if="retrieve">
          <h4>{{ $t("navigations.add controls") }}</h4>
        </div>
        <div class="ibox-content p-3" v-if="retrieve">
          <div class="row">
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                v-model="controledit"
                :placeholder="$t('navigations.control name')"
                maxlength="30"
              />
              <span class="errormsg" v-if="errors[1]"
                >{{ $t("navigations.enter a valid control name") }}</span
              >
            </div>

            <div
              v-if="rollsprivilages.includes('Screen Add Control')"
              class="col-lg-1 my-1"
            >
              <button
                class="btn-circle position-relative"
                type="button"
                @click.prevent="createControls()"
              >
                <i class="fa fa-plus"></i>
                <div class="tool-tip">
                  {{ $t("navigations.add control") }}
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="list-group scrollable mt-3" v-if="controls.length != 0">
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t("navigations.controls") }}</th>
                <th>{{ $t("navigations.edit") }}</th>
                <th> {{ $t("navigations.delete") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in modalscreenbyid.controls" :key="item">
                <td v-if="item != control">{{ item }}</td>
                <td v-if="item == control">
                  <input
                    type="text"
                    class="form-control"
                    v-model="controlName"
                    placeholder=""
                    maxlength="30"
                  />
                </td>
                <td v-if="item != control">
                  <a
                    v-if="rollsprivilages.includes('Screen Edit Control')"
                    class="editicon position-relative"
                    @click="updateControllers(item, newvalue, screenbyid.id)"
                  >
                    <i class="fa fa-edit edit-delete"> </i>
                    <div class="tool-tip">
                      {{ $t("navigations.edit control") }}
                    </div></a
                  >
                </td>
                <td v-if="item == control">
                  <i class="fa fa-save edit-delete my-1" @click="savebutton(0)">
                  </i>
                  <i class="fa fa-times close my-2" @click="closebutton()"> </i>
                </td>
                <td>
                  <a
                    v-if="rollsprivilages.includes('Screen Delete Control')"
                    class="editicon position-relative"
                    @click="deletePrivilege(item, modalscreenbyid.id)"
                  >
                    <i class="fa fa-trash edit-delete"></i>
                    <div class="tool-tip">
                      {{ $t("navigations.delete control") }}
                    </div></a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- test -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import axios from "axios";
import store from "../../store/index";
export default {
  name: "Screen",
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      localurl: process.env.VUE_APP_redirect,
      updateValue: {
        screenId: "",
        name: "",
        controls: [],
      },
      openModal: false,
      isEdit: true,
      errors: [false, false],
      screenidbyname: "",
      selectedid: "",
      controledit: "",
      modalscreenbyid: "",
      screenName: "",
      screen: "",
      screenstatus: false,
      screenid: "",
      screenbyid: "",
      controlName: "",
      controls: [],
      screenList: [],
      screens: [],
      deletebody: {
        screenId: "",
        control: "",
        myModel: false,
      },
      edit: true,
      control: "",
      retrieve: true,
      showDelete: true,
      HeadingChange: true,
      rollsprivilages: "",
    };
  },
  async mounted() {
    this.getallscreen();
    this.rollsprivilages = await store.getters.getRolesprivilegeData;
  },
  methods: {
    async startEditMethod(id) {
      this.openModel(true);
      this.retrieve = true;
      this.showDelete = true;
      this.HeadingChange = false;
      await this.getcontrollers(id, "false");
      this.screenName = this.screenbyid.name;
      console.log("Screenmame Set " + this.screenName);
      this.screenList = this.screenbyid.controls;
      this.controls = this.screenbyid.controls;
      this.modalscreenbyid = this.screenbyid;
      this.screenidbyname = id;
      this.updateValue.screenId = id;
      this.updateValue.name = this.screenName;
      this.updateValue.controls = this.screenbyid.controls;
    },

    async getallscreen() {
      await axios
        .get(`${this.baseurl}/account-core/roles/getallscreens`)
        .then((res) => {
          this.screen = res.data;
          console.log(this.screen);
        })
        .catch((err) => {
          console.log("Have error After listed");
          console.log(err);
        });
    },

    async getcontrollers(id, modal) {
      this.selectedid = id;
      await axios
        .get(`${this.baseurl}/account-core/roles/getscreenbyid?guid=${id}`)
        .then((res) => {
          if (modal == "false") {
            this.screenbyid = res.data;
            console.log("Data Set To RightSide");
          } else {
            this.modalscreenbyid = res.data;
            console.log("Data Set To PopUP");
          }
        })
        .catch((err) => {
          console.log("GetControllers Have Error ");
          console.log(err);
        });
    },

    async postScreen() {
      this.screenName = this.screenName.replace(/ +/g, " ").trim();
      this.validateScreenName(this.screenName);
      if (!this.errors[0]) {
        if (!this.isEdit) {
          await axios
            .post(`${this.baseurl}/account-core/roles/screen`, {
              name: this.screenName,
              controls: [],
            })
            .then((response) => {
              this.retrieve = true;
              this.showDelete = false;
              this.HeadingChange = true;
              this.getScreens();
              this.getallscreen();
              this.controlId = response.data;
            })
            .catch((err) => {
              console.log(err);
              alert("Screen name already exist");
              this.screenName = "";
            });
          await this.getidbyname();
          this.openModal = !this.openModal;
        } else {
          this.updateValue.name = this.screenName;
          console.log(this.updateValue);
          await axios
            .put(
              `${this.baseurl}/account-core/roles/updatescreen`,
              this.updateValue
            )
            .then((response) => {
              console.log(response);
              // alert("Screen name updated successfully");
                alert(this.$t('navigations.screen name updated successfully'))
            })
            .catch((err) => {
              console.log(err);
              alert("Screen name already exist");
              this.screenName = this.screenbyid.name;
            });
        }
      }
    },

    validateScreenName(screenName) {
      if (screenName == "") {
        this.errors[0] = true;
      } else {
        this.errors[0] = false;
      }
    },
    validateControlName(controledit) {
      if (controledit == "") {
        this.errors[1] = true;
      } else {
        this.errors[1] = false;
      }
    },

    async closemodall() {
      this.openModal = !this.openModal;
      this.modalscreenbyid = "";
      this.screenName = "";
      this.control = "";
      this.screenList = [];
      this.controls = [];
      this.errors[0] = false;
      this.errors[1] = false;
      this.getallscreen();
    },

    async getScreens() {
      await axios
        .get(`${this.baseurl}/account-core/roles/getallscreens`)
        .then((response) => {
          this.screens = response.data;

          this.screens.filter((item) => {
            if (item.name == this.screenName) {
              this.screenList.push(item);
              this.guid = item.id;
              console.log("The screen list", this.screenList);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async createControls() {
      this.controledit = this.controledit.replace(/ +/g, " ").trim();
      this.validateControlName(this.controledit);
      this.controlName = this.controledit;
      if (!this.errors[1]) {
        await axios
          .post(`${this.baseurl}/account-core/roles/createcontrol`, {
            screenId: this.screenidbyname,
            control: this.controlName,
          })
          .then(async (response) => {
            console.log(response);
            this.controls.push(this.controlName);
            await this.getcontrollers(this.screenidbyname, "true");
          })
          .catch((err) => {
            console.log(err);
            alert("Control name already exist");
          });
        this.controlName = "";
        this.controledit = "";
      }
    },
    async getidbyname() {
      await axios
        .get(
          `${this.baseurl}/account-core/roles/searchscreen?screenName=${this.screenName}`
        )
        .then((response) => {
          console.log("id by name is ");
          this.screenidbyname = response.data[0].id;
          console.log(
            "Current Screen Is " +
              this.screenName +
              "id is " +
              this.screenidbyname
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async deletePrivilege(control, id) {
      console.log("Entered function delete with screen list", id);
      this.deletebody.screenId = id;
      this.deletebody.control = control;
      if (confirm(this.$t('navigations.are you sure you want to delete this control'))) {
        await axios
          .delete(`${this.baseurl}/account-core/roles/deletecontrol`, {
            data: {
              screenId: id,
              control: control,
            },
          })
          .then((response) => {
            console.log(response);
            this.getcontrollers(id, "true");
            this.screenstatus;
            this.refreshSelectedById(id);
          })
          .catch((err) => {
            console.log(err);
          });
        this.control = "";
      }
    },
    async updateControllers(item, id) {
      this.edit = false;
      this.control = item;
      this.screenid = id;
      console.log(item, ",", id);
    },
    //----------
    async refreshSelectedById(id) {
      this.selectedid = id;
      await axios
        .get(`${this.baseurl}/account-core/roles/getscreenbyid?guid=${id}`)
        .then((res) => {
          this.screenbyid = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async savebutton(id) {
      id = id == 0 ? this.screenidbyname : id;
      console.log(
        "id is" +
          id +
          " old controll" +
          this.control +
          " new controll" +
          this.controlName
      );
      await axios
        .put(`${this.baseurl}/account-core/roles/updatecontrol`, {
          screenId: id,
          oldControl: this.control,
          newControl: this.controlName,
        })
        .then(async (response) => {
          console.log(response);
          await this.getcontrollers(id, "true");
        })
        .catch((err) => {
          console.log(err);
          console.log(this.controlId, this.control, this.controlName);
          alert("Control name already exist");
        });
      this.control = "";
      this.controlName = "";
    },

    async closebutton() {
      console.log("CLOSE MODEL");
      this.control = "";
      this.controlName = "";
    },
    async deleteScreen(control, id) {
      console.log("Entered function delete with screen list", id);
      this.deletebody.screenId = id;
      if (confirm(this.$t('navigations.are you sure you want to delete this screen'))) {
        await axios
          .delete(`${this.baseurl}/account-core/roles/deletescreen`, {
            data: {
              screen_Id: id,
            },
          })
          .then((response) => {
            console.log(response);
            this.getcontrollers(id, "true");
            this.screenstatus;
            this.refreshSelectedById(id);
          })
          .catch((err) => {
            console.log(err);
          });

        // alert("Screen deleted successfully");
          alert(this.$t('navigations.screen deleted successfully'))
        this.closemodall();
      }
    },
    async openModel(isedit) {
      this.openModal = !this.openModal;
      this.retrieve = false;
      this.showDelete = false;
      this.HeadingChange = true;
      this.isEdit = isedit;
    },
  },
};
</script>

<style scoped>
/* Create a two-column layout */
@import "../../assets/style.css";
@import "../../assets/formpage.css";
@import "./modal.css";
.active {
  opacity: 1 !important;
  pointer-events: auto !important;
}
.errormsg {
  color: red;
}
.scrollable {
  overflow-y: scroll;
  height: 250px;
}
.ibox-tools-screen {
  display: block;
  float: none;
  margin-top: 0;
  position: absolute;
  top: 8px;
  right: 17px;
  padding: 0;
  text-align: right;
}
.btn-primary {
  color: #fff;
  background-color: #145faf;
  border-color: #1ab394;
  border: none;
}
.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all 0.5s;
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item {
  background-color: inherit;
  border: 1px solid #e7eaec;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative;
}
.modal__wrapper {
  position: fixed;
  left: 0;
  top: 0;
  background: rgb(0 0 0 / 18%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: cubic-bezier;
  overflow: scroll;
}
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.btn-circle {
  color: #ffffff;
  padding: 3px;
  width: 22px;
  height: 22px;
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  background-color: #618bd7;
  border-color: #618bd7 !important;
  line-height: 1.428571429;
}
.v-pagination.li {
  color: red;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid #b20774;
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}

.PaginationControl {
  padding: 0.42rem;
}
.ibox-form {
  background-color: #ffffff;
  border-color: #e7eaec;
  border-style: solid solid none;
  border-width: 1px;
  margin-bottom: 1px;
}
.save_btn :hover {
  background-color: #2d91fb;
  border-color: #2d91fb;
}
.edit-delete1 {
  --primary-color: #145faf;
  --hvr-color: #2d91fb;
  --secondary-color: #618bd7;
  cursor: pointer !important;
  font-size: 20px;
}
.position-relative img {
  width: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
</style>