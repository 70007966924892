<template>
  <div ref="component" class="stat-card-white">
    <div class="stat-header-white">
      <span>{{ chartLabel }}</span>

      <div class="d-flex col-md-4">
        <label class="mb-0 mr-1" for="x-axis-select">Site code:</label>
        <select class="graph-select mr-2" v-model="selectedSiteCode" id="x-axis-select" @change="createChart">
          <option value="">All</option>
          <option  v-for="site in uniqueSiteCodes" :key="site" :value="site">{{ site }}</option>
        </select>
        </div>
        <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
            <img @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
            <div class="more-expand">
              <span>{{$t("navigations.data overview")}}</span>
            </div>
          </div>
      <div v-if="!loader" class="d-flex align-items-center">
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock = true">
          <img src="../../assets/img/expand.png" alt="Expand block" />
        </div>
        <div v-on:clickout="showVisitPopup = false">
          <div class="cursor-pointer biggerfont ml-2" @click.prevent="showVisitPopup = !showVisitPopup">
            <i class="fa fa-ellipsis-v"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="stat-body-white">
      <!-- Dropdown for siteCode -->

      <div v-if="loader" class="loader"></div>
      <div ref="chartContainerWrapper" class="w-100">
        <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
        <svg ref="chartContainer" v-show="!loader"></svg>
        <div v-if="colorcodes.length!=0" class="d-flex">
          <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
          <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
          {{ colors.type }}
          </div>
        </div>
        <div ref="tooltip" class="tooltip"></div>
      </div>
    </div>
    <enlargerpopup v-if="expandBlock" @closeModal="expandBlock = false; createChart()">
      <div class="stat-body-white d-flex flex-column">
        <svg ref="popupChartContainer"></svg>
      </div>
    </enlargerpopup>
  </div>
</template>
  
  <script>
  import * as d3 from 'd3';
import axios from "axios";
import store from "../../store/index";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue"
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
export default {
  name: "comparativeLineChart",
  components: {
    enlargerpopup,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    xAxisValue: {
      type: String,
      default: "createdAt",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    yAxisValue: {
      type: String,
      default: "",
    },
    groupCategoryValue: {
      type: String,
      default: "",
    },
    chartLabel: {
      type: String,
      default: "Comparative Analysis",
    },
    custcolors: {
      type: Array,
      default: () => ["#62B2FD", "#9BDFC4", "#ABF0FF", "#C1D1FF", "#FBD164", "#FDAD73"],
    },
    colorcodes:{
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      svg: null,
      selectedXAxis: this.xAxisValue || "",
      selectedYAxis: this.yAxisValue || "",
      selectedGroupCategory: this.groupCategoryValue || "",
      selectedSiteCode: "", // Dropdown filter for siteCode
      uniqueSiteCodes: [], // Store unique site codes for the dropdown
      expandBlock: false,
      tooltip: null,
      chartContainer: null,
      sortdata: [],
      filterkeyword:"",
      filterarray:[],
      selectedGroups: [],
      selectedKeywords: [],
      mouseX: 0,
      mouseY: 0,
    };
  },
  watch: {
    data: {
      handler() {
        this.cancelselection();
        this.extractUniqueSiteCodes(); // Update dropdown options when data changes
        this.createChart();
      },
      deep: true,
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
  },
  mounted() {
    window.addEventListener('mousemove', this.updateMousePosition)
    this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
    this.tooltip = this.$refs.tooltip;
    this.extractUniqueSiteCodes(); // Extract site codes on mount
    this.createChart();
  },
  methods: {
    showtable() {
      this.showMore = false
      this.$emit("opendrillpopup", this.data);
    },
    async cancelselection() {
  this.selectedGroups = [];
  this.sortdata = [];
  this.createChart();
},
backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
    updateMousePosition(event) {
      if (this.$refs.component) {
      const rect = this.$refs.component.getBoundingClientRect();
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    }
    },
    extractUniqueSiteCodes() {
      // Extract unique site codes from the data
      this.uniqueSiteCodes = Array.from(new Set(this.data.map(d => d.siteCode))).sort();
    },
    createChart() {
  this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;

  const margin = { top: 20, right: 60, bottom: 40, left: 60 };
  const width = 1200;
  const height = 400 - margin.top - margin.bottom;

  // Filter data based on selected siteCode
  const filteredData = this.selectedSiteCode
    ? this.data.filter((d) => d.siteCode === this.selectedSiteCode)
    : this.data;

  // Aggregate data
  const aggregatedData = this.aggregateData(filteredData);
  if (aggregatedData.length === 0) return;

  const subCategories = Array.from(new Set(
    aggregatedData.flatMap((d) => d.values.map((v) => v.category))
  ));

  // Create scales
  const x0Scale = d3.scaleBand()
    .domain(aggregatedData.map((d) => d.key)) // Outer scale (e.g., siteCode)
    .range([0, width])
    .padding(0.1);

  const x1Scale = d3.scaleBand()
    .domain(subCategories) // Inner scale (e.g., queryStatus or responseStatus)
    .range([0, x0Scale.bandwidth()])
    .padding(0.05);

  const yScale = d3.scaleLinear()
    .domain([0, d3.max(aggregatedData, (d) =>
      d3.max(d.values, (v) => v.value)
    )])
    .nice()
    .range([height, 0]);

  const colorScale = d3.scaleOrdinal()
    .domain(subCategories)
    .range(this.custcolors);

  // Clear previous chart
  d3.select(this.chartContainer).selectAll("*").remove();

  // Create SVG container
  this.svg = d3.select(this.chartContainer)
    .attr("width", width)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  // Draw bars
  aggregatedData.forEach((group) => {
    const groupContainer = this.svg
      .append("g")
      .attr("transform", `translate(${x0Scale(group.key)}, 0)`);

    groupContainer.selectAll("rect")
      .data(group.values)
      .enter()
      .append("rect")
      .attr("x", (d) => x1Scale(d.category))
      .attr("y", (d) => yScale(d.value))
      .attr("width", x1Scale.bandwidth())
      .attr("height", (d) => height - yScale(d.value))
      .attr("fill", (d) => colorScale(d.category))
      .on("mouseover", (event, d) => {
        d3.select(this.tooltip)
          .style("opacity", 0.7)
          .html(`Category: ${d.category}<br/>Value: ${d.value}`)
          .style("left", `${this.mouseX + 10}px`)
          .style("top", `${this.mouseY - 20}px`);
      })
      .on("mouseout", () => d3.select(this.tooltip).style("opacity", 0));
  });

  // Add axes
  this.svg.append("g")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(x0Scale));

  this.svg.append("g")
    .call(d3.axisLeft(yScale));

  // Add labels
  this.svg.append("text")
    .attr("x", width / 2)
    .attr("y", height + margin.bottom - 10)
    .attr("text-anchor", "middle")
    .text("Groups (Site Code)");

  this.svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("x", -height / 2)
    .attr("y", -margin.left + 15)
    .attr("text-anchor", "middle")
    .text("Values");
},
    async clicklinechart(filteredData, filteredkeyword,category) {
    if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedKeywords = this.selectedKeywords.filter((k) => k !== filteredkeyword+category);
        this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
        let result = this.sortdata.filter(item => !filteredData.includes(item));
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
        this.filterarray = filterarraydeleted;
        this.filterkeyword = `${this.selectedXAxis}(${this.selectedGroups})`;
      } else {
    this.selectedGroups.push(filteredkeyword);
    this.selectedKeywords.push(filteredkeyword+category);
        this.filterarray.push(`(${filteredkeyword})`)
        this.filterkeyword = `${this.selectedXAxis}(${this.selectedGroups})`;
        this.sortdata = this.sortdata.concat(filteredData);
      }
      this.createChart();
  },
  recreatechart() {
      // this.$emit("opendrillpopup", this.sortdata);
      const dataToEmit = {
    sortedData: this.sortdata,
    filterkeyword: this.filterkeyword
  };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
drilldownfuntion(value){
      const dataToEmit = {
        sortedData: value.fullData,
        filterkeyword: `${this.selectedXAxis}(${value.key})`
      };
      this.$emit("drilldowncurrentchart", dataToEmit);
      console.log('Data array for clicked bar:', value);
    },
    aggregateData(data) {
  const formattedData = data.map(d => ({
    ...d,
    [this.selectedXAxis]: d[this.selectedXAxis].split("T")[0].slice(0, 7), // Extract 'YYYY-MM'
  }));

  const nestedData = d3.group(
    formattedData,
    d => d[this.selectedXAxis], // Group by 'YYYY-MM'
    d => d[this.selectedGroupCategory]
  );

  return Array.from(nestedData, ([key, values]) => ({
    key, // 'YYYY-MM'
    values: Array.from(values, ([category, records]) => ({
      category,
      value: d3.sum(records, r => r[this.selectedYAxis] || 1),
      originalData: records, // Attach the original records to the category
    })),
  }));
},
  },
};

  </script>
  
  <style scoped>
  .legandbox{
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
  .filter-wrapper {
  margin-bottom: 10px;
}

.filter-wrapper select {
  padding: 5px;
  font-size: 14px;
}
  .line-chart-wrapper {
    overflow-x: auto;
    width: 100%;
  }
  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .tooltip {
    position: absolute;
    text-align: center;
    width: auto;
    height: auto;
    padding: 5px;
    background: rgb(0, 0, 0);
    border: 1px solid rgb(117, 116, 116);
    border-radius: 3px;
    pointer-events: none;
    opacity: 0;
    z-index: 10;
  }

  </style>
  