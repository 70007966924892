<template>
  <div ref="component" class="stat-card-white">
    <div class="stat-header-white">
      <span> {{chartLabel}}</span>
      <div v-if="!loader" class="d-flex align-items-center">
        <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
          <img  class="cursor-pointer" @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
          <div class="more-expand">
            <span>{{ $t("navigations.data overview") }}</span>
          </div>
        </div>
        <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock=true">
        <img src="../../assets//img/expand.png" alt="Expand block">
      </div>
      <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false,exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stat-body-white" :class="{'bar-chart-wrapper d-flex flex-column':fromPage=='descriptiveStatistics'}">
      <div v-if="enrolledornot  && fromPage=='patientScreening' && !loader">
        <label class="mr-2">
          <input type="checkbox" v-model="aiPredictionTrue" @change="updateChart"> Enrolled
        </label>
        <label>
          <input type="checkbox" v-model="aiPredictionFalse" @change="updateChart"> Non-enrolled
        </label>
      </div>
      <div v-if="loader" class="loader"></div>
      <div ref="chartContainerWrapper"  class="w-100" :class="{'d-flex flex-column':!loader && selectedGroups.length!=0}">
      
      <div v-if="!loader && requiredOptions" class="d-flex align-items-center float-left justify-content-between">
        <div class="d-flex">
        <label v-if="fromPage!='studySummary'" class="mb-0 mr-1" for="x-axis-select">{{ $t("navigations.attribute") }}:</label>
        <select v-if="fromPage!='studySummary'" class="graph-select mr-2" v-model="selectedXAxis" id="x-axis-select" @change="updateChart">
          <option v-for="option in xAxisOptions" :key="option" :value="option">{{ option }}</option>
        </select>

        <label v-if="fromPage!='studySummary'" class="mb-0 ml-2 mr-1" for="group-category-select">{{ $t("navigations.group category") }}:</label>
        <select v-if="fromPage!='studySummary'" class="graph-select" v-model="selectedGroupCategory" id="group-category-select" @change="updateChart">
          <option v-for="option in groupCategoryOptions" :key="option" :value="option">{{ option }}</option>
        </select>
        </div>
<div v-if="!loader && selectedGroups.length!=0" class="d-flex flex-row-reverse gap-2 ">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
        </div>
        
        <svg ref="chartContainer" v-show="!loader"></svg>
        <div v-if="colorcodes.length!=0" class="d-flex">
          <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
          <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
          {{ colors.type }}
          </div>
        </div>
      </div>
      <div ref="tooltip" class="tooltip"></div>
    </div>
  </div>
  <enlargerpopup v-if="expandBlock==true" @closeModal="expandBlock=false,createChart()">
    <div
      class="stat-body-white d-flex flex-column">
    <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
    <svg ref="popupChartContainer"></svg>
    </div>
  </enlargerpopup>
</template>
<script>
import * as d3 from 'd3';
import axios from "axios";
import store from "../../store/index";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue"
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
export default {
  name: 'comparitiveBarChart',
  components:{
    enlargerpopup
  } ,
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    xAxisValue: {
      type: String,
      default: '',
    },
    loader: {
      type: Boolean,
      default: false,
    },
    yAxisValue: {
      type: String,
      default: '',
    },
    stackvalue: {
      type: String,
      default: '',
    },
    groupCategoryValue: {
      type: String,
      default: '',
    },
    templateId: {
      type: String,
      default: ''
    },
    fromPage:{
      type:String,
      default:""
    },
    enrolledornot: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 1200,
    },
    height: {
      type: Number,
      default: 800,
    },
    chartLabel:{
      type:String,
      default:"Comparative Analysis"
    },
    xAxisLabel:{
      type:String,
      default:""
    },
    yAxisLabel:{
      type:String,
      default:""
    },
    requiredOptions:{
      type: Boolean,
      default: true
    },
    custcolors:{
      type: Array,
      default: () => [
    '#62B2FD', '#9BDFC4', '#ABF0FF', '#C1D1FF', '#FBD164', '#FDAD73'
  ],
    },
    colorcodes:{
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      showVisitPopup: false,
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      mappings: {},
      svg: null,
      selectedXAxis: this.xAxisValue || '',
      selectedYAxis: this.yAxisValue || '',
      selectedGroupCategory: this.groupCategoryValue || '',
      selectedStatistic: 'Count',
      xAxisOptions: [],
      yAxisOptions: [],
      groupCategoryOptions: [],
      aggregatedData:[],
      aiPredictionTrue: true,
      aiPredictionFalse: true,
      fixedGroupKeys: [],
      fixedSubCategories: [],
      expandBlock:false,
      barselectedarray:[],
      sortdata: [],
      filterkeyword:"",
      filterarray:[],
      selectedGroups: [],
      chartContainer:null,
      mouseX:0,
      mouseY:0
    };
  },
  watch: {
    data: {
      handler() {
        this.loadQuantitativeAttributes();
        this.cancelselection();
        this.createChart();
      },
      deep: true,
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
  },
  async beforeMount() {
    this.idtoken = store.getters.getIdToken;
    if(this.fromPage == 'patientScreening'){
      await this.createmapping();
    }
    this.loadQuantitativeAttributes();
  },
  mounted() {
   
    this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
    window.addEventListener('mousemove', this.updateMousePosition)
    this.tooltip=this.$refs.tooltip
    this.createChart();
    // Add resize observer to make SVG responsive
    this.resizeObserver = new ResizeObserver(() => this.createChart());
    this.resizeObserver.observe(this.chartContainer);

  },
  methods: {
    async exporttoexcell() {
      if(this.fromPage=='studySummary'){
        const filteredData=this.data.map(item => {
              const newItem = { ...item }; // Create a shallow copy of the object
              delete newItem.FORMID; // Remove the unwanted key
              return newItem; // Return the new object without the unwanted key
          })
        const uniqueData = filteredData.reduce((acc, current) => {
            const uniqueKey = `${current.QUERYID}_${current.STUDYREF}`;
              if (current.QUERYID !== null && !acc.some(item => `${item.QUERYID}_${item.STUDYREF}` === uniqueKey)) {
                  acc.push(current);
              }
                    return acc;
        }, [])
        const worksheet = XLSX.utils.json_to_sheet(uniqueData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");  
        XLSX.writeFile(workbook, "Query Count.xlsx");
        
      }
      else{
        this.$emit("exporttoexcell");
      }

      
    },
    exporttopdf() {
      // Create a temporary container
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.component.cloneNode(true);
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '100%';

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: `${this.fromPage=='studySummary' ? `${this.$t('navigations.query count')}.pdf` : `Comparative Analysis.pdf`}`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    updateMousePosition(event) {
      if (this.$refs.component) {
      const rect = this.$refs.component.getBoundingClientRect();
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    }
    },
    showtable() {
      if(this.fromPage=='studySummary')
      {
        const uniqueData = this.data.reduce((acc, current) => {
            const uniqueKey = `${current.QUERYID}_${current.STUDYREF}`;
              if (current.QUERYID !== null && !acc.some(item => `${item.QUERYID}_${item.STUDYREF}` === uniqueKey)) {
                  acc.push(current);
              }
                    return acc;
        }, [])
        this.$emit("opendrillpopup", uniqueData.map(item => {
        
              const newItem = { ...item }; // Create a shallow copy of the object
              delete newItem.STUDYID; // Remove the unwanted key
              return newItem; // Return the new object without the unwanted key
          }),'query')
      }
      else{
        this.$emit("opendrillpopup", this.data)
      }  
  },
    async createmapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach(page => {
            page.field.forEach(field => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce((map, option) => {
                  map[option.codevalue] = option.optionname;
                  return map;
                }, {});
              }
            });
          });
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    loadQuantitativeAttributes() {
     if(this.fromPage == 'patientScreening' || this.fromPage== 'patientRetention'){
      const sampleData = (this.data && this.data.length > 0 && this.data[0].features) ? this.data[0].features : {};

        const quantitativeAttributes = [];
        const qualitativeAttributes = [];
        const categories = [];

        Object.keys(sampleData).forEach(key => {
          const uniqueValues = new Set(this.data.map(item => item.features[key])).size;
          if (uniqueValues < 6) {
            qualitativeAttributes.push(key);
            categories.push(key);
          } else {
            quantitativeAttributes.push(key);
          }
        });
        this.xAxisOptions = qualitativeAttributes;
        this.selectedXAxis = this.xAxisValue || qualitativeAttributes[1] || '';
        this.yAxisOptions = quantitativeAttributes;
        this.selectedYAxis = this.yAxisValue || '';
        this.groupCategoryOptions = categories;
        this.selectedGroupCategory = this.groupCategoryValue || categories[0] || '';
        this.fixedGroupKeys = Array.from(new Set(this.data.map(d => this.getMappedValue(d.features[this.selectedXAxis], this.selectedXAxis))));
        this.fixedSubCategories = Array.from(new Set(this.data.map(d => this.getMappedValue(d.features[this.selectedGroupCategory], this.selectedGroupCategory))));
     }
     if(this.fromPage=='descriptiveStatistics' || this.fromPage=='demographicsScreen' ||this.fromPage=='labSummary' || this.fromPage=='medicalHistory' || this.fromPage=='labResult')
     {
      const sampleData = (this.data && this.data.length > 0) ? this.data[0] : {};

          const quantitativeAttributes = [];
          const qualitativeAttributes = [];
          const categories = [];

          Object.keys(sampleData).forEach(key => {
            if (key === '_id' || key === 'Participant ID') {
                return;
              }
              
              const uniqueValues = new Set(this.data.map(item => item[key])).size;
              
              if (uniqueValues < 6) {
                qualitativeAttributes.push(key);
                categories.push(key);
              } else {
                quantitativeAttributes.push(key);
  }
            });
          this.xAxisOptions = qualitativeAttributes;
          this.selectedXAxis = this.xAxisValue || qualitativeAttributes[1] || '';
          this.yAxisOptions = quantitativeAttributes;
          this.selectedYAxis = this.yAxisValue || '';
          this.groupCategoryOptions = categories;
          this.selectedGroupCategory = this.groupCategoryValue || categories[0] || '';
     }
    },
    updateChart() {
      this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
      // Clear existing chart
      d3.select(this.chartContainer).selectAll('*').remove();
      // Re-create the chart with new settings
      const groupandxaxis={
      "xaxiskey":this.fromPage!='studySummary' ? this.selectedXAxis : this.xAxisValue,
      "groupkey":this.fromPage!='studySummary' ? this.selectedGroupCategory : this.groupCategoryValue,
      }
      this.$emit("setgroupkeys", groupandxaxis);
      this.cancelselection();
      this.createChart();
    },
    createChart() {
  const margin = { top: 20, right: 60, bottom: 40, left: 60 };
  const width = this.width - margin.left - margin.right;
  const height = this.height - margin.top - margin.bottom;

  // Clear any existing chart
  d3.select(this.chartContainer).selectAll('*').remove();

  // Aggregate data
  const aggregatedData = d3.rollup(
    this.data,
    group => {
      const statusGroups = d3.group(group, d => d[this.groupCategoryValue]);
      const responseGroups = d3.group(group, d => d[this.stackvalue]);
      return Array.from(statusGroups, ([status, statusRecords]) => ({
        groupKey: status,
        stacks: Array.from(responseGroups, ([response, responseRecords]) => ({
          category: response,
          value: responseRecords.filter(r => r[this.groupCategoryValue] === status).length,
        })),
      }));
    },
    d => d[this.xAxisValue]
  );

  const preparedData = Array.from(aggregatedData, ([xKey, groups]) => ({
    xKey,
    groups,
  }));

  // Unique values for x-axis, queryStatus (group), and responseStatus (stack)
  const xKeys = Array.from(new Set(this.data.map(d => d[this.xAxisValue])));
  const groupKeys = Array.from(new Set(this.data.map(d => d[this.groupCategoryValue])));
  const stackKeys = Array.from(new Set(this.data.map(d => d[this.stackvalue])));

  // Scales
  const xScale0 = d3.scaleBand().domain(xKeys).range([0, width]).padding(0.1);
  const xScale1 = d3.scaleBand().domain(groupKeys).range([0, xScale0.bandwidth()]).padding(0.05);
  const yScale = d3.scaleLinear()
    .domain([
      0,
      d3.max(preparedData, d =>
        d.groups.reduce((sum, g) => sum + g.stacks.reduce((s, stack) => s + stack.value, 0), 0)
      ),
    ])
    .nice()
    .range([height, 0]);

  const colorScale = d3.scaleOrdinal().domain(stackKeys).range(this.custcolors);

  // SVG
  const svg = d3
    .select(this.chartContainer)
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // Render bars
  preparedData.forEach(d => {
    const group = svg.append('g').attr('transform', `translate(${xScale0(d.xKey)},0)`);
    d.groups.forEach(g => {
      const stackGenerator = d3
        .stack()
        .keys(stackKeys)
        .value((data, key) => {
  const stackData = Array.isArray(data)
    ? data.find(stack => stack.category === key)
    : undefined; // Ensure `data` is an array before using `.find()`
  return stackData ? stackData.value : 0;
});
      const series = stackGenerator(g.stacks);

      const barGroup = group.append('g').attr('transform', `translate(${xScale1(g.groupKey)},0)`);

      barGroup
        .selectAll('rect')
        .data(series)
        .enter()
        .append('rect')
        .attr('x', 0)
        .attr('y', d => yScale(d[1]))
        .attr('height', d => yScale(d[0]) - yScale(d[1]))
        .attr('width', xScale1.bandwidth())
        .attr('fill', d => colorScale(d.key))
        .on('mouseover', (event, d) => {
          d3.select(this.tooltip)
            .style('opacity', 1)
            .html(`Response Status: ${d.key}<br>Value: ${d[1] - d[0]}`)
            .style('left', `${event.pageX}px`)
            .style('top', `${event.pageY}px`);
        })
        .on('mouseout', () => d3.select(this.tooltip).style('opacity', 0));
    });
  });

  // Axes
  svg
    .append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(xScale0));

  svg.append('g').call(d3.axisLeft(yScale));
},

drilldowndata(filteredData, filteredkeyword) {
    if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
        let result = this.sortdata.filter(item => !filteredData.includes(item));
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
        this.filterarray = filterarraydeleted;
        this.filterkeyword = `${this.selectedXAxis}(${this.selectedGroups})`;
      } else {
    this.selectedGroups.push(filteredkeyword);
        this.filterarray.push(`(${filteredkeyword})`)
        this.filterkeyword = `${this.selectedXAxis}(${this.selectedGroups})`;
        this.sortdata = this.sortdata.concat(filteredData);
      }
      this.createChart();
  },
  async cancelselection() {
  this.selectedGroups = [];
  this.sortdata = [];
  this.createChart();
},
  backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
  recreatechart() {
      // this.$emit("opendrillpopup", this.sortdata);
      // each bars in the following graph to be stacked based on data field which is passed through prop stackvalue
      const dataToEmit = {
    sortedData: this.sortdata,
    filterkeyword: this.filterkeyword
  };
      this.$emit("drilldowncurrentchart", dataToEmit);
            const groupandxaxis={
      "xaxiskey":this.selectedXAxis,
      "groupkey":this.selectedGroupCategory,
      }
      this.$emit("setgroupkeys", groupandxaxis);
    },
// drilldowndata(key,fullArrayData){
//   const dataToEmit = {
//         sortedData: fullArrayData,
//         filterkeyword: `${this.selectedXAxis}(${key})`
//       };
//       this.$emit("drilldowncurrentchart", dataToEmit);
//       const groupandxaxis={
//       "xaxiskey":this.selectedXAxis,
//       "groupkey":this.selectedGroupCategory,
//       }
//       this.$emit("setgroupkeys", groupandxaxis);
// },
    validateData(data) {
      return data.filter(
        (d) => {
          const aiPrediction = d.features.AIPrediction;
          if ((aiPrediction && this.aiPredictionTrue) || (!aiPrediction && this.aiPredictionFalse)) {
            return d.features[this.selectedXAxis] !== undefined && d.features[this.selectedGroupCategory] !== undefined;
          }
          return false;
        }
      );
    },
    aggregateData(data) {
  if (this.fromPage === 'patientScreening' || this.fromPage === 'patientRetention') {
    const validData = this.validateData(data);
    // i have siteCode as x axis value and no of data as y axis value and queryStatus as grouped value. now i also want 
    // responseStatus as stackcode which should be passed by props. i mean each bars should be divides into stacks based on responseStatus.
    // Group by X-axis and Group Category, ensuring "null" is handled
    const nestedData = d3.group(
      validData, 
      d => this.getMappedValue(d.features[this.selectedXAxis], this.selectedXAxis), 
      d => this.getMappedValue(d.features[this.selectedGroupCategory], this.selectedGroupCategory)
    );
    
    this.aggregatedData = Array.from(nestedData, ([key, values]) => ({
      key,
      values: Array.from(values, ([category, records]) => ({
        category: category || "null", // Ensure null or empty strings are labeled as "null"
        value: this.selectedYAxis ? d3.sum(records, r => r.features[this.selectedYAxis]) : records.length
      }))
    }));
  }

  if (this.fromPage!='patientScreenig' && this.fromPage!='patientRetention') {
    const validData = this.data.filter(
      (d) => d[this.selectedXAxis] !== undefined && d[this.selectedGroupCategory] !== undefined
      && d[this.selectedGroupCategory] !== null && d[this.selectedGroupCategory] !== "null"
    );

    const nestedData = d3.group(
      validData, 
      d => this.getMappedValue(d[this.selectedXAxis], this.selectedXAxis), 
      d => this.getMappedValue(d[this.selectedGroupCategory], this.selectedGroupCategory)
    );

    this.aggregatedData = Array.from(nestedData, ([key, values]) => ({
      key: key || "null", // Ensure null or empty strings are labeled as "null"
      values: Array.from(values, ([category, records]) => ({
        category: category || "null", // Ensure null or empty strings are labeled as "null"
        value: this.selectedYAxis ? d3.sum(records, r => r[this.selectedYAxis]) : records.length
      }))
    }));
  }

  return this.aggregatedData;
},
    getMappedValue(value, key) {
  // Check if the value is null or empty string
  if (value === null || value === "") {
    return "null"; // Return "null" to be displayed
  }

  // Return the mapped value if it exists, otherwise the original value
  return this.mappings[key] ? this.mappings[key][value] || value : value;
},
  },
};
</script>

<style scoped>
@import "../AiCharts/charts.css";
.legandbox{
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
.bar-chart-wrapper {
  overflow-x: auto;
  width: 100%;
}

.bar-chart {
  position: relative;
}

.legend {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.legend-item {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.legend-color.enrolled {
  background-color: darkblue;
}

.legend-color.non-enrolled {
  background-color: lightblue;
}

.controls {
  margin-bottom: 10px;
}

.controls label {
  margin-right: 5px;
}

.controls select {
  margin-right: 15px;
}

.gropbarwidth {
  height: 405px;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
