/* eslint-disable  */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import * as queryService from "./queryService";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import store from '@/store';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Result } from "vite/node_modules/postcss";
export default {
    name: 'querymapping',
    components: { Multiselect },
    props: {
        queryid: String,
        closedoption: {
            type: String,
            default: 'allQueryList'
        },
    },
    data() {
        return {
            roleprivileges: "",
            baseurl: process.env.VUE_APP_Service_URL,
            messages: [],
            queries: [],
            response: [],
            replies: [],
            lastMessage: [],
            postReply: "",
            raisedBy: "",
            queryId: "",
            removeButtons: false,
            showForm: false,
            lastModifier: "",
            lastModifiedDate: "",
            length: 0,
            values: {
                mode: 'tags',
                value: [],
                options: [],
                closeOnSelect: false,
                searchable: true,
                createTag: true,
                resolveOnLoad: true
            },
            recipients: [],
            userName: "",
            showList: false,
            studyName: "",
            sites: "",
            defaultTime: "",
        }
    },
    async mounted() {
        window.addEventListener("studyIdChanged", (event) => {
            console.log("StudyIDChanged into" + event.detail.storage);
            // window.location.reload();
            this.queryId = this.queryid
            this.getUserName();
            this.getbyId();
            this.getStudyById();
            this.getSites();
            this.getRecipients();
        });
        this.defaultTime = localStorage.getItem("timeformat");
        await this.setroleprivileges();
        this.queryId = this.queryid
        this.getUserName();
        await this.getbyId();
        this.getRecipients();
    },
    computed: {
        isDisabled() {
            if (this.postReply == "") {
                return true
            }
        }
    },
    methods: {
        async setroleprivileges() {
            this.roleprivileges = await store.getters.getRolesprivilegeData;
        },
        async gotoform() {
            store.dispatch("setFieldName", this.queries.fieldName);
            console.log("Pat id in query:",this.queries.patientId);
            localStorage.setItem("patientIdis",this.queries.patientId)

            //this.$router.push(`/showform/forms/${this.queries.formId}`);
            const archive = false;
            this.$router.push(`/showform/forms/${this.queries.formId}/${archive}`);
        },
        async exportpdf() {
            await this.getStudyById();
            console.log("", this.studyName);
            console.log("export",);
            let pdffilename = "";
            if (this.queries.formId == "" || this.queries.formId == '' || this.queries.formId == null) {
                // general Query
                //:'Study'_QueryID'.pdf

                pdffilename = this.studyName + "_" + this.queries.queryId;
            }
            else {
                // field, page or form Query
                //'Study'_'Site code'_'Subject ID_'Query ID'.pdf
                pdffilename = this.studyName + "_" + this.queries.siteId + "_" + this.queries.subjectId + "_" + this.queries.queryId;
            }
            console.log("for pdf", pdffilename);
            // let removenode = document.getElementById("childnodetoremove");
            //console.log(element);
            let element = document.getElementById('printcontent');
            // this.removeButtons=false;
            // console.log(element);
            let doc = new jsPDF('p', 'mm');
            console.log("element is", element);
            await html2canvas(element).then(canvas => {
                console.log("for pdf2");
                let imgData = canvas.toDataURL('image/png');

                let imgWidth = 210;
                let pageHeight = 295;
                let imgHeight = canvas.height * imgWidth / canvas.width;
                let heightLeft = imgHeight;
                let position = 10;
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = (heightLeft - imgHeight) + 10;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                //test pdf save ends
            });
            // foeeatch statement for paging pdf ends
            doc.save(pdffilename + '.pdf');
        },
        filter(date) {
            if (date) {
                return moment(date).format('DD-MM-YYYY')
            }
            console.log("Reverse is")
            console.log(date)

        },
        formatTime(time){
            if (time) {
                return moment(time).format(this.defaultTime);
            }
            
          },
        async getSites() {
            const currentstudy = await store.getters.getStudyIs;
            const idtoken = store.getters.getIdToken;
            await axios
                .get(`${this.baseurl}/management/site/searchlist?SiteCode=` + this.queries.siteId + `&StudyID=` + currentstudy,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        }
                    })
                .then((res) => {
                    this.sites = res.data.results[0];
                    console.log("siteslist");
                    console.log(this.sites.results[0]);

                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async getRecipients() {
            if (this.queries.queryFor == "General Query") {
                const valnow = await store.getters.getStudyIs;
                const idtoken = store.getters.getIdToken;
                await axios
                    // .get(`${this.baseurl}/account-core/user/getusersbysiteprivilege?studyid=${valnow}&siteCode=${this.queries.siteId}`,
                    .get(`${this.baseurl}/management/study/getusersinstudy?StudyId=${valnow}`,
                        {
                            headers: {
                                Authorization: "Bearer " + idtoken,
                                "Content-Type": "application/json",
                            }
                        })
                    .then((res) => {
                        console.log("Recipients are...", res.data);
                        this.values.options = [];
                        res.data.forEach(element => {
                            if (element.email != this.userName) { this.values.options.push(element) }
                        });

                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            else {
                await this.getSites();
                console.log("hiii", this.sites.siteID);
                const valnow = await store.getters.getStudyIs;
                const idtoken = store.getters.getIdToken;
                console.log("Reccccc", this.queries);
                await axios
                    .get(`${this.baseurl}/account-core/user/getusersbysiteprivilege?studyid=${valnow}&siteCode=${this.queries.siteId}`,
                        //.get(`${this.baseurl}/management/study/getusersinstudy?StudyId=${valnow}`,
                        {
                            headers: {
                                Authorization: "Bearer " + idtoken,
                                "Content-Type": "application/json",
                            }
                        })
                    .then((res) => {
                        console.log("in service function");
                        console.log("Recipients are...", res.data);
                        this.values.options = [];
                        res.data.forEach(element => {
                            if (element.email != this.userName) { this.values.options.push(element) }
                        });

                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        // replay and colse
        async replyClose(removeButtons, showForm) {
            if (this.values.value.includes(this.userName)) {
                const filtersList = this.values.value.filter(element => element !== this.userName)
                this.values.value = filtersList
            }
            if (this.values.value.length == 0) {
                alert(this.$t('navigations.please select a recipient'))
            }
            else {

                var replyClose = confirm(this.$t('navigations.are you sure you want to send this message and close this query?'));
                if (replyClose == true) {
                    this.raisedBy = this.userName
                    await queryService.apiReply(this.baseurl, this.queries.id, this.postReply, this.raisedBy, this.values.value, this.userName, this.recipients)
                    await queryService.apiClose(this.baseurl, this.userName, this.queries.id)
                    removeButtons = !removeButtons
                    showForm = !showForm
                    if (this.closedoption == "showform") {
                        await this.$router.go(-1);
                    }
                    else {
                        await this.$router.push(`/${this.closedoption}`);
                    }
                }
            }
        },
        async getStudyById() {
            const valnow = await store.getters.getStudyIs;
            const idtoken = store.getters.getIdToken;
            await axios.get(`${this.baseurl}/management/study/getbyid/${valnow}`,
                {
                    headers: {
                        Authorization: "Bearer " + idtoken,
                    },
                })
                .then(res => {
                    this.studyName = res.data.studyName;
                })
        },
        //Get by Id         
        async getbyId() {
            const idtoken = store.getters.getIdToken;
            console.log(this.recipients)
            console.log(idtoken)
            await axios.get(
                `${this.baseurl}/forms/query/getbyid/${this.queryId}?api-version=1`,
                {
                    headers: {
                        Authorization: "Bearer " + idtoken,
                    },
                }
            )
                .then((response) => {
                    if (response.data) {
                        this.queries = response.data
                        this.messages = response.data.messages
                        this.length = this.messages.length
                        console.log("Length is", this.length)
                        console.log(this.queries)
                        console.log("Messages are", this.messages)
                        this.getMessage()
                        this.lastMessage.push(this.messages.at(-1))
                        console.log("The last message is", this.lastMessage)
                        this.values.value.push(this.queries.createdUserEmail)
                        if (!this.values.value.includes(this.queries.lastModifiedUserEmail)) {
                            this.values.value.push(this.queries.lastModifiedUserEmail)
                        }
                        console.log("Recipients ", this.values.value)
                        this.patientId = this.queries.patientId;
                        if (this.queries.queryOpenStatus == "Closed") {
                            this.removeButtons = true
                            this.showForm = true
                        }
                        console.log(this.messages)
                    }

                    else
                        console.log('Not found')
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async getMessage() {
            this.messages.filter((item) => {
                item.messagesRecipients.filter((subitem) => {
                    if (subitem.recipients) {
                        this.recipients = []
                        this.recipients = subitem.recipients
                    }
                })
            });

        },
        async jwtDecrypt(token) {
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(function (c) {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );
            return JSON.parse(jsonPayload);
        },
        async getUserName() {
            const idToken = store.getters.getIdToken;
            const jwtDecodedValue = await this.jwtDecrypt(idToken);
            this.userName = jwtDecodedValue.name;
            console.log("Username is", this.userName);
        },

        //Reply
        async onReply() {
            if (this.values.value.includes(this.userName)) {
                const filtersList = this.values.value.filter(element => element !== this.userName)
                this.values.value = filtersList
            }
            if (this.values.value.length == 0) {
                alert(this.$t('navigations.please select a recipient'))
            }
            else {
                var confirmed = confirm(this.$t('navigations.are you sure you want to send this message?'))
                if (confirmed == true) {
                    console.log(this.queries.id)
                    console.log(this.postReply)
                    this.raisedBy = this.userName
                    await queryService.apiReply(this.baseurl, this.queries.id, this.postReply, this.raisedBy, this.values.value, this.userName, this.recipients);
                    if (this.closedoption == "showform") {
                        await this.$router.go(-1);
                    }
                    else {
                        await this.$router.push(`/${this.closedoption}`);
                    }
                }
            }
        },

        //Query close 
        async statusChange(removeButtons, showForm) {
            // if(this.values.value.includes(this.userName)){
            //     const filtersList = this.values.value.filter(element => element !== this.userName)
            //     this.values.value=filtersList
            // }
            // if(this.values.value.length == 0){
            //     alert(this.$t('navigations.please select a recipient'))
            // }
            // else{
            var confirmed = confirm(this.$t('navigations.are you sure you want to close this query?'))
            if (confirmed == true) {

                await queryService.apiClose(this.baseurl, this.userName, this.queries.id)
                removeButtons = !removeButtons
                showForm = !showForm
                if (this.closedoption == "showform") {
                    await this.$router.go(-1);
                }
                else {
                    await this.$router.push(`/${this.closedoption}`);
                }
            }
            // }
        },
        async onclosed() {
            var confirmed = confirm(this.$t('navigations.are you sure you want to leave this page?'))
            if (confirmed == true) {
                if (this.closedoption == "showform") {
                    await this.$router.go(-1);
                }
                else {
                    await this.$router.push(`/${this.closedoption}`);
                }
            }
        },
    }
}
