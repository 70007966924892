<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.subjects") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 px-1 pl-lg-0">
            <label class="mb-0">{{ $t("navigations.site code") }}</label>
            <select class="form-select my-md-2 my-lg-0" name="account" placeholder="Site Code"
              v-model="searchData.siteId">
              <!-- <option disabled value="">{{ $t("--Select--") }}</option> -->
              <option selected value="">{{ $t("navigations.all") }}</option>
              <option v-for="site in sites" :key="site.siteID" :value="site.siteID">
                {{ site.siteCode }}
              </option>
              <!--<option>Archived</option>-->
            </select>
          </div>
          <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0">{{ $t("navigations.subject id") }}</label>
            <input type="text" maxlength="1000" class="form-control my-md-2 my-lg-0" v-model="searchData.subjectid" />
          </div>
          <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0">{{ $t("navigations.status") }}</label>
            <select class="form-select my-md-2 my-lg-0" name="account" placeholder="Search" v-model="searchData.status">
              <!-- <option disabled value="">{{ $t("--Select--") }}</option> -->
              <option selected value="">{{ $t("navigations.all") }}</option>
              <option value="Completed">{{ $t("navigations.completed") }}</option>
              <option value="enrolled">{{ $t("navigations.enrolled") }}</option>
              <option value="new">{{ $t("navigations.new") }}</option>
              <option value="Visit Ongoing">{{ $t("navigations.visit ongoing") }}</option>
              <option value="Withdrawn">{{ $t("navigations.withdrawn") }}</option>
            </select>
          </div>

          <span typr="button" class="col-sm-12 col-lg-2 save_btn my-1 my-lg-0 mx-lg-1 align-self-end"
            @click="SearchButtonClicked()">
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="mt-1">{{ $t("navigations.subject list") }}</h5>
        <div class="d-flex">
          <div class="position-relative align-self-center mr-3" v-if="roleprivileges.includes('Subjects Upload File')"
            aria-label="Upload subject file" tabindex="0">
            <input class="d-none" accept=".xls,.xlsx" id="InPutFile" type="file" @change="previewFiles" />
            <img width="17" height="17" class="cursor-pointer" src="../../assets/img/upload.png" alt="Upload subject"
              @click="onFileChange()" />
            <div class="tool-tip">{{ $t("navigations.upload subject") }}</div>
          </div>
          <div class="position-relative align-self-center"
          :class="{'mr-3':patientLists.length!=0}"
            v-if="roleprivileges.includes('Subjects Create Subject')" aria-label="Create subject" tabindex="0">
            <img width="17" height="17" class="cursor-pointer" src="../../assets/img/create.png" alt="Create subject"
              @click="editPatient(0)" />
            <div class="tool-tip">{{ $t("navigations.create subject") }}</div>
          </div>
          <div class="position-relative cursor-pointer" v-if="patientLists.length!=0" @click.prevent="showFilter = !showFilter"><img
              src="../../assets/img/filter.png" width="17" height="17" alt="filter table" />
            <div class="tool-tip">{{ $t("navigations.filter") }}</div>
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <div class="d-flex justify-content-end px-0 mb-2" v-if="showFilter">
          <div class="col-3 px-0" v-on:clickout="dropdownOpen = false">
            <div class=" table-multi-select-dropdown" @click.stop="dropdownOpen = !dropdownOpen;">
              <div class="selected-options">
                <span>{{ $t("navigations.select columns") }}</span>
              </div>
              <div class="dropdown-arrow"></div>
            </div>
            <div v-if="dropdownOpen" class="table-dropdown-content" @click.stop>
              <label v-for="(column, index) in getColumns" :key="index">
                <input type="checkbox" v-model="selectedcolumnobj[column]" :value="true" /> {{ column }}
              </label>
            </div>
          </div>
        </div>
        <div class="text-center" v-if="patientLists.length===0">
          No data available
        </div>
        <div v-else class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th v-if="selectedcolumnobj['Site Code']">
                  {{ $t("navigations.site code") }}
                </th>
                <th v-if="selectedcolumnobj['Subject ID']">
                  {{ $t("navigations.subject id") }}
                </th>
                <th v-if="selectedcolumnobj['Status']" @click="SortSelected('status', 3)" id="3">
                  <div class="sort_block">
                    <div class="d-flex flex-row justify-content-between">
                      {{ $t("navigations.status") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Language']" class="sort_block" @click="SortSelected('language', 4)" id="4">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.language") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Country']" class="sort_block" @click="SortSelected('country', 5)" id="5">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.country") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Investigator']" class="sort_block" @click="SortSelected('nurseName', 6)"
                  id="6">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.investigator") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Enrolment Date']" class="sort_block"
                  @click="SortSelected('enrollmentDate', 7)" id="7">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.enrolment date") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Active']">{{ $t("navigations.active") }}</th>
                <th  class="text-center">Video Call</th>
                <th class="text-center" v-if="roleprivileges.includes('Subjects Schedule')">
                  {{ $t("navigations.schedule") }}
                </th>
                <th class="text-center" v-if="roleprivileges.includes('Subjects Complete')">
                  {{ $t("navigations.complete") }}
                </th>
                <th class="text-center" v-if="(!roleprivileges || roleprivileges.includes('Subjects Edit'))">
                  {{ $t("navigations.edit") }}
                </th>
                <th class="text-center" v-if="roleprivileges.includes('Subjects Withdraw')">
                  {{ $t("navigations.withdraw") }}
                </th>
                <th class="text-center" v-if="roleprivileges.includes('Audit Trail Subjects')">
                  {{ $t("navigations.audit trail") }}
                </th>
                <th class="text-center" v-if="(!roleprivileges || roleprivileges.includes('Subjects Delete'))">
                  {{ $t("navigations.delete") }}
                </th>
                <!-- <th v-if="roleprivileges.includes('Subjects Add Document')">{{ $t('navigations.documents') }}</th> -->
                <th v-if="roleprivileges.includes('Subjects Manage Folder & Document')" class="text-center">
                  {{ $t("navigations.document") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="patient in patientLists" :key="patient.studyId">
                <td v-if="selectedcolumnobj['Site Code']">{{ patient.siteCode }}</td>
                <td v-if="selectedcolumnobj['Subject ID']">{{ patient.subjectId }}</td>
                <td v-if="selectedcolumnobj['Status']">{{ patient.status }}</td>
                <td v-if="selectedcolumnobj['Language']">{{ patient.language }}</td>
                <td v-if="selectedcolumnobj['Country']">{{ patient.country }}</td>
                <td v-if="selectedcolumnobj['Investigator']">{{ patient.nurseName }}</td>
                <td v-if="selectedcolumnobj['Enrolment Date']">{{ filter(patient.enrollmentDate) }}</td>
                <td v-if="selectedcolumnobj['Active']" class="text-center">
                  <div class="i-checks">
                    <input type="checkbox" v-model="patient.active" onclick="return false" />
                  </div>
                </td>
                <td class="text-center">
                  <i class="fa fa-video-camera edit-delete" @click.prevent="openVideoCallSchedulePopup(patient.patientId)"></i>
                  
                </td>
                <td v-if="roleprivileges.includes('Subjects Schedule')" class="text-center">
                  <i class="fa fa-calendar edit-delete" v-if="patient.active && isVisitCreated > 0"
                    @click="shedule(patient.patientId)"></i>
                </td>

                <td v-if="roleprivileges.includes('Subjects Complete')" class="text-center">
                  <i v-if="
                    patient.status == 'Enrolled' || patient.status == 'Visit Ongoing'
                  " class="fa fa-check-square-o edit-delete" @click="completepatient(patient.patientId)"></i>
                </td>

                <td class="text-center" v-if="(!roleprivileges || roleprivileges.includes('Subjects Edit'))">
                  <i v-if="patient.status != 'Withdrawn'" class="fa fa-edit edit-delete"
                    @click="editPatient(patient.patientId)"></i>
                </td>

                <td v-if="roleprivileges.includes('Subjects Withdraw')" class="text-center">
                  <!-- <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i> -->
                  <i v-if="patient.status != 'Withdrawn' && patient.status != 'Completed'"
                    class="fa fa-user-times edit-delete" @click="withdrawPatient(patient.patientId)"></i>
                </td>
                <td v-if="roleprivileges.includes('Audit Trail Subjects')" class="text-center">
                  <i class="fa fa-eye edit-delete" @click="openModelaudit(patient.patientId)"></i>
                </td>
                <td class="text-center" v-if="(!roleprivileges || roleprivileges.includes('Subjects Delete'))">
                  <i v-if="patient.status == 'New'" class="fa fa-trash edit-delete"
                    @click="deletePatient(patient.patientId)"></i>
                </td>
                <!-- <td class="text-center" v-if="roleprivileges.includes('Subjects Add Document')">
                  <i
                    class="fa fa-calendar edit-delete"
                    @click="documentPage(patient.patientId)"
                  ></i>
                </td> -->
                <td v-if="roleprivileges.includes('Subjects Manage Folder & Document')" class="text-center">
                  <i @click="
                    folderList(patient.patientId, patient.subjectId, patient.siteCode)
                    " title="Document" class="fa fa-folder edit-delete">
                  </i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!------ pagination -------->
        <div class="row mt-3 mx-0" v-if="patientLists.length!=0">
          <div class="col-lg-12 mx-0 px-0">
            <div class="dataTables_paginate paging_simple_numbers pagination float-right">
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination v-model="currentPage" :pages="totalPages" :range-size="1" active-color="#618bd7"
                  @update:modelValue="loadPage" />
              </div>
            </div>
          </div>
        </div>

        <!-- pagin -->
      </div>
      <!-- Audit trail details modal starts -->
      <div v-if="auditpopup == true" class="audit__details__modal__wrapper">
        <div class="audit__details__modal__container">
          <div class="audit__details__modal__title d-flex justify-content-between py-2 px-3">
            <h4 class="my-0">{{ $t("navigations.audit details") }}</h4>
            <div class="cursor-pointer" title="Close">
              <i class="fa fa-times" title="Close" @click.prevent="closemodallll()"></i>
            </div>
          </div>
          <!-- Popup -->
          <div class="audit__details__modal__content p-3" :class="{ heightBig: changeHeight }">
            <div class="table-responsive">
              <table class="table table-hover issue-tracker">
                <tbody>
                  <tr v-for="audit in auditLists" :key="audit.studyId" class="cursor-pointer">
                    <td @click="openModelauditOps(audit.id)">
                      <span class="d-block"><strong> {{ audit.loggedInUserName }}</strong></span>
                     
                    </td>
                    <td @click="openModelauditOps(audit.id)">
                      <div> <small>{{ filter(audit.createdAt) }}</small></div>
                      <div> <small>{{ toTime(audit.createdAt) }}</small></div>
                    </td>
                    <td v-if="audit.operation == 'Update'" @click="openModelauditOps(audit.id)">
                      <span class="badge badge-primary">{{ audit.operation }}</span>
                    </td>
                    <td v-if="audit.operation == 'Delete'" @click="openModelauditOps(audit.id)">
                      <span class="badge badge-danger">{{ audit.operation }}</span>
                    </td>
                    <td v-if="audit.operation == 'Insert'" @click="openModelauditOps(audit.id)">
                      <span class="badge badge-warning-light">{{ audit.operation }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--  popup -->
    </div>
    <div class="audit__history__modal__wrapper" v-if="audithistortpopup == true">
      <div class="audit__history__modal__container">
        <div class="audit__history__modal__title d-flex justify-content-between py-2 px-3">
          <h4 class="my-0">{{ $t("navigations.audit trail") }}</h4>
          <div class="cursor-pointer" title="Close">
            <i class="fa fa-times" @click.prevent="closehistorymodal()"></i>
          </div>
        </div>
        <!-- test -->

        <!-- Popup -->
        <div class="audit__history__modal__contents p-3">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.field name") }}</strong>
                  </th>
                  <th>
                    <strong>{{ $t("navigations.old value") }}</strong>
                  </th>
                  <th>
                    <strong>{{ $t("navigations.new value") }}</strong>
                  </th>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.subject id") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.SubjectId }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.SubjectId }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.enrolment date") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ filter(auditHistoryLists.oldValue.EnrollmentDate) }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ filter(auditHistoryLists.newValue.EnrollmentDate) }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.country") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.Country }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.Country }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.investigator") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.NurseName }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.NurseName }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.pre visit") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.PreVisit }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.PreVisit }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.post visit") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.PostVisit }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.PostVisit }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.city") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.City }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.City }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.post/zip code") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.ZipCode }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.ZipCode }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.language") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.Language }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.Language }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.subject notes") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.PatientNotes }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.PatientNotes }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.language code") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.LanguageCode }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.LanguageCode }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <!-- <tr>
                  <th>{{ $t('navigations.language')}}</th>
                  <td>English</td>
                  <td>English</td>
                </tr> -->
                <tr>
                  <th>
                    <strong>{{ $t("navigations.active") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.Active }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.Active }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <!-- <tr>
                  <th>{{ $t('navigations.create schedule')}}</th>
                  <td v-if="auditHistoryLists.oldValue">{{auditHistoryLists.oldValue.Active}}</td>
                    <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.oldValue">Yes</td>
                    <td v-else>{{}}</td>
                </tr> -->
              </thead>
            </table>
          </div>
        </div>
       
      </div>
    </div>
  </div>
  <!--history modal starts -->

  <!--  popup -->
   <CreateVideoCallSchedulePopUp v-if="CreateVideoCallSchedulePopUp == true"
          @closeModal="CreateVideoCallSchedulePopUp = false" :patientid="selectedPatientId" :fromPage="'SubjectList'" />
</template>
<script>
/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import CreateVideoCallSchedulePopUp from "../../components/CreateVideoCallSchedulePopUp/CreateVideoCallSchedulePopUp.vue";
export default {
  name: "patientList",
  components: {
    VPagination,
    CreateVideoCallSchedulePopUp
  },
  data() {
    return {
      SubjectList:"SubjectList",
      CreateVideoCallSchedulePopUp:false,
      loadNextPage: false,
      auditpopup: false,
      audithistortpopup: false,
      changeHeight: false,
      showFilter: false,
      dropdownOpen: false,
      selectedcolumnobj: {},
      defaultdate: "",
      xlsFile: "",
      roleprivileges: "",
      ItemID: "",
      AuditId: "",
      auditHistoryLists: [],
      currentPage: 1,
      sites: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/patient/`,
      baseapi: process.env.VUE_APP_Service_URL,
      auditLists: [],
      patientLists: [],
      patientIds: [],
      countries: [],
      enablePrevious: true,
      enableNext: true,
      totalPages: 1,
      siteId: "",
      subjectid: "",
      status: "",
      pageIndex: 1,
      pageSize: 5,
      sortProperty: "enrollmentDate",
      sortorder: 1,

      searchData: {
        siteId: "",
        subjectid: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        sortProperty: "createdAt",
        sortorder: 0,
      },
      sheduleList: [],
      isVisitCreated: 0,
      siteCode: "",
      selectedPatientId: null,
      defaultTime: "",
    };
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    this.defaultTime = localStorage.getItem("timeformat");
    const idToken = store.getters.getIdToken;
    this.headerValues = {
      headers: {
        Authorization: "Bearer " + idToken,

        "Content-Type": "application/json",
      },
    };
    window.addEventListener("studyIdChanged", async (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      // window.location.reload();
      this.IsVsitCreated();
      this.getCountries();
      await this.search();
      this.getsites();
      this.initializeColumns()
      this.filter();
    });
    await this.IsVsitCreated();
    await this.setroleprivileges();
    await this.getCountries();
    await this.search();
    await this.getsites();
    this.initializeColumns()
    this.filter();
  },
  methods: {
    initializeColumns() {
      if (this.patientLists.length > 0) {
        const allowedKeys = [
          "siteCode",
          "subjectId",
          "status",
          "language",
          "country",
          "nurseName",
          "enrollmentDate",
          "active"
        ]
        this.getColumns = Object.keys(this.patientLists[0])
          .filter(column => allowedKeys.includes(column));
        const fieldLabels = {
          siteCode: "Site Code",
          subjectId: "Subject ID",
          status: "Status",
          language: "Language",
          country: "Country",
          nurseName: "Investigator",
          enrollmentDate: "Enrolment Date",
          active: "Active"
        };

        const readableData = allowedKeys.map(key => fieldLabels[key] || key);

        this.getColumns = readableData
        this.selectedColumns = [...this.getColumns];
        this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {});
      }
    },
    onFileChange() {
      document.getElementById("InPutFile").click();
    },
    openVideoCallSchedulePopup(patientId) {
      this.selectedPatientId = patientId;
      console.log("Darthvader is ",this.selectedPatientId);
      this.CreateVideoCallSchedulePopUp = true;
    },
    openModelaudit(patID) {
      document.body.style.overflowY = "hidden";
      this.auditpopup = true;
      this.ItemID = patID;
      console.log("ITEM ID Issssssssssssssssss...", this.ItemID);
      //this.getDocumentHistory(this.documentId);
      //const modalWrapper = document.querySelector(".modal-wrapper");
      //modalWrapper.classList.add("active");
      this.getAuditdetails();
    },
    openModelauditOps(audID) {
      console.log("audit id issssssssssssssssssssssssssssss", audID);
      this.audithistortpopup = true;
      this.AuditId = audID;
      console.log("audit id is......?", this.AuditId);
      this.getAuditHistory();
    },
    async closemodallll() {
      console.log("close modal");
      document.body.style.overflowY = "auto";
      this.auditpopup = false;
    },
    async closehistorymodal() {
      console.log("close modal");
      this.audithistortpopup = false;
    },
    async previewFiles(event) {
      this.xlsFile = event.target.files[0];
      console.log(this.xlsFile);
      this.submitFile();
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged");
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) console.log(this.sortorder);
      {
        if (this.sortorder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.searchData.pageIndex = 1;
      this.currentPage = 1;
      this.sortProperty = sortProp;
      this.sortorder = this.sortorder == 0 ? 1 : 0;
      this.search();
    },
    async folderList(id, subjectID, siteCode) {
      await store.dispatch("setStorageId", id);
      await store.dispatch("setStorageCode", subjectID);
      const storageId = store.getters.getStorageId;
      const storageCode = store.getters.getStorageCode;
      console.log("wowwwwwww", storageId);
      console.log("wowwwwwww", storageCode, siteCode);
      this.$router.push(`/document_patient/${id}/${siteCode}`);
    },
    async submitFile() {
      var formData = new FormData();
      formData.append("item", this.xlsFile, this.xlsFile.name);
      console.log(formData);
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      //TODO Change here
      await axios
        .post(
          // `https://localhost:5001/patient/upload?StudyID=${currentstudy}`,
          `${this.baseapi}/management/patient/upload?StudyID=${currentstudy}`,
          formData,
          {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          },
          {
            onUploadProgress: (uploadEvent) => {
              console.log("Helllo" + uploadEvent.loaded);
            },
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Results";
          link.click();
          URL.revokeObjectURL(link.href);
          alert(this.$t("navigations.file uploaded"));
          window.location.reload();
        })
        .catch((err, response) => {
          console.log(response);
          if (err == "Error: Request failed with status code 400") {
            alert(this.$t("navigations.please upload xls or xlsx file"));
          }
          else if (err == "Error: Request failed with status code 504") {
            alert(this.$t("navigations.incorrect format"));
          }

          // alert("Bad Request");
          window.location.reload();
        });
    },
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },
    toTime(data) {
      if (data) {
        return moment(data).format(this.defaultTime);
      }
    },
    async getCountries() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/management/patient/getallcountry`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.countries = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async IsVsitCreated() {
      console.log("in is visit created under study");
      const currentstudy = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/management/visit/listvisit?StudyId=${currentstudy}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.isVisitCreated = res.data.totalCount;
          console.log("is visit created .........", this.isVisitCreated);
        })
        .catch((err) => console.log("error...", err));
    },
    async shedule(patientId) {
      this.$router.push(`/visitSheduleList/${patientId}`);
    },
    async getsites() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&sortProperty=siteCode&IsDes=false&getAll=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          console.log(idtoken);
          this.sites = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async editPatient(id) {
      console.log(id);
      this.$router.push(`/managePatient/${id}`);
    },
    async deletePatient(id) {
      const idtoken = store.getters.getIdToken;
      if (confirm(this.$t("navigations.are you sure you want to delete this patient?"))) {
        await axios
          .get(`${this.baseapi}/forms/forms/formcount?patientID=${id}`, {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            const idtoken = store.getters.getIdToken;
            console.log("Patient ID:" + id);
            console.log("response :" + res.data);
            if (res.data < 1) {
              this.patientIds.push(id);
              axios
                .delete(this.baseUrl + "deletepatient", {
                  data: this.patientIds,
                  headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                  },
                })
                .then(() => {
                  this.search();
                });
            } else {
              alert(this.$t("navigations.patients with active forms cannot be deleted"));
            }
            alert(this.$t("navigations.deleted successfully"));
          });
      }
    },
    async withdrawPatient(patientId) {
      console.log("header values...", this.headerValues);
      if (
        confirm(this.$t("navigations.are you sure you want to withdraw this patient?"))
      ) {
        await axios

          .put(
            `${this.baseapi}/management/patientschedule/patientwithdrawn?patientId=${patientId}`,
            {},
            this.headerValues
          )
          .then((res) => {
            console.log("withdrawn...!", res);
            alert("Subject withdrawn successfully.\n(Note: The statuses of Completed, Partially Filled, and Overdue forms will remain unchanged.)");
          })

          .catch((err) => {
            console.log("error withdraw...!", err);
            alert(this.$t("navigations.patient withdrawn successfully"));
          });

        this.search();
      }
    },
    async completepatient(patientId) {
      await this.getShedules(patientId);
      console.log("Shedule for complete patient is ", this.sheduleList);
      if (
        this.sheduleList.some(
          (e) => e.visitStatus != "Completed" && e.visitStatus != "Withdrawn"
        )
      ) {
        alert(
          this.$t(
            "navigations.all the visit status should be either completed or withdrawn for completing patient"
          )
        );
      } else {
        if (
          confirm(this.$t("navigations.are you sure you want to complete this subject?"))
        ) {
          await axios
            .put(
              `${this.baseapi}/management/patient/completepatient?patientid=${patientId}`,
              {},
              this.headerValues
            )
            .then((res) => console.log("Completed..", res))
            .catch((err) => console.log("erroe..", err));

          this.search();
        }
      }
    },
    async getShedules(patientId) {
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/patientschedule/getfromsbyvisit?StudyId=${currentstudy}&PatientID=${patientId}&PageNo=1&ItemCount=1000`,
          this.headerValues
        )
        .then((res) => {
          console.log("sheduleList...", res.data.data);
          this.sheduleList = res.data.data;
        })
        .catch((err) => {
          console.log("error in shedule list", err);
        });
    },
    async getPreviouspage() {
      if (this.searchData.pageIndex > 1) {
        this.searchData.pageIndex = this.searchData.pageIndex - 1;
      }
      await this.search();
    },
    async getNextpage() {
      if (this.searchData.pageIndex < this.totalPages) {
        this.searchData.pageIndex = this.searchData.pageIndex + 1;
      }
      await this.search();
    },
    async SearchButtonClicked() {
      this.currentPage = 1;
      this.searchData.pageIndex = 1;
      this.search();
    },

    async search() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      if (this.searchData.siteId == "All") {
        this.searchData.siteId = "";
      }
      if (this.searchData.status == "All") {
        this.searchData.status = "";
      }
      this.siteId = this.searchData.siteId;
      this.subjectid = this.searchData.subjectid;
      this.status = this.searchData.status;
      this.sortProperty = this.sortProperty;
      this.sortorder = this.sortorder;
      this.pageIndex = this.searchData.pageIndex;
      this.pageSize = this.searchData.pageSize;
      await axios
        .get(
          `${this.baseUrl}listpatient?SiteId=${this.siteId}&Studyid=${currentstudy}&SubjectId=${this.subjectid}&Status=${this.searchData.status}&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=${this.sortProperty}&SortOrder=${this.sortorder}&api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          this.patientLists = res.data.results;
          this.siteCode = this.patientLists.subjectId;
          this.patientLists.filter((item) => {
            if (item.country) {
              this.countries.filter((subitem) => {
                if (item.country == subitem.code) {
                  item.country = subitem.name;
                }
              });
            }
          });
          // if(this.loadNextPage==true){
          //   this.initializeColumns()
          //   this.loadNextPage=false
          // }
          console.log("Patient list is", this.patientLists);
          if (res.data.totalCount % this.pageSize != 0) {
            this.totalPages = this.totalPages + 1;
          }
          console.log(res);
        });
    },
    async getAuditdetails() {
      //this.ItemID = this.patientId;
      console.log("item id is", this.ItemID);
      await axios
        .get(
          `${this.baseapi}/management/audit/getaudits?AuditTable=Management&ItemID=${this.ItemID}&Sort=Desc`
        )
        .then((res) => {
          //this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          this.auditLists = res.data.data;
          if (res.data.count > 4) {
            this.changeHeight = true;
          } else {
            this.changeHeight = false;
          }
          console.log("Audit Trial list is", this.auditLists);
          // if (res.data.totalCount % this.pageSize != 0) {
          //   this.totalPages = this.totalPages + 1;
          // }
          console.log(res);
        });
    },
    async getAuditHistory() {
      //this.ItemID = this.patientId;
      console.log("audit Id is...", this.AuditId);
      await axios
        .get(
          `${this.baseapi}/management/audit/getaudit?AuditId=${this.AuditId}&auditTable=Management`
        )
        .then((res) => {
          // this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          this.auditHistoryLists = res.data;
          console.log("Audit History List issssss", this.auditHistoryLists);
          // if (res.data.totalCount % this.pageSize != 0) {
          //   this.totalPages = this.totalPages + 1;
          // }
          console.log(res);
        });
    },
    async loadPage(page) {
      this.currentPage = page;
      this.searchData.pageIndex = page;
      this.loadNextPage = true
      this.search();
    },
    async documentPage(patientId) {
      this.$router.push(`/patientdocument/${patientId}`);
    },
  },
};
</script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";

.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}

.page-links {
  border-radius: 0px;
}

/*pagination styles*/
.pagination {
  margin: 0;
}

.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}

.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}

.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}

.PaginationControl {
  padding: 0.42rem;
}

.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}

.form-control {
  padding: 0.2rem 0.75rem !important;
}

.form-select {
  border-radius: 0 !important;
  padding: 0.2rem 0.75rem !important;
}

.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

/* .modal__container22 {
  width: 49%;
  max-width: 30%;
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
  overflow-x:hidden;
} */
.modal__container22 {
  width: 33%;
  max-width: 30%;
  position: fixed;
  height: 20% !important;
  margin-top: -550px !important;
  margin-left: 409px !important;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
}

.modal__container23 {
  width: 66%;
  max-width: 62%;
  position: fixed;
  height: -14% !important;
  margin-top: -683px !important;
  margin-left: 140px !important;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
}

.scrollable3 {
  overflow-y: scroll;
  height: 250px;
}

.audit__details__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.audit__details__modal__container {
  width: min(90%, 365px);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}

.audit__details__modal__title,
.audit__history__modal__title {
  background-color: var(--pop-up-background);
  color: #ffffff;
}

.heightBig {
  overflow-y: scroll;
  height: 250px;
}

.audit__history__modal__contents {
  overflow-y: scroll;
  height: 404px;
}

.audit__history__modal__wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 4000;
}

.audit__history__modal__container {
  width: min(45%, 90%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}

.headingunderline {
  text-decoration-line: underline;
}
</style>
