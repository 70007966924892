<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t('navigations.users') }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-0 my-lg-0">
        <div
          class="
            d-flex
            flex-column flex-lg-row
            justify-content-lg-start
            my-2 my-lg-0
            mb-lg-0
          "
        >
          <div class="col-lg-5 my-1 pl-lg-0 pr-lg-1">
            <div class="form-group mb-md-0">
              <label class="mb-0">{{ $t('navigations.email or name') }}</label>
              <input
                type="email"
                class="form-control my-md-2 my-lg-0"
                v-model="searchData.searchEmail"
              />
            </div>
          </div>
          <div class="col-lg-5 my-1 pr-lg-1">
            <div class="form-group mb-md-0">
              <label class="mb-0">{{ $t('navigations.role') }}</label>
              <select
                class="form-select my-md-2 my-lg-0 setheight"
                name="account"
                placeholder="Search"
                v-model="searchData.searchRole"
              >
                <option selected value="">All</option>
                <option
                  v-for="role in roleList"
                  :key="role.roleID"
                  :value="role.roleName"
                >
                  {{ role.roleName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-lg-2 my-1 align-self-end">
            <span
              type="button"
              class="save_btn w-100"
              @click.prevent="search()"
            >
              {{ $t('navigations.search') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5> {{ $t('navigations.users list') }}</h5>
        <div
          class="position-relative align-self-center"
          v-if="!roleprivileges || roleprivileges.includes('Users Create User')"
          aria-label="Create User"
          tabindex="0"
        >
          <img
            width="20"
            class="cursor-pointer"
            src="../../assets/img/create.png"
            alt="Create user"
            @click="createuser(0)"
          />
          <div class="tool-tip">Create User</div>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th
                  class="sort_block"
                  @click="SortSelected('firstName', 1)"
                  id="1"
                >                  
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t('navigations.name') }}           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>

                <th
                  class="sort_block"
                  @click="SortSelected('emailIdPrimary', 2)"
                  id="2"
                >                  
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t('navigations.email') }}     
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <!-- <th>Email</th> -->
                <!-- <th class="sort_block" @click="SortSelected('role',3)" id="3">
                  Role
                  <div class="d-flex flex-row justify-content-between">              
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                </th> -->
                <th>{{ $t('navigations.role') }}</th>
                <th class="text-center">{{ $t('navigations.active') }}</th>
                <th
                  class="sort_block"
                  @click="SortSelected('locked', 4)"
                  id="4"
                >                  
                  <div class="d-flex flex-row justify-content-between">              
                    {{ $t('navigations.locked') }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <!-- <th>Locked</th> -->

                <th
                  class="sort_block"
                  @click="SortSelected('createdUser', 5)"
                  id="5"
                >                  
                  <div class="d-flex flex-row justify-content-between">       
                     {{ $t('navigations.created by') }}      
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <!-- <th>Created By</th> -->

                <th
                  class="sort_block"
                  @click="SortSelected('createdAt', 6)"
                  id="6"
                >                 
                  <div class="d-flex flex-row justify-content-between">      
                     {{ $t('navigations.created date') }}       
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <!-- <th>Created Date</th> -->
                <th
                  class="text-center"
                  v-if="
                    !roleprivileges ||
                    roleprivileges.includes('Users User Privilege')
                  "
                >
                  {{ $t('navigations.study privilege') }}
                </th>
                <th
                  class="text-center"
                  v-if="
                    !roleprivileges || roleprivileges.includes('Users Edit')
                  "
                >
                  {{ $t('navigations.edit') }}
                </th>
                <th
                  class="text-center"
                  v-if="
                    !roleprivileges ||
                    roleprivileges.includes('Audit Trail Users')
                  "
                >
                  {{ $t("navigations.audit trail") }}
                </th>
                <th
                  class="text-center"
                  v-if="
                    !roleprivileges || roleprivileges.includes('Users Delete')
                  "
                >
                  {{ $t('navigations.delete') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in userList" :key="user.id">
                <td>{{ user.firstName + " " + user.lastName }}</td>
                <td>{{ user.emailIdPrimary }}</td>
                <!-- <td>{{ user.role }}</td> -->
                <td>
                  <span v-for="(roles, index) in user.role" :key="index"
                    >{{ index == 0 ? roles : "," + roles }}
                  </span>
                </td>
                <td class="text-center">
                  <div class="i-checks">
                    <input
                      type="checkbox"
                      :checked="user.active == true ? true : false"
                      name="status"
                    />
                  </div>
                </td>
                <td>{{ user.locked == true ? "True" : "False" }}</td>
                <td>{{ user.createdUser }}</td>
                <td>{{ user.createdAt ? filter(user.createdAt) : "" }}</td>
                <td
                  class="text-center"
                  v-if="
                    !roleprivileges ||
                    roleprivileges.includes('Users User Privilege')
                  "
                >
                  <i
                    class="fa fa-file-text-o edit-delete"
                    @click="userprivilege(user.id)"
                  ></i>
                </td>
                <td
                  class="text-center"
                  v-if="
                    !roleprivileges || roleprivileges.includes('Users Edit')
                  "
                >
                  <i
                    class="fa fa-edit edit-delete"
                    @click="createuser(user.id)"
                  ></i>
                </td>
                <td
                  v-if="
                    !roleprivileges ||
                    roleprivileges.includes('Audit Trail Users')
                  "
                  class="text-center"
                >
                  <i
                    class="fa fa-eye edit-delete"
                    @click="openModelaudit(user.id)"
                  ></i>
                </td>
                <td
                  class="text-center"
                  v-if="
                    !roleprivileges || roleprivileges.includes('Users Delete')
                  "
                >
                  <i
                    class="fa fa-trash edit-delete"
                    @click="deleted(user.id, user.userId)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <!------ pagination -------->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="
                  dataTables_paginate
                  paging_simple_numbers
                  pagination
                  float-right
                "
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="currentPageNumber"
                    :pages="totalPages"
                    :range-size="0"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Audit trail details modal starts -->
      <div v-if="auditpopup == true" class="audit__details__modal__wrapper">
        <div class="audit__details__modal__container">
          <div
            class="
              audit__details__modal__title
              d-flex
              justify-content-between
              py-2
              px-3
            "
          >
            <h4 class="my-0">{{ $t("navigations.audit details") }}</h4>
            <div class="cursor-pointer">
              <i class="fa fa-times" title="Close" @click.prevent="closemodal()"></i>
            </div>
          </div>
          <!-- Popup -->
          <div
            class="audit__details__modal__content p-3"
            :class="{ heightBig: changeHeight }"
          >
            <div class="table-responsive">
              <table class="table table-hover issue-tracker">
                <tbody>
                  <tr
                    v-for="audit in auditLists"
                    :key="audit.studyId"
                    class="cursor-pointer"
                  >
                    <td @click="openModelauditOps(audit.id)">
                      <span class="d-block"
                        ><strong> {{ audit.loggedInUserName }}</strong></span
                      >
                    </td>
                    <td  @click="openModelauditOps(audit.id)">
                       <div> <small>{{ filter(audit.createdAt) }}</small> </div>
                       <div> <small>{{ toTime(audit.createdAt) }}</small> </div>
                    </td>
                    <td
                      v-if="audit.operation == 'Update'"
                      @click="openModelauditOps(audit.id)"
                    >
                      <span class="badge badge-primary">{{
                        audit.operation
                      }}</span>
                    </td>
                    <td
                      v-if="audit.operation == 'Delete'"
                      @click="openModelauditOps(audit.id)"
                    >
                      <span class="badge badge-danger">{{
                        audit.operation
                      }}</span>
                    </td>
                    <td
                      v-if="audit.operation == 'Insert'"
                      @click="openModelauditOps(audit.id)"
                    >
                      <span class="badge badge-warning-light">{{
                        audit.operation
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--  popup -->
    </div>
    <div
      class="audit__history__modal__wrapper"
      v-if="audithistortpopup == true"
    >
      <div class="audit__history__modal__container">
        <div
          class="
            audit__history__modal__title
            d-flex
            justify-content-between
            py-2
            px-3
          "
        >
          <h4 class="my-0">{{ $t("navigations.audit trail") }}</h4>
          <div class="cursor-pointer">
            <i class="fa fa-times" @click.prevent="closehistorymodal()"></i>
          </div>
        </div>
        <!-- test -->

        <!-- Popup -->
        <div class="audit__history__modal__contents p-3">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.field name") }}</strong>
                  </th>
                  <th>
                    <strong>{{ $t("navigations.old value") }}</strong>
                  </th>
                  <th>
                    <strong>{{ $t("navigations.new value") }}</strong>
                  </th>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.first name") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.FirstName }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.FirstName }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.last name") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.LastName }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.LastName }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.primary email") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.EmailIdPrimary }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.EmailIdPrimary }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.secondary email") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.EmailIdSecondary }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.EmailIdSecondary }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.user type") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.UserType }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.UserType }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.role") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.RoleNames[0] }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.RoleNames[0] }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.country") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.Country }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.Country }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.date of birth") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ filter(auditHistoryLists.oldValue.DateOfBirth) }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ filter(auditHistoryLists.newValue.DateOfBirth) }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.phone number") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.PhoneNumber }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.PhoneNumber }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.address line1") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.AddressLineOne }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.AddressLineOne }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.address line2") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.AddressLineTwo }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.AddressLineTwo }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.active") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.Active }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.Active }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.lock") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.Locked }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.Locked }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import auth0 from "auth0-js";
var webAuth = new auth0.WebAuth({
  domain: "dev-datamatica.eu.auth0.com",
  clientID: "R4ggiTZw7Z8WSrmzfoKxUmv09oJZp93j",
  scope: "openid profile email offline_access",
  responseType: "token id_token",
  useRefreshTokens: true,
});
export default {
  name: "userlist",
  components: {
    VPagination,
  },
  data() {
    return {
      auditpopup: false,
      audithistortpopup: false,
      defaultdate: "",
      defaultTime: "",
      roleprivileges: "",
      baseurl: process.env.VUE_APP_Service_URL,
      activeValue: "",
      lockedValue: "",

      enableNext: true,
      enablePrevious: true,

      currentPageSize: 0,
      currentPageNumber: 1,
      userList: [],
      totalIteems: 0,
      totalPages: 0,

      searchData: {
        pageSize: 0,
        pageNumber: 0,
        searchEmail: "",
        searchName: "",
        searchRole: "",
        searchActive: null,
        searchLocked: null,
      },

      requestData: {
        pageSize: 0,
        pageNumber: 0,
        searchEmail: "",
        searchName: "",
        searchRole: "",
        searchActive: null,
        searchLocked: null,
      },
      tempRoleItem: {},
      tempRoleString: "",
      tempListOfRoleId: [],
      roleList: [],
      pageNumbers: [],

      roles: [],
      auditLists: [],
      AuditId: "",
      auditHistoryLists: [],

      sortDesc: "",
      SortOrder: 0,
      SortProperty: "firstName",
    };
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    this.defaultTime = localStorage.getItem("timeformat");
    await this.setroleprivileges();
    this.currentPageSize = 10;
    this.currentPageNumber = 1;
    this.requestData.pageSize = 10;
    this.requestData.pageNumber = 1;
    this.searchAndGetData();
    console.log("data from userlist", this.searchAndGetData);
    this.setRoleList();
    this.filter();
  },
  methods: {
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
      console.log("Reverse is");
      console.log(data);
    },
    toTime(data) {
      if (data) {
        return moment(data).format(this.defaultTime);
      }
    },
    async createuser(id) {
      this.$router.push(`/createuser/${id}`);
    },
    openModelauditOps(audID) {
      console.log("audit id issssssssssssssssssssssssssssss", audID);
      this.audithistortpopup = true;
      this.AuditId = audID;
      console.log("audit id is......?", this.AuditId);
      this.getAuditHistory();
    },
    async closehistorymodal() {
      console.log("close modal");
      this.audithistortpopup = false;
    },
    async getAuditHistory() {
      console.log("audit Id is...", this.AuditId);
      await axios
        .get(
          `${this.baseurl}/management/audit/getaudit?AuditId=${this.AuditId}&auditTable=Accounts`
        )
        .then((res) => {
          // this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          this.auditHistoryLists = res.data;
          console.log("Audit History List issssss", this.auditHistoryLists);
          // if (res.data.totalCount % this.pageSize != 0) {
          //   this.totalPages = this.totalPages + 1;
          // }
          console.log(res);
        });
    },
    openModelaudit(userId) {
      document.body.style.overflowY = "hidden";
      this.auditpopup = true;
      this.ItemID = userId;
      console.log("ITEM ID Issssssssssssssssss...", this.ItemID);
      this.getAuditdetails();
    },
    async getAuditdetails() {
      console.log("item id is", this.ItemID);
      await axios
        .get(
          `${this.baseurl}/management/audit/getaudits?AuditTable=Accounts&ItemID=${this.ItemID}&Sort=Desc&api-version=1.0`
        )
        .then((res) => {
          this.auditLists = res.data.data;
          console.log("Audit Trial list is", this.auditLists);
          console.log(res);
        });
    },
    async closemodal() {
      console.log("close modal");
      document.body.style.overflowY = "auto";
      this.auditpopup = false;
    },
    async userprivilege(userid) {
      this.$router.push(`/userprivilege/${userid}`);
    },

    SortSelected(sortProp, id) {
      console.log("SortPropChanged");
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) 
      {
        this.sortDesc = !this.sortDesc;

        if (this.SortOrder == 0) {

          // if (selectedElement.classList.contains("sortDesc")) {
          //   return;
          // } 
           if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } 
          else selectedElement.classList.add("sortDesc");
        } 
        else 
        {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } 

          // else if (selectedElement.classList.contains("sortAsc")) {
          //   return;
            
          // } 
          else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.searchAndGetData();
    },

    async search() {
      // if (this.searchData.searchRole == "All") {
      //   this.searchData.searchRole = "";
      // }
      this.requestData.pageSize = 10;
      this.requestData.pageNumber = 1;
      this.currentPageNumber = 1;
      this.requestData.searchEmail = this.searchData.searchEmail;
      this.requestData.searchName = this.searchData.searchName;
      this.requestData.searchRole = this.searchData.searchRole;
      this.requestData.searchActive = this.searchData.searchActive;
      this.requestData.searchLocked = this.searchData.searchLocked;
      console.log(this.requestData);
      await this.searchAndGetData();
    },
    async deleted(userid, authid) {
      var confirmed = confirm(this.$t('navigations.are you sure you want to delete this user'));
      if (confirmed) {
        const idtoken = store.getters.getIdToken;
        // console.log(idtoken);
        console.log("Enter mounted");
        await axios
          .delete(
            `${this.baseurl}/account-core/user/deleteuser?id=${userid}&api-version=1.0`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (res) => {
            console.log(res.data);
            console.log("authid is " + authid);
            await this.deleteauth(res.data.access_token, authid);
            this.userList = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.searchAndGetData();
    },
    async deleteauth(deltocken, authid) {
      console.log("in auth0 delete function");
      await axios
        .delete(`https://dev-datamatica.eu.auth0.com/api/v2/users/${authid}`, {
          headers: {
            Authorization: "Bearer " + deltocken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async onPageClick(event) {
      this.requestData.pageNumber = event;
      this.searchAndGetData();
    },
    //To Be Deleted
    async getNextpage() {
      if (this.userList.length / 10 == 1) {
        this.requestData.pageNumber++;
        this.currentPageNumber++;
        this.searchAndGetData();
      }
    },
    async getPreviouspage() {
      if (this.currentPageNumber > 1) {
        this.currentPageNumber--;
        this.requestData.pageNumber--;
        this.searchAndGetData();
      }
    },
    //
    async setRoleList() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/account-core/roles/rolelist`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("DropDownData");
          this.roleList = res.data;
          console.log(this.roleList);
        });
    },

    async searchAndGetData() {
      const idtoken = store.getters.getIdToken;
      console.log("Enter Search");
      console.log(this.requestData);
      if (this.activeValue === "TRUE") {
        this.requestData.searchActive = true;
      } else if (this.activeValue === "FALSE") {
        this.requestData.searchActive = false;
      } else {
        this.requestData.searchActive = null;
      }
      if (this.lockedValue === "TRUE") {
        this.requestData.searchLocked = true;
      } else if (this.lockedValue === "FALSE") {
        this.requestData.searchLocked = false;
      } else {
        this.requestData.searchLocked = null;
      }
      if (this.requestData.searchRole === "All") {
        this.requestData.searchRole = "";
      }
      console.log(this.requestData);
      console.log("users listed");
      await axios
        .get(
          `${this.baseurl}/account-core/user/userlist?Key=${this.requestData.searchEmail}&Role=${this.requestData.searchRole}&pageindex=${this.requestData.pageNumber}&pagesize=10&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.data === "No User Found.") {
            alert("No Users Were Found.");
            this.userList = [];
            this.enableNext = false;
          } else {
            console.log("users data");
            this.userList = res.data.results;
            console.log(res.data.results);
            this.totalIteems = res.data.totalCount;
            this.totalPages =
              Math.trunc(this.totalIteems / 10) < 1
                ? 1
                : this.totalIteems / 10 > Math.trunc(this.totalIteems / 10)
                ? Math.trunc(this.totalIteems / 10) + 1
                : Math.trunc(this.totalIteems / 10);
            this.enableNext = this.currentPageNumber !== this.totalPages;
            this.enablePrevious = this.currentPageNumber !== 1;
          }
          this.totalIteems = res.data.totalCount;
        })
        .catch((err) => {
          console.log(err);
          this.enableNext = false;
          this.enablePrevious = false;
        });
    },
    async loadPage(page) {
      this.currentPageNumber = page;
      this.requestData.pageNumber = page;
      this.searchAndGetData();
    },
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.form-group {
  margin-bottom: 0 !important;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.form-select {
  border-radius: 0 !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.scrollable3 {
  overflow-y: scroll;
  height: 250px;
}
.audit__details__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.audit__details__modal__container {
  width: min(90%, 365px);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}
.audit__details__modal__title,
.audit__history__modal__title {
  background-color: var(--pop-up-background);
  color: #ffffff;
}
.audit__details__modal__content {
  overflow-y: scroll;
  height: 250px;
}
.audit__history__modal__contents {
  overflow-y: scroll;
  height: 404px;
}
.audit__history__modal__wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 4000;
}
.audit__history__modal__container {
  width: min(45%, 90%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}
</style>

