<template>
  <div class="stat-card-white" ref="captureArea">
    <div class="stat-header-white">
      <span>{{ chartlabel }}</span>
      <div v-show="!loader" class="d-flex align-items-center" v-if="!loader">
        <!-- Show attribute dropdown if showcatoptions and not from 'independent' page -->
        <select
          v-if="showcatoptions && fromPage!='independent'"
          class="graph-select py-0"
          v-model="selectedxaxis"
          id="fieldSelect"
        >
          <option value="" disabled>Select X-Axis Field</option>
          <option
            v-for="(column, index) in attributeOptions"
            :key="index"
            :value="column"
          >
            {{ column }}
          </option>
        </select>

        <!-- If fromPage = 'independent' and dropdownoption = 'country', show country dropdown -->
        <div v-if="fromPage==='independent' && dropdownoption==='country'" class="d-flex align-items-center ml-2">
          <label class="mb-0 mr-1">Country:</label>
          <select class="graph-select py-0" v-model="selectedCountry" @change="createBarChart()">
            <option value="All">All</option>
            <option v-for="(country,index) in uniqueCountries" :key="index" :value="country">{{ country }}</option>
          </select>
        </div>

        <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
          <img class="cursor-pointer" @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
          <div class="more-expand">
            <span>{{$t("navigations.data overview")}}</span>
          </div>
        </div> 
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock=true">
          <img src="../../assets//img/expand.png" alt="Expand block">
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false; exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
    </div>
     <div
      class="stat-body-white d-flex flex-column overflow-auto p-1" :class="{'h-100':loader==true}">
      <div v-if="loader" class="loader"></div>
      <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
      <div ref="chartContainerWidth" :class="{'overflow-x-auto':fromPage=='statusReport'||fromPage=='independent'}" v-show="!loader" class="w-100">
        <svg ref="chartContainer" ></svg>
        <div ref="tooltip" class="tooltip"></div>
      </div>
      <div v-if="colorcodes.length!=0" class="d-flex w-100">
          <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
          <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
          {{ colors.type }}
          </div>
        </div>
    </div>
  </div>
  <enlargerpopup v-if="expandBlock==true" @closeModal="expandBlock=false,createBarChart()">
    <div
      class="stat-body-white d-flex flex-column">
    <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
    <div class="histogram-content overflow-x-scroll">
      <svg ref="popupChartContainer"></svg>
    </div>
  </div>
     
  </enlargerpopup>
</template>

<script>
import * as d3 from 'd3';
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';

export default {
  name: 'BarChart',
  components:{
    enlargerpopup
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    colorcodes:{
      type: Array,
      default: () => [],
    },
    availableColumns: {
      type: Array,
      required: true,
      default: () => [],
    },
    loader: {
      type: Boolean,
      default: false,
    },
    xAxisKey: {
      type: String,
      default: 'AEOUT',
    },
    yAxisKey: {
      type: String,
      default: 'AEYN',
    },
    yAxisLabel: {
      type: String,
      default: 'Count',
    },
    xAxisLabel: {
      type: String,
      default: '',
    },
    chartlabel: {
      type: String,
      default: "Bar chart",
    },
    fromPage:{
      type:String,
      default:""
    },
    groupvars:{
      type: Array,
      required: true,
      default: () => ["baseline_cost","actual_cost"],
    },
    graphwidth: {
      type: Number,
      default: 1200,
    },
    width: {
      type: Number,
      default: 1000,
    },
    graphheight: {
      type: Number,
      default: 400,
    },
    showcatoptions: {
      type: Boolean,
      default: true,
    },
    drilldowneabled: {
      type: Boolean,
      default: true,
    },
    sort: {
      type: String,
      default: "",
    },
    dropdownoption: {
      type: String,
      default: "country",
    },
  },
  data() {
    return {
      showVisitPopup: false,
      attributeOptions:[],
      selectedxaxis:"",
      chartdata: [],
      graphdata:[],
      selectedyaxis: "",
      chartContainer: null,
      resizeObserver: null,
      tooltip: null,
      expandBlock:false,
      barselectedarray:[],
      sortdata: [],
      filterkeyword:"",
      filterarray:[],
      selectedGroups: [],
      color:{},
      tableArray:[],
      mouseX:0,
      mouseY:0,
      selectedCountry: "All",
    };
  },
  computed: {
    uniqueCountries() {
      if(!this.data || this.data.length === 0) return [];
      // Extract unique countries if dropdownoption is 'country'
      if (this.dropdownoption === 'country') {
        return Array.from(new Set(this.data.map(d => d.country))).sort();
      }
      return [];
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$refs.component) {
        console.error("Component ref is not defined.");
      }
    });
    this.extractAttributeOptions();
    window.addEventListener('mousemove', this.updateMousePosition)
    this.selectedxaxis = this.xAxisKey;
    this.selectedyaxis = this.yAxisKey;
    this.chartContainer = this.$refs.chartContainer;
    this.tooltip = this.$refs.tooltip;
    this.createBarChart();
     // Add resize observer to make SVG responsive
    this.resizeObserver = new ResizeObserver(() => this.createBarChart());
    this.resizeObserver.observe(this.$refs.chartContainer);
  },
  watch: {
    data: {
      handler() {
        this.graphdata = this.data
        this.extractAttributeOptions();
        this.createBarChart();
        this.cancelselection();
      },
      deep: true,
    },
    selectedxaxis() {
      if(this.fromPage!=='independent'){
        this.createBarChart();
        this.cancelselection();
      }
    },
    selectedyaxis() {
      this.createBarChart();
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createBarChart();
        });
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
    // Disconnect the observer on component destruction
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    async exporttoexcell() {
      this.$emit("exporttoexcell");
    },
    exporttopdf() {
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.captureArea.cloneNode(true);
      pdfContainer.appendChild(captureArea);
      pdfContainer.style.width = '100%';
      pdfContainer.style.display = 'block';

      const opt = {
        margin: 0,
        filename: `${this.chartlabel}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
      html2pdf().set(opt).from(pdfContainer).save();
    },
    extractAttributeOptions() {
      if (this.data.length > 0) {
        if(this.availableColumns.length == 0){
          this.attributeOptions = Object.keys(this.data[0]).filter((key)=>
            key!='_id' && key!='STUDYID' && key!='USUBJID'
          );
        }
        else{
          this.attributeOptions=this.availableColumns;
        }
      }
    },
    updateMousePosition(event) {
      const rect = this.$refs.chartContainer && this.$refs.chartContainer.getBoundingClientRect();
      if (!rect) return;
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    },
    showtable() {
      this.$emit("opendrillpopup", this.chartdata);
    },
    async cancelselection() {
      this.selectedGroups = [];
      this.sortdata = [];
      this.createBarChart();
    },
    backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = [];
    },
    createBarChart() {
      this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
      this.chartdata = this.data;
      if (!this.chartContainer) return;

      d3.select(this.chartContainer).selectAll('*').remove();

      // If fromPage = 'independent', filter data by selectedCountry if not All
      let filteredData = this.data;
      if (this.fromPage === 'independent' && this.dropdownoption === 'country' && this.selectedCountry !== 'All') {
        filteredData = this.data.filter(d => d.country === this.selectedCountry);
      }

      if (this.fromPage === 'independent') {
        const margin = { top: 30, right: 20, bottom: 100, left: 70 };
        const width = this.width - margin.left - margin.right;
        const height = this.graphheight - margin.top - margin.bottom;

        const svg = d3
          .select(this.chartContainer)
          .attr('width', width + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)
          .append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`);

        // Use filteredData instead of this.data
        const categories = Array.from(new Set(filteredData.map(d => d[this.selectedxaxis])));

        const groupedData = categories.map(cat => {
          const row = { [this.xAxisKey]: cat };
          this.groupvars.forEach(g => {
            const filtered = filteredData.filter(d => d[this.selectedxaxis] === cat);
            const sumVal = d3.sum(filtered, d => +d[g]);
            row[g] = sumVal || 0;
          });
          return row;
        });

        const maxVal = d3.max(groupedData, d => d3.max(this.groupvars, key => d[key]));

        const x = d3.scaleBand()
          .domain(categories)
          .range([0, width])
          .padding(0.2);

        const xSubgroup = d3.scaleBand()
          .domain(this.groupvars)
          .range([0, x.bandwidth()])
          .padding(0.05);

        const y = d3.scaleLinear()
          .domain([0, maxVal || 0])
          .nice()
          .range([height, 0]);

        if (!this.color || typeof this.color.domain !== 'function') {
          this.color = d3.scaleOrdinal()
            .range(['#62B2FD', '#9BDFC4', '#ABF0FF', '#C1D1FF', '#FBD164', '#FDAD73'].concat(d3.schemeCategory10));
        }
        let currentDomain = this.color.domain();
        let newKeys = this.groupvars;
        let updatedDomain = [...currentDomain, ...newKeys.filter(key => !currentDomain.includes(key))];
        this.color.domain(updatedDomain);

        svg.append('g')
          .attr('transform', `translate(0,${height})`)
          .call(d3.axisBottom(x))
          .selectAll('text')
          .attr('transform', 'translate(-10,0)rotate(-45)')
          .style('text-anchor', 'end');

        svg.append('g').call(d3.axisLeft(y));

        svg.append('text')
          .attr('transform', 'rotate(-90)')
          .attr('y', 0 - margin.left)
          .attr('x', 0 - (height / 2))
          .attr('dy', '1em')
          .style('text-anchor', 'middle')
          .style('font-size', '13')
          .text(this.yAxisLabel);

        svg.append("text")
          .attr("x", width / 2)
          .attr("y", height + margin.bottom - 20)
          .attr("text-anchor", "middle")
          .style('font-size', '13')
          .text(this.xAxisLabel);

        // Draw bars
        svg.append("g")
          .selectAll("g")
          .data(groupedData)
          .enter()
          .append("g")
          .attr("transform", d => `translate(${x(d[this.xAxisKey])},0)`)
          .selectAll("rect")
          .data(d => this.groupvars.map(key => ({ key: key, value: d[key], category: d[this.xAxisKey] })))
          .enter().append("rect")
          .attr("x", d => xSubgroup(d.key))
          .attr("y", d => y(d.value))
          .attr("width", xSubgroup.bandwidth())
          .attr("height", d => height - y(d.value))
          .attr("fill", d => this.color(d.key))
          .attr("style", d =>
            this.selectedGroups.length !== 0
              ? (this.selectedGroups.includes(d.category) ? "" : "opacity: 35%;")
              : ""
          )
          .on('mouseover', (event, d) => {
            d3.select(this.tooltip)
              .style('opacity', 1)
              .html(`Category: ${d.category}<br>${d.key}: ${d.value.toFixed(2)}`)
              .style("left", this.mouseX + "px")
              .style("top", this.mouseY + "px");
          })
          .on("mouseout", () => {
            d3.select(this.tooltip).style("opacity", 0);
          })
          .on('mousemove', () => {
            d3.select(this.tooltip)
              .style("left", this.mouseX + "px")
              .style("top", this.mouseY + "px");
          })
          .on('click', (event, d) => {
            if (this.drilldowneabled) {
              d3.select(this.tooltip).style("opacity", 0);
              const filteredDataClick = filteredData.filter(item => (item[this.selectedxaxis] || 'null') === d.category);
              this.clickbarinchart(filteredDataClick, d.category);
            }
          });

        // Add labels inside bars, rotated 90 degrees
        svg.selectAll(".bar-label")
          .data(groupedData.flatMap(d => 
            this.groupvars.map(key => ({
              category: d[this.xAxisKey],
              key: key,
              value: d[key]
            }))
          ))
          .enter()
          .append('text')
          .attr('class', 'bar-label')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .attr('fill', 'black') 
          .text(d => {
            const barHeight = height - y(d.value);
            return barHeight < 14 ? '' : d.value.toFixed(2);
          })
          .attr('transform', d => {
            const barX = x(d.category) + xSubgroup(d.key) + xSubgroup.bandwidth() / 2;
            const barHeight = height - y(d.value);
            const barCenterY = y(d.value) + barHeight / 2;
            return `translate(${barX}, ${barCenterY}) rotate(-90)`;
          });

      } else if (this.fromPage === 'statusReport') {
        this.createStandardBarChartStatusReport();
      } else {
        this.createStandardBarChart();
      }
    },
    createStandardBarChartStatusReport(){
      // ...existing code...
    },
    createStandardBarChart(){
      // ...existing code...
    },
    clickbarinchart(filteredData, filteredkeyword) {
      if (this.selectedGroups.includes(filteredkeyword)) {
          this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
          let result = this.sortdata.filter(item => !filteredData.includes(item));
          this.sortdata = result;
          let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
          this.filterarray = filterarraydeleted;
          this.filterkeyword = `${this.selectedxaxis}(${this.selectedGroups})`;
        } else {
      this.selectedGroups.push(filteredkeyword);
          this.filterarray.push(`(${filteredkeyword})`)
          this.filterkeyword = `${this.selectedxaxis}(${this.selectedGroups})`;
          this.sortdata = this.sortdata.concat(filteredData);
        }
        this.createBarChart();
    },
    recreatechart() {
      const dataToEmit = {
        sortedData: this.sortdata,
        filterkeyword: this.filterkeyword
      };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
},
};
</script>

<style scoped>
@import "../../custom_lib/AiCharts/charts.css";
.bar-chart-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
}
.selectedbargraph{
  opacity: 50%;
}
svg {
  font-size: 10px;
}
text.bar-label {
  fill: black;
  font-size: 12px;
}
.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}
.overflow-x-auto{
    overflow-x:auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.overflow-x-scroll{
  overflow-x: auto;
}
.legandbox {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}
</style>
