/* eslint-disable */
// import createAuth0Client from "@auth0/auth0-spa-js";
import { ref, onMounted } from 'vue'
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { router } from "../../router";
import auth0 from "auth0-js";
import jwt_decode from "jwt-decode";
import axios from "axios";
var webAuth = new auth0.WebAuth({
  domain: "dev-datamatica.eu.auth0.com",
  clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
  scope: "openid profile email offline_access",
  responseType: "token id_token",
  useRefreshTokens: true,
});

export default {
  name: "login",
  setup() {
    let loadingBlock=ref(false)
    const authResult = ref({});
    const userid = ref("");
    const router = useRouter();
    const store = useStore();
    const username = ref("");
    const password = ref("");
    const errr = ref("");
    const patientemail = ref("")
    const patientpassword = ref("")
    const result = ("");
    const defaultdate = ref("");
    const defaultTmile = ref("");
    const hasprivilege = ref(true);
    const inputType=ref("password")
		const visibleOn=ref(false)
    const errorBlock=ref(false)
    let usersettingsUrl=ref(`${process.env.VUE_APP_Service_URL}/account-core/usersettings`);
    let baseurl = ref(process.env.VUE_APP_Service_URL);
    onMounted(async () => {
      let login = document.getElementsByClassName("login-patient")[0];
      login.style.display = "none";
      let loginTabPatient = document.getElementsByClassName("patient-tab-panels")[0]
      loginTabPatient.style.backgroundColor = "#eaeaea"
    })

    async function patientloginscreen() {
      router.push(`/PatientLoginScreen`);
    }

    async function login() {
      errorBlock.value=false
      errr.value=""
      loadingBlock.value=true
      // alert(username.value + password.value);
      // return webAuth.client.login(
      //   {
      //     realm: "Username-Password-Authentication",
      //     username: username.value,
      //     password: password.value,
      //   },
      //   async function (err, authResult) {
      //     if (err) {
      //       console.log("err msg", err)
      //       console.log(err.description);
      //       if (err.description == 'Wrong email or password.') {
      //         errr.value = "Invalid username or password.";
      //       }
      //       if (err.description == "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it.") {
      //         alert(err.description);
      //       }
      //       else if (err.description == "please change your password") {
      //         alert("Your password has been expired. Please reset your password.")
      //         router.push(`/ResetPassword/${username.value}/reset/Webapp`);
      //       }
      //     }
      //     else {
      //       console.log("authResult is");
      //       console.log(authResult);
      //       await loginAudit(authResult)
      //       await axios
      //         .put(
      //           `${baseurl.value}/management/patientschedule/visitstatusupdate`,
      //           {
      //             headers: {
      //               Authorization: "Bearer " + authResult.idToken,
      //               "Content-Type": "application/json",
      //             },
      //           })
      //         .then((res) => {
      //           console.log("statusupdate res", res);
      //         })
      //         .catch((err) => {
      //           console.log("statusupdate res", err);
      //         });
      //       await setdefaultstudy(authResult);
      //       await setuserrole(authResult);
      //       await getUserSettings(authResult)
      //       await statusUpdate();
      //     }
      //   }
      // );
      await axios
        .post(
          `${baseurl.value}/account-core/user/userauthorize`,{
            "userName": userid.value,
            "userEmail": username.value,
            "password": password.value
          },
        )
        .then((res) => {
             authResult.value =res.data
        })          
        .catch((err)=>{           
          if(err.response.data.errors){
            if (err.response.data.errors.UserName && err.response.data.errors.UserName.length !== 0) {
              errorBlock.value=true
              loadingBlock.value=false
              errr.value = "The username field is required";
            } else if (err.response.data.errors.UserEmail && err.response.data.errors.UserEmail.length !== 0) {
              errorBlock.value=true
              errr.value = "The email field is required";
              loadingBlock.value=false
            } else if (err.response.data.errors.Password && err.response.data.errors.Password.length !== 0) {
              errorBlock.value=true
              errr.value = "The password field is required";
              loadingBlock.value=false
            }
          }
          else {
            if(err.response.data === "The username is not associated with this account.")
            {
              errorBlock.value=true
              errr.value = "The username is not associated with this account."
              loadingBlock.value=false
            }
            
            else if (err.response.data === "Invalid email or password."){
              errorBlock.value=true
              errr.value = "Invalid email or password."
              loadingBlock.value=false
            }
            else if(err.response.data== "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it.")
            {
              alert("Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it.")
              loadingBlock.value=false
            }
            else if (err.response.data == "Your password has been expired. Please reset your password.") {
                      alert("Your password has been expired. Please reset your password.")
                      router.push(`/ResetPassword/${username.value}/reset/Webapp`);
                      loadingBlock.value=false
                    }
          }
          
        });
        await loginAudit(authResult.value)
          //Not sure about the need for Below code
            // await axios
            //   .put(
            //     `${baseurl.value}/management/patientschedule/visitstatusupdate`,
            //     {
            //       headers: {
            //         Authorization: "Bearer " + authResult.value.idToken,
            //         "Content-Type": "application/json",
            //       },
            //     })
            //   .then((res) => {
            //     console.log("statusupdate res", res);
            //   })
            //   .catch((err) => {
            //     console.log("statusupdate res", err);
            //   });
            await setdefaultstudy(authResult.value);
           
            await getUserSettings(authResult.value)
            router.push(`/landingPage`);
            //await statusUpdate();
            errr.value=""
            setTimeout(() => {
             setuserrole(authResult.value);
            }, 2000);
        
    }
    /*Tab functionality begins */
    function loginClicked() {
      let login = document.getElementsByClassName("login")[0]
      let loginPatient = document.getElementsByClassName("login-patient")[0]
      let loginTab = document.getElementsByClassName("login-tab-panels")[0]
      let loginTabPatient = document.getElementsByClassName("patient-tab-panels")[0]
      loginTabPatient.style.backgroundColor = "#eaeaea"
      loginTab.style.backgroundColor = "#ffffff"
      loginPatient.style.display = "none"
      login.style.display = "block"
    }
    function patientLogin() {
      let login = document.getElementsByClassName("login")[0]
      let loginTab = document.getElementsByClassName("login-tab-panels")[0]
      let loginTabPatient = document.getElementsByClassName("patient-tab-panels")[0]
      let loginPatient = document.getElementsByClassName("login-patient")[0]
      loginTabPatient.style.backgroundColor = "#ffffff"
      loginTab.style.backgroundColor = "#eaeaea"
      login.style.display = "none"
      loginPatient.style.display = "block"
    }
    async function patientloginClicked() {
      const idtoken = store.getters.getIdToken;
      console.log("in funccccc")
      await axios
        .get(`${this.baseapi}verifypatientlogin?patientemail=${patientemail.value}&patientpassword=${patientpassword.value}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          console.log(res);
          this.result = res.data;
          console.log("resulttttt", this.result);
          if (this.result == true) {
            alert("Patient login success")
            this.$router.push(`/PatientDashboard`);
          }
          else {
            alert("Login failed, please check the credentials!!")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // async function statusUpdate() {
    //   const idtoken = store.getters.getIdToken;
    //   await axios
    //     .get(`${baseurl.value}/management/patientschedule/statusupdate`,
    //       {
    //         headers: {
    //           Authorization: "Bearer " + idtoken,
    //           "Content-Type": "application/json",
    //         },
    //       })
    //     .then((res) => {
    //       console.log(res);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
    /*Tab functionality ends */
    async function getUserSettings(authresult) {
     
      const idtoken = authresult.idToken;
      const emailId = await jwt_decode(idtoken).email;
      await axios
        .get(
          `${usersettingsUrl.value}/getbyid?userId=${emailId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            }
          },
        )
        .then((res) => {
            defaultdate.value = res.data.dateFormat; 
            defaultTmile.value = res.data.timeFormat;                    
            console.log("default dateformat from api",defaultdate.value);
            if(defaultdate.value=="undefined" || defaultdate.value==null)
            {
              localStorage.setItem("dateformat","DD/MM/YYYY")
            }
            else{
            localStorage.setItem("dateformat",defaultdate.value)
            }

            if(defaultTmile.value=="undefined" || defaultTmile.value==null)
            {
              localStorage.setItem("timeformat","h:mm A")
            }
            else{
              localStorage.setItem("timeformat", defaultTmile.value);
            }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function passwordVisible()
      {
        visibleOn.value=!visibleOn.value
        if(visibleOn.value==true){
          inputType.value="text"
        }
        else inputType.value="password"
      } 
    async function getrollprevilage(array, authresult) {
      const idtoken = authresult.idToken;
      // let array = (store.getters.getRolesData).split(',');
      array.forEach(async (eachrole) => {
        console.log("rolldata is");
        console.log(eachrole);
        await axios
          .get(`${baseurl.value}/account-core/roles/getscreenroleprivilegebyid?guid=${eachrole}`, {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then(async (res) => {
            if (res.data != "No ScreenRolePrivilege found") {
              console.log("The privilages", res.data.screens);
              // store.dispatch("setrolesprivileges", res.data.screens);
              await setrolesprivileges(res.data.screens, authresult);
            }
            else {
              router.push("/landingpage")
              await gotopages(authresult);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });

    }
    async function setrolesprivileges(data, authresult) {

      let privilegevalues = [];
      await (data).forEach((screen) => {
        for (let j = 0; j < screen.controls.length; j++) {
          if (!privilegevalues.includes(screen.name + " " + screen.controls[j])) {
            privilegevalues.push(screen.name + " " + screen.controls[j]);
          }
        }
      });
      let userprivilegestring = privilegevalues.join();
      await store.dispatch("setrolesprivileges", `test test,${userprivilegestring}`);
      store.dispatch("loginuser", authresult);
      router.push("/landingpage")
      await gotopages(authresult);
    }
    async function gotopages(authresult) {
      const idtoken = authresult.idToken;
      const userid = jwt_decode(idtoken).sub;
      await axios
        .get(`${baseurl.value}/account-core/settings/getdashboard?UserID=${userid}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          store.dispatch("loginuser", authresult);
          if (res.data.defaultDashBoard && typeof res.data.defaultDashBoard != "undefined") {
            store.dispatch("setdefaultdashboard", res.data.defaultDashBoard);
          }
          else {
            let roles = store.getters.getRolesData;
            if (roles[0] == "bfc0a935-0441-405b-92a0-08d9af2e7771") {
              store.dispatch("setdefaultdashboard", "nursesummary");
            }
            else {
              store.dispatch("setdefaultdashboard", "home");
              router.push(`/landingPage`);
            }
          }

        })
        .catch((err) => {
          console.log(err);
          let roles = store.getters.getRolesData;
          if (roles[0] == "bfc0a935-0441-405b-92a0-08d9af2e7771") {
            store.dispatch("setdefaultdashboard", "nursesummary");
            router.push(`/nursesummary`);
          }
          else {
            store.dispatch("setdefaultdashboard", "home");
            router.push(`/home`);
          }
        });
    }
    async function loginAudit(authresult) {
      const token = authresult.idToken;
      const emailId = await jwt_decode(token).email;
      await axios
        .post(`${baseurl.value}/account-core/user/saveloginaudit`,
          {
            "userEmail": emailId,
            "logdetails": [
              {
                "action": "Log In",
                "date": "2022-08-30T09:10:42.634Z",
                "time": "2022-08-30T09:10:42.634Z"
              }
            ]
          }
          ,
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          console.log("login audit res", res)
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function setdefaultstudy(authresult) {
      await getdefaultlanguage(authresult);
      const idtoken = authresult.idToken;
      const userId = await jwt_decode(idtoken).email;
      console.log("userid " + userId);
      await axios
        .get(`${baseurl.value}/management/study/getdefaultstudy?id=` + userId, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.statusText == "No Content") {
            console.log("no default study");
          } else {
            store.dispatch("setStudyID", res.data.studyID);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function setuserrole(authresult) {
      const idtoken = authresult.idToken;
      const userid = (jwt_decode(idtoken).sub).trim();
      await axios
        .get(baseurl.value + "/account-core/user/getuserwithauthid?id=" + userid, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then(async (res) => {
          console.log("rolls are");
          console.log(res.data[0].role);
          store.dispatch("setroles", res.data[0].role);
          await getrollprevilage(res.data[0].role, authresult);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    function googleLogin() {
      console.log("login with google");
      webAuth.authorize(
        {
          connection: "google-oauth2",
          redirectUri: "http://localhost:8080/home",
        },
        function (err, authResult) {
          //do something-------a
          if (err) {
            email
            console.log("err.description");
          } else {
            console.log("authResult");
            store.dispatch("loginuser", authResult);
            // router.push("/home");
          }
        }
      );
    }
    async function getdefaultlanguage(authresult) {
      // alert("default study");
      const idtoken = authresult.idToken;
      const userId = await jwt_decode(idtoken).email;
      await axios
        .get(
          // .get(`${baseurl.value}/management/study/getdefaultstudy?id=` + userId,
          `${baseurl.value}/account-core/usersettings/getbyid?userId=` + userId.trim(),
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("default language", res.data.localLanguage);
          if (res.data.localLanguage) {
            localStorage.setItem("lang", res.data.localLanguage);
          }

        })
        .catch((err) => {
          console.log(err);
        });
    }
    function testonmount() {
      store.dispatch("setrolesprivileges", 'test test,');
      store.dispatch("setNavbar", true);
    }
    function microsoftLogin() {
      console.log("login with microsof");
      webAuth.authorize(
        {
          connection: "windowslive",
          redirectUri: "http://localhost:8080/home",
        },
        function (err, authResult) {
          //do something
          if (err) {
            console.log(err.description);
          } else {
            console.log(authResult);
            store.dispatch("loginuser", authResult);
            // router.push("/home");
          }
        }
      );
    }

    onMounted(testonmount)
    return {
      login,
      authResult,
      userid,
      username,
      password,
      errr,
      defaultdate,
      defaultTmile,
      patientemail,
      patientpassword,
      result,
      usersettingsUrl,
      visibleOn,
		  inputType,
      loadingBlock,
      errorBlock,
      setuserrole,
      googleLogin,
      microsoftLogin,
      testonmount,
      patientloginscreen,
      loginClicked,
      patientLogin,
      getdefaultlanguage,
      setdefaultstudy,
      patientloginClicked,
      passwordVisible,
      getUserSettings,
      //statusUpdate
    };
  },
};
