/* eslint-disable */
// import {tokenAlive} from "@/Shared/jwtHelper";
const state = () => ({
    studyId: localStorage.getItem('study_id_ref'),
    vistStudyId: localStorage.getItem('visit_study_id_ref'),
    defaultdashboard: localStorage.getItem('defaultdashboard'),
    showDefaultMenu:localStorage.getItem('defaultMenu'),
    mapformvariables: "",
    showNavbar:true,   
    collapseShow:false
});
const getters = {
    getStudyIs(state: { studyId: any }){
        return state.studyId;
      },
      getVisitStudyIs(state: { vistStudyId: any }){
        return state.vistStudyId;
      },
      getdefaultdashboard(state: { defaultdashboard: any }){
        return state.defaultdashboard;
      },
      getmapformvariables(state: { mapformvariables: any }){
        return state.mapformvariables;
      },
      getNavbarstatus(state: { showNavbar: boolean }){
        return state.showNavbar;
      },
      getDefaultMenu(state: { showDefaultMenu: boolean }){
        return state.showDefaultMenu;
      },
      getCollappseShow(state: { collapseShow: boolean }){
        return state.collapseShow;
      }
}
const actions = {
    async setStudyID({commit}: any, data: any) {
        // console.log(data);
        console.log("setStudyID" +data)
        commit("setStudy", data);
                  },
     async setVisitStudyID({commit}: any, data: any) {
         console.log("setvisitStudy" +data)
         commit("setvisitStudy", data);
                   },
      async setdefaultdashboard({commit}: any, data: any) {
          console.log("setdefaultdashboard" +data)
          commit("setdefaultdashboard", data);
                   },
     async setNavbar({commit}: any, data: boolean) {
                    // console.log(data);
                   // console.log("setStudyID" +data)
                    commit("setNavbar", data);
     },
    async setDefaultMenu({commit}: any, data: any) {
      commit("setDefaultMenu", data);
    },
    async setCollapse({commit}: any, data: boolean) {
      commit("setCollapse", data);
                },
      async setmapform({commit}: any, data: boolean) {
            commit("setmapform", data);
            }
};

const mutations = {
    setStudy(state: {studyId: any} , studyData: any){
        console.log("setStudyID" +studyData)
        state.studyId = studyData;
        // console.log(state.authData);
        localStorage.setItem("study_id_ref", studyData);
      },
      setvisitStudy(state: {vistStudyId: any} , visitStudyData: any){
        console.log("setVisitStudyID" +visitStudyData)
        state.vistStudyId = visitStudyData;
        localStorage.setItem("visit_study_id_ref", visitStudyData);
      },
      setdefaultdashboard(state: {defaultdashboard: any} , defaultdashboarddata: any){
        console.log("setdefaultdashboard" +defaultdashboarddata)
        state.defaultdashboard = defaultdashboarddata;
        localStorage.setItem("defaultdashboard", defaultdashboarddata);
      },
      setNavbar(state: {showNavbar: boolean} , shownavstatus: boolean){
        state.showNavbar = shownavstatus;
      },
      setDefaultMenu(state: {showDefaultMenu: any} , showDefaultMenu: any){
        state.showDefaultMenu = showDefaultMenu;
        localStorage.setItem("defaultMenu", showDefaultMenu);
      },
      setCollapse(state: {collapseShow: boolean} , showcollapsestatus: boolean){
        state.collapseShow = showcollapsestatus
      },
      setmapform(state: {mapformvariables: any} , mapformvar: any){
        state.mapformvariables = mapformvar;
        //localStorage.setItem("visit_study_id_ref", visitStudyData);
      }
}

export default {
    state,
    getters,
    actions,
    mutations
}