/* eslint-disable */
// import jwtInterceptor from "../Shared/jwtInterceptor";


/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import "/src/assets/css/animate.css";
import "/src/assets/css/style.css";
import jwt_decode from "jwt-decode";
import Chart from "chart.js";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useI18n } from 'vue-i18n';
const idtoken = store.getters.getIdToken;
export default {
    name: 'formlisting',
    components: {
        VPagination,
    },
    data() {
        return {
            defaultdate: "",
            baseapi: process.env.VUE_APP_Service_URL,
            baseUrl: `${process.env.VUE_APP_Service_URL}/forms/forms/`,
            searchdata: {
                SortOrder:1,
                SiteCode: "",
                PatientId: "",
                FormName: "",
                FormStatus: "",
                Pageindex: 1,
                Pagesize: 10,
                SortProperty: "",
            },
            dataList:[],
            roleprivileges:"",
            sites: "",
            patients: "",
            idToken: "",
            selectedType: "",
            selectedId: "",
            statusCheck: "",
            searchItem: "",
            currentPageSize: 0,
            currentPageNumber: 1,
            enableNext: true,
            enablePrevious: true,
            showFilter:false,
            dropdownOpen:false,
            selectedcolumnobj:{},
            totalLists: 0,
            totalPages: 0,
            lists: [],          
            filteredListing: [],
            //folder structure
            siteList :[],
            patientLists:[],
            visitobj: {},
            visitFormObj: {},
            selectedVisit: {},
            showSidePopup: [[[false]]],
            

            //form Chart 
            formchart: {
				charttype: "pie",
				responsive: [
					{
						breakpoint: 480,
						options: {
							pie: {
								size: 200,
							},
							chart: {
								toolbar: {
									show: false,
								},
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						toolbar: {
							show: false,
						},
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: false,
						},
					},
					xaxis: {
						categories:[this.$t('navigations.new') ,this.$t('navigations.active'),this.$t('navigations.verified'),this.$t('navigations.unverified')],
					},
				},
				series: [
					{
						name: 'forms',
						data: [0,0,0,0],
					},
				],
				dataseries: [this.$t('navigations.new'),this.$t('navigations.active'),this.$t('navigations.verified'),this.$t('navigations.unverified')],
				piechartOptions: {					
					chart: {
						customScale: 0.8,
						width: "100%",
						type: "pie",
					},
					labels:[this.$t('navigations.new'),this.$t('navigations.active'),this.$t('navigations.verified'),this.$t('navigations.unverified')],
					responsive: [
						{
							breakpoint: 480,
							options: {
								pie: {
									size: 200,
								},
								chart: {
									toolbar: {
										show: false,
									},
									width: 200,
								},
								legend: {
									position: "bottom",
								},
							},
						},
					],
				},
				piedataseries: [2, 3, 2, 1],
			},
            activeformcount: "",
            siteId :"",
            sndcharts : "",
            options: {
				scales: {
					y: {
						beginAtZero: true,
					},
				},
			},
        }
    },
    async mounted() {
        this.defaultdate = localStorage.getItem("dateformat");
        window.addEventListener("studyIdChanged", (event) => {
            console.log("StudyIDChanged into" + event.detail.storage);
            // window.location.reload();
            this.idToken = store.getters.getIdToken;
            this.roleprivileges =  store.getters.getRolesprivilegeData;
            this.getpatients();
            this.getsites();
            this.searchData();
            this.filter();
            this.formchartdata(this.siteId);
            this.initializeColumns()
          });
        this.idToken = store.getters.getIdToken;
        this.roleprivileges =  store.getters.getRolesprivilegeData;
        await this.getpatients();
        await this.getsites();
        await this.searchData();
        await this.filter();
        await this.formchartdata(this.siteId)
        await this.initializeColumns()
        const snd = document.getElementById("lineChart");
        this.sndcharts = new Chart(snd, {
			type: "pie",
			data: this.sendData,
			options: this.options,
		});
		this.sndcharts;
    },
    methods: {
      async initializeColumns() {
        if (this.dataList.length > 0) {
          const allowedKeys = ['siteId','subjectId','visitName','formName', 'formStatus', 'createdUserName', 'createdAt'];
          this.getColumns= Object.keys(this.dataList[0])
          .filter(column => allowedKeys.includes(column));       
          const fieldLabels = {
            siteId: "Site Code",
            subjectId: "Subject ID",
            visitName: "Visit Name",
            formName: "Form Name",
            formStatus: "Status",
            createdUserName: "Uploaded By",
            createdAt: "Uploaded Date"
          };
  
          const readableData = allowedKeys.map(key => fieldLabels[key] || key);
           
          this.getColumns=readableData
          this.selectedColumns = [...this.getColumns];
          this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
            obj[key] = true;
            return obj;
          }, {});
        }
        },
        filter(data) {
            if (data) {
                return moment(data).format(this.defaultdate)
            }
        }, 
        SortSelected(sortProp,id){
            console.log('SortPropChanged');
            const selectedElement=document.getElementById(id)
            const selectedElementID=document.getElementById(id).id
            if(sortProp!=null && id==selectedElementID)
            {
              this.sortDesc=!this.sortDesc
              if(this.searchdata.SortOrder == 0)
              {
                // if(selectedElement.classList.contains("sortDesc")){
                //   return
                // }
                 if(selectedElement.classList.contains("sortAsc"))
                {
                  selectedElement.classList.remove("sortAsc")
                  selectedElement.classList.add("sortDesc")
                }    
                else selectedElement.classList.add("sortDesc")
              }
              else
              {
                if(selectedElement.classList.contains("sortDesc")){
                  selectedElement.classList.remove("sortDesc")
                  selectedElement.classList.add("sortAsc")
                }
                // else if(selectedElement.classList.contains("sortAsc"))
                // {
                //   return
                // }          
                else selectedElement.classList.add("sortAsc")
              }
            }
            this.searchdata.Pageindex = 1;
            this.currentPageNumber = 1;
            this.searchdata.SortProperty=sortProp;
            this.searchdata.SortOrder = this.searchdata.SortOrder==0?1:0;
            this.searchData();
          },
        async viewforms(id,patientId) {
            store.dispatch("setFieldName"," ");
            localStorage.setItem("PatifFrmview",patientId)
            console.log("Pat from summary is :",patientId);
            const archive = false;
            this.$router.push(`/showform/forms/${id}/${archive}`);
        },
        async getPrevious() {
            if (this.searchdata.Pageindex > 1) {
                console.log("previous page");
                this.searchdata.Pageindex = this.searchdata.Pageindex - 1;
                this.searchData();
            }
        },
        async getNext() {
            if (this.searchdata.Pageindex < this.totalLists) {
                this.searchdata.Pageindex = this.searchdata.Pageindex + 1;
                this.searchData();

            }
        },
        async searchData() {
           if(this.searchdata.SiteCode=="All") 
           {
            this.searchdata.SiteCode=""
           }    
           if(this.searchdata.PatientId=="All"){
            this.searchdata.PatientId=""
           }    
           if(this.searchdata.FormStatus=="All"){
             this.searchdata.FormStatus=""
            }
            const idtoken = store.getters.getIdToken;
            const stdid = store.getters.getStudyIs;
            await axios
                .get(this.baseUrl +
                    "formlist?FormName=" + this.searchdata.FormName +
                    "&FormStatus=" + this.searchdata.FormStatus +
                    "&SiteCode=" + this.searchdata.SiteCode +
                    "&StudyId=" + stdid +
                    "&PatientId=" + this.searchdata.PatientId +
                    "&pageindex=" + this.searchdata.Pageindex +
                    "&pagesize=" + this.searchdata.Pagesize +
                    "&SortProperty=" + this.searchdata.SortProperty + "&SortOrder=" + this.searchdata.SortOrder,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    if (response.data) {
                        this.totalLists = Math.ceil(response.data.totalCount / this.searchdata.Pagesize);
                        this.dataList =response.data.results;
                        //this.filteredListing = response.data.results;
                    } else {
                        console.log("There are currently no list");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.enableNext = false;
                    this.enablePrevious = false;
                });
        },
        async getpatients() {
            this.searchdata.PatientId ="";
            const idtoken = store.getters.getIdToken;
            const currentstudy = store.getters.getStudyIs;
            console.log(this.searchdata.SiteCode,currentstudy );
            await axios
                .get(
                    `${this.baseapi}/management/patient/listpatient?SiteId=${this.searchdata.SiteCode}&Studyid=${currentstudy}&SortProperty=subjectId`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",

                        },
                    }
                )
                .then((res) => {
                    console.log(" get patients", res);
                    this.patients = res.data.results;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async getsites() {
            const idtoken = store.getters.getIdToken;
            const currentstudy = store.getters.getStudyIs;
            await axios
                .get(
                    `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&getAll=false`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    console.log("sites", res);
                    this.sites = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //filtering list
        filteredLists() {
            this.filteredListing = []
            if ((this.searchItem) || (this.selectedType) || (this.selectedId) || (this.statusCheck)) {
                this.lists.filter((list) => {
                    if ((this.searchItem == list.formName) || (this.selectedType == list.siteId) || (this.selectedId == list.subjectId) || (this.statusCheck == list.formStatus)) {
                        //push the filtered list to new array                     
                        this.filteredListing.push({
                            visitName: list.visitName,
                            siteId: list.siteId,
                            subjectId: list.subjectId,
                            formName: list.formName,
                            formStatus: list.formStatus
                        })
                        // console.log(this.filteredListing) 
                        return (this.filteredListing)
                    }
                });

            }
        },
        async loadPage(page) {
            this.currentPageNumber = page;
            this.searchdata.Pageindex = page;
            this.searchData();
        },

        // folder structure
         async getsites(){
            const currentstudyid = store.getters.getStudyIs;
            const idtoken = store.getters.getIdToken;  
            await axios
                      .get(
                        `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudyid}&sortProperty=siteCode&IsDes=false&getAll=false`,
                          {
                              headers: {
                                  Authorization: "Bearer " + idtoken,
                                  "Content-Type": "application/json",
                              },
                          }
                      )
              .then((res) => {
                this.siteList = res.data;
                console.log("Sites are",this.siteList)
              })
              .catch((err) => {
                console.log(err);
              });
          },
          async getpatient(siteId,index) {
              console.log("get patient function");
            this.patientLists[index]=[];
            console.log("Site id is",siteId)
            const idtoken = store.getters.getIdToken;
            const currentstudyid = store.getters.getStudyIs;
            await axios
              .get(
                `${this.baseapi}/management/patient/listpatient?SiteId=${siteId}&Studyid=${currentstudyid}&SortProperty=subjectId`,
                {
                  headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then(async (res) => {
                  let data = res.data.results;
                await data.forEach(patientdata => {
                    this.visitobj[patientdata.patientId] = [];
                });
                this.patientLists[index] = data;
              });
            //   await this.setvisitobj(this.patientLists[index]);
          },
          async setvisitobj(data){
            console.log("set visit function");
            console.log("patient list is",data);
            data.forEach(patientdata => {
                this.visitobj[patientdata.patientId] = [];
            });
            console.log(this.visitobj);
          },
          async getVisits(patientId,index)
          { 
            console.log("get visit function");
            this.visitobj[patientId][index] = [];
            const idtoken = store.getters.getIdToken;
            console.log("patient ID is",patientId)
            
            await axios
            .get(
              `${this.baseapi}/management/patientschedule/getpatientshedule?patientID=${patientId}`,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
                this.visitobj[patientId][index] = res.data.data;
                 console.log("The visit list is",this.visitobj)
            })
            .catch((err) =>{
                console.log("error is" , err);
            })
            this.displayTable=true
          },
          async gotoform(visits){
              if(visits.visitStatus == 'Completed'){
                    if(visits.formId == null || visits.formId == ""){
                    let tempvar = {
                        "Siteid": visits.siteId,
                        "Subject_id": visits.patientId,
                        "Visitname" : visits.visitName,
                        "Visitno" : visits.visitNo,
                        "sheduleId": visits.sheduleId
                    }
                    await store.dispatch("commitvisitdetails", tempvar);
                    
                    console.log("REAL",visits.patientId)
                    this.$router.push(`/form/${visits.templateId}/${visits.studyId}/ `);
                    }
                    else{
                    const archive = false;
                    
                    this.$router.push(`/showform/forms/${visits.formId}/${archive}`);
                    }
             }
          },
          filterMonth(date) {
            if(date){
              return moment(date).format('DD-MMM-YYYY')
            }
          },
          async getFormsByVisit(subjectId, visitNo, visitName) {
            console.log("HEllo",subjectId, visitNo, visitName)
            this.visitFormObj[visitNo + subjectId] = [];
            const idtoken = store.getters.getIdToken;
            const stdid = store.getters.getStudyIs;
            await axios.get(`${this.baseapi}/management/patientschedule/getfromsbyvisit?studyId=${stdid}&patientID=${subjectId}&visitNo=${visitNo}&visitName=${visitName}`,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            ).then((res) => {
              this.visitFormObj[visitNo + subjectId] = res.data;
              console.log("getfromsbyvisit list is...", this.visitFormObj, visitNo);
              console.log("data is ", this.visitFormObj[visitNo + subjectId].data)
            })
              .catch(err => console.log("error while getting visit forms list", err)
      
              )
          },
          async listVisitForms(siteId, subjectId, visitNo) {
            console.clear();
            this.visitFormObj[visitNo] = [];
            const idtoken = store.getters.getIdToken;
            const stdid = store.getters.getStudyIs;
            await axios.get(`${this.baseapi}/forms/forms/getformsbyids?studyid=${stdid}&siteid=${siteId}&subjectid=${subjectId}&visitno=${visitNo}`,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            ).then((res) => {
              this.visitFormObj[visitNo] = res.data;
              console.log("Visit form list is...", this.visitFormObj);
              console.log("data is ", this.visitFormObj[visitNo])
            })
              .catch(err => console.log("error while getting visit forms list", err)
      
              )
          },
          async gotoform(singlevisit, autosubjectid, autositecode) {
            console.log("singlevisit", singlevisit);
            await store.dispatch("setautofillsiteid", autositecode);
            await store.dispatch("setautofillsubjectid", autosubjectid);
            localStorage.setItem("patientIdFromGotoForm", singlevisit.patientId);
            console.log("From goto",singlevisit.patientId)
            if (singlevisit.formId == null || singlevisit.formId == "") {
              let tempvar = {
                "Siteid": singlevisit.siteId,
                "Subject_id": singlevisit.patientId,
                "Visitname": singlevisit.visitName,
                "Visitno": singlevisit.visitNo,
                "sheduleId": singlevisit.sheduleId
              }
              await store.dispatch("commitvisitdetails", tempvar);
              console.log("IspatientVist?", singlevisit.isPatientVisit)
              if (singlevisit.templateId == null || singlevisit.templateId == "") {
                alert(this.$t("navigations.no template assigned"))
              }
              else {
                this.$router.push(`/form/${singlevisit.isPatientVisit}/${singlevisit.templateId}/${singlevisit.studyId}/0 `);
              }
            }
      
            else {
              //this.$router.push(`/editForm/submitForm/${singlevisit.formId}/${singlevisit.isPatientVisit} `);
              this.$router.push(`/showform/PatientScheduleView/${singlevisit.formId}/false`);
            }
          },
          toggleSidePopup(sIndex, pIndex, vIndex) {
            console.log("Toggle", sIndex, this.showSidePopup);
      
            // Initialize arrays if they don't exist
            if (!this.showSidePopup[sIndex]) {
              this.showSidePopup[sIndex] = [];
            }
            if (!this.showSidePopup[sIndex][pIndex]) {
              this.showSidePopup[sIndex][pIndex] = [];
            }
      
            // Toggle the value
            if (typeof this.showSidePopup[sIndex][pIndex][vIndex] === 'undefined') {
              this.showSidePopup[sIndex][pIndex][vIndex] = false;
            }
            this.showSidePopup[sIndex][pIndex][vIndex] = !this.showSidePopup[sIndex][pIndex][vIndex];
          },
          closeSidePopup(sIndex, pIndex, vIndex) {
            if (this.showSidePopup[sIndex] && this.showSidePopup[sIndex][pIndex]) {
              this.showSidePopup[sIndex][pIndex][vIndex] = false;
            }
          },
          async openModal(visit, subjectId, siteCode) {
            console.log("Open Modal", visit, subjectId, siteCode)
            this.selectedVisit.visit = visit;
            this.selectedVisit.subjectId = subjectId;
            this.selectedVisit.siteCode = siteCode;
            console.log("selected visit is ", this.selectedVisit);
            // this.selectedmodeltemplate = "";
            // this.selectedvisitid = visitid;
            await this.listTemplate();
            const modalWrapper = document.querySelector(".modal-wrapper");
            modalWrapper.classList.add("active");
          },
          closeModal() {
            // this.selectedmodeltemplate = "";
            const modalWrapper = document.querySelector(".modal-wrapper");
            modalWrapper.classList.remove("active");
          },
          
          //chart 
        async secondchartchange(val) {
			if (val == "barhorizondal") {
				await this.switchsecondbar(true);
				this.formchart.charttype = "bar";
			} else {
				await this.switchsecondbar(false);
				this.formchart.charttype = val;
			}
		}, 
        async switchsecondbar(res) {
			this.formchart.chartOptions.plotOptions = { bar: { horizontal: res } };
			this.formchart.charttype = "";
		},
        async formchartdata(siteId) {
			const idtoken = store.getters.getIdToken;
            var currentStudy = store.getters.getStudyIs;
			await axios
				.get(                                
					`${this.baseapi}/forms/forms/formcountinsite?studyID=${currentStudy}&siteID=${siteId}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					console.log(res);
					let formValues=[]
					let formCategories=[]
					for (const [key, value] of Object.entries(res.data)) {
						if(key!='Total')
						{
							formValues.push(res.data[key])
						}
					  }
					
					console.log("forms value is ",formValues);
					console.log("forms data is ",formCategories);
					this.formchart.dataseries = [
						{
							'name':'forms',
							'data': [
								res.data.New == null ? 0 : res.data.New,
								res.data.Active == null ? 0 : res.data.Active,
								res.data.Verified == null ? 0 : res.data.Verified ,
								res.data.Unverified == null ? 0 : res.data.Unverified,
							]
						}
					];
					this.formchart.piedataseries = this.formchart.dataseries[0].data;
				})
				.catch((err) => {
					console.log(err);
				});
				this.formchart.charttype = 'pie';
		},
    },

}