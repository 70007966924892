<template>
    <div ref="component" class="stat-card-white">
      <div class="stat-header-white">
        <span>{{ chartLabel }}</span>
        <div class="d-flex justify-content-end align-items-center">
          <div class="d-flex align-items-center">
            <label class="mb-0 mr-1" for="country-select">Country:</label>
            <select class="graph-select mr-2" v-model="selectedCountryCode" id="country-select" @change="createChart">
              <option value="All">All</option>
              <option v-for="country in uniquecountryCodes" :key="country" :value="country">
                {{ country }}
              </option>
            </select>
          </div>
          <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
            <img @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
            <div class="more-expand">
              <span>{{$t("navigations.data overview")}}</span>
            </div>
          </div>
          <div v-if="!loader" class="d-flex align-items-center">
            <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock = true">
              <img src="../../assets/img/expand.png" alt="Expand block" />
            </div>
            <div v-on:clickout="showVisitPopup = false">
              <div class="cursor-pointer biggerfont ml-2" @click.prevent="showVisitPopup = !showVisitPopup">
                <i class="fa fa-ellipsis-v"></i>
              </div>
              <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="exporttoexcell()">Export to Excel</div>
            <div @click.prevent="exporttopdf()">Export to PDF</div>
          </div>
            </div>
          </div>
        </div>
      </div>
      <div class="stat-body-white">
        <div v-if="loader" class="loader"></div>
        <div ref="chartContainerWrapper" class="w-100 overflow-auto">
          <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
            <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile" />
            <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile" />
          </div>
          <svg ref="chartContainer" v-show="!loader"></svg>
          <div v-if="colorcodes.length!=0" class="d-flex">
            <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
              <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
              {{ colors.type }}
            </div>
          </div>
          <div ref="tooltip" class="tooltip"></div>
        </div>
      </div>
      <enlargerpopup v-if="expandBlock" @closeModal="expandBlock = false; createChart()">
        <div class="stat-body-white d-flex flex-column">
          <svg ref="popupChartContainer"></svg>
        </div>
      </enlargerpopup>
    </div>
  </template>
  
  <script>
  import * as d3 from 'd3';
  import store from "../../store/index";
  import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
  import html2pdf from "html2pdf.js";
  export default {
    name: "comparativeLineChart",
    components: {
      enlargerpopup,
    },
    props: {
      data: {
        type: Array,
        required: true,
        default: () => [],
      },
      xAxisValue: {
        type: String,
        default: "site_id",
      },
      loader: {
        type: Boolean,
        default: false,
      },
      thresholdline: {
        type: Boolean,
        default: true,
      },
      yAxisValue: {
        type: String,
        default: "", // Not used directly now, since we have two categories
      },
      groupcategories: {
        type: Array,
        required: true,
        default: () => ["difference"],
      },
      dropdownoption: {
        type: String,
        default: "country",
      },
      groupCategoryValue: {
        type: String,
        default: "",
      },
      chartLabel: {
        type: String,
        default: "Comparative Analysis",
      },
      optionstochose: {
        type: Array,
        default: () => ["siteCode", "country"],
      },
      custcolors: {
        type: Array,
        default: () => ["#62B2FD", "#9BDFC4", "#ABF0FF", "#C1D1FF", "#FBD164", "#FDAD73"],
      },
      colorcodes: {
        type: Array,
        default: () => [ {
              "color":"#62B2FD",
              "type":"Difference between actual cost and baseline cost"
          }],
      }
    },
    data() {
      return {
        showVisitPopup:false,
        svg: null,
        selectedCountryCode: "All",
        uniquecountryCodes: [],
        expandBlock: false,
        tooltip: null,
        chartContainer: null,
        sortdata: [],
        filterkeyword:"",
        filterarray:[],
        selectedGroups: [],
        selectedKeywords: [],
        mouseX: 0,
        mouseY: 0,
      };
    },
    watch: {
      data: {
        handler() {
          this.cancelselection();
          this.extractUniqueCountries();
          this.createChart();
        },
        deep: true,
      },
      expandBlock(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.createChart();
          });
        }
      },
    },
    beforeUnmount() {
      window.removeEventListener('mousemove', this.updateMousePosition);
    },
    mounted() {
      window.addEventListener('mousemove', this.updateMousePosition);
      this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
      this.tooltip = this.$refs.tooltip;
      this.extractUniqueCountries();
      this.createChart();
    },
    methods: {
      async exporttoexcell() {
      this.$emit("exporttoexcell");
    },
    exporttopdf() {
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = "none";
      const pdfContainer = document.createElement("div");

      const captureArea = this.$refs.component.cloneNode(true);
      pdfContainer.appendChild(captureArea);

      pdfContainer.style.width = "100%";
      pdfContainer.style.display = "block";
      const opt = {
        margin: 0,
        filename:  `${this.chartLabel}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "pt", format: "a4", orientation: "landscape" },
      };

      html2pdf().set(opt).from(pdfContainer).save();
    },
      showtable() {
        this.showMore = false;
        this.$emit("opendrillpopup", this.data);
      },
      async cancelselection() {
        this.selectedGroups = [];
        this.sortdata = [];
        this.createChart();
      },
      backtooriginal() {
        this.sortdata = [];
        this.selectedGroups = [];
      },
      updateMousePosition(event) {
        if (this.$refs.component) {
          const rect = this.$refs.component.getBoundingClientRect();
          this.mouseX = event.clientX - rect.left;
          this.mouseY = event.clientY - rect.top;
        }
      },
      extractUniqueCountries() {
        this.uniquecountryCodes = Array.from(new Set(this.data.map(d => d['country']))).sort();
      },
      createChart() {
  this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
  if (!this.chartContainer) return;

  const margin = { top: 20, right: 60, bottom: 80, left: 60 };
  const width = 550 - margin.left - margin.right;
  const height = 340 - margin.top - margin.bottom;

  // Filter data by selected country if not "All"
  let filteredData = this.selectedCountryCode === "All"
    ? this.data
    : this.data.filter(d => d['country'] === this.selectedCountryCode);

  if (!filteredData || filteredData.length === 0) {
    d3.select(this.chartContainer).selectAll("*").remove();
    return;
  }

  // Sort by site_id (assuming site_id is numeric)
  filteredData = filteredData.slice().sort((a, b) => {
    const aVal = isNaN(+a[this.xAxisValue]) ? a[this.xAxisValue] : +a[this.xAxisValue];
    const bVal = isNaN(+b[this.xAxisValue]) ? b[this.xAxisValue] : +b[this.xAxisValue];
    return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
  });

  // Extract site_ids for x-axis
  const xDomain = filteredData.map(d => d[this.xAxisValue]);

  // Prepare data for each category line
  const lineData = {};
  this.groupcategories.forEach(cat => {
    lineData[cat] = filteredData.map(d => ({ site_id: d[this.xAxisValue], value: +d[cat], originalData: [d] }));
  });

  const allValues = Object.values(lineData).flat().map(d => d.value);
  let yMax = d3.max(allValues) || 0;
  let yMin = d3.min(allValues) || 0;

  // Force inclusion of zero in the domain
  const forcedMin = Math.min(yMin, 0);
  const forcedMax = Math.max(yMax, 0);

  // Clear previous chart
  d3.select(this.chartContainer).selectAll("*").remove();

  // Create SVG
  this.svg = d3.select(this.chartContainer)
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  const xScale = d3.scalePoint()
    .domain(xDomain)
    .range([0, width])
    .padding(0.5);

  const yScale = d3.scaleLinear()
    .domain([forcedMin, forcedMax])
    .nice()
    .range([height, 0]);

  // Define line generator
  const lineGenerator = d3.line()
    .x(d => xScale(d.site_id))
    .y(d => yScale(d.value))
    .curve(d3.curveMonotoneX);

  // Color scale for lines
  const colorScale = d3.scaleOrdinal()
    .domain(this.groupcategories)
    .range(this.custcolors);

  // Draw lines and points
  this.groupcategories.forEach(category => {
    const dataForCat = lineData[category];

    // Line
    this.svg.append("path")
      .datum(dataForCat)
      .attr("fill", "none")
      .attr("stroke", colorScale(category))
      .attr("stroke-width", 2)
      .attr("d", lineGenerator);

    // Points
    this.svg.selectAll(`circle-${category}`)
      .data(dataForCat)
      .enter()
      .append("circle")
      .attr("cx", d => xScale(d.site_id))
      .attr("cy", d => yScale(d.value))
      .attr("r", 4)
      .attr("fill", colorScale(category))
      .on("mouseover", (event, d) => {
        d3.select(this.tooltip)
          .style('opacity', 0.9)
          .html(`Category: ${category}<br/>Site: ${d.site_id}<br/>Value: ${d.value}`)
          .style("left", `${this.mouseX + 20}px`)
          .style("top", `${this.mouseY - 28}px`);
      })
      .on("mouseout", () => d3.select(this.tooltip).style("opacity", 0))
      .on("mousemove", () => {
        d3.select(this.tooltip)
          .style("left", `${this.mouseX + 20}px`)
          .style("top", `${this.mouseY - 28}px`);
      })
      .on("click", (event, d) => {
        this.clicklinechart(d.originalData, d.site_id, category);
      });
  });

  // Add axes
  this.svg.append("g")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(xScale))
    .selectAll("text")
    .style("text-anchor", "end")
    .attr("transform", "rotate(-45)");

  this.svg.append("g")
    .call(d3.axisLeft(yScale));

  // X-axis label
  this.svg.append("text")
    .attr("x", width / 2)
    .attr("y", height + margin.bottom - 20)
    .attr("text-anchor", "middle")
    .text("Sites");

  // Y-axis label
  this.svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("x", -height / 2)
    .attr("y", -margin.left + 15)
    .attr("text-anchor", "middle")
    .text("Cost Varience($)");

  // Draw threshold line if enabled
  if (this.thresholdline) {
    this.svg.append("line")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", yScale(0))
      .attr("y2", yScale(0))
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "5,5");
  }
},
      clicklinechart(filteredData, filteredkeyword, category) {
        if (this.selectedGroups.includes(filteredkeyword)) {
            this.selectedKeywords = this.selectedKeywords.filter((k) => k !== filteredkeyword+category);
            this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
            let result = this.sortdata.filter(item => !filteredData.includes(item));
            this.sortdata = result;
            let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
            this.filterarray = filterarraydeleted;
            this.filterkeyword = `${this.xAxisValue}(${this.selectedGroups})`;
          } else {
            this.selectedGroups.push(filteredkeyword);
            this.selectedKeywords.push(filteredkeyword+category);
            this.filterarray.push(`(${filteredkeyword})`)
            this.filterkeyword = `${this.xAxisValue}(${this.selectedGroups})`;
            this.sortdata = this.sortdata.concat(filteredData);
          }
          this.createChart();
      },
      recreatechart() {
        const dataToEmit = {
          sortedData: this.sortdata,
          filterkeyword: this.filterkeyword
        };
        this.$emit("drilldowncurrentchart", dataToEmit);
      },
    },
  };
  </script>
  
  <style scoped>
  .legandbox{
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
  }
  .tooltip {
    position: absolute;
    text-align: center;
    width: auto;
    height: auto;
    padding: 5px;
    background: rgb(0, 0, 0);
    border: 1px solid rgb(117, 116, 116);
    border-radius: 3px;
    pointer-events: none;
    opacity: 0;
    z-index: 10;
  }
  </style>
  