import axios from "axios";
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import D3PieChart from "../../custom_lib/AiCharts/D3PieChart.vue";
import advtable from '../../custom_lib/AiCharts/advenenttable.vue';
import groupbarchart from '../../custom_lib/AiCharts/cmbarchart.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
import simplelinechart from '../../custom_lib/AiCharts/simplelinechart.vue'
import * as XLSX from "xlsx";
import JsonData from './costmonitoring.json';
import html2canvas from "html2canvas";
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
export default{
  name: "demographicReport",
  props: {

  },
  components:{
    historytab,
    D3PieChart,
    advtable,
    groupbarchart,
    chartdrill,
    simplelinechart
  },
  data() {
     return{
      namesobj:{
        country:"Country",
        site_id:"Site ID",
        baseline_cost:"Baseline Cost",
        actual_cost:"Actual Cost",
        difference:"Difference",
        difference_percentage:"Percentage of Difference",
        status:"Status"
      },
      customColors:["#EB5757",
        "#F2C94C",
        "#57D9A5",
        "#FFB44F",
        "#57D9A5"],
        data:[],
        piedata:{},
        JsonData,
        baseUrl: process.env.VUE_APP_Service_URL + "/forms-ml/report/",
        totalCount: 0,
        datahistory:[],
        historykeys:[],
        historydata:{},
        drilldata:{},
        drilldowndata:[],
        drillpopup:false,
        arrayofkeys: [
          "status" ],
        xaxiskeys:{"groupbarchart":"",
          "barchart":"",
          "histogram":"",
          "piechart":"AGE",
          "advtable":"",
          "aepiechart":"",
          "historytab":"",
          "chartdrill":""},
        groupchartkeys:{
          "xaxiskey":"SEX",
        "groupkey":"ALCOHOL",
        },
        barcolorcodes:[
          {
              "color":"#62B2FD",
              "type":"Baseline Cost"
          },{
              "color":"#9BDFC4",
              "type":"Actual Cost"
          }
        ],
        loader:true,
     };
  },
  async mounted(){
   
    await this.getdata();
  },
  methods: {
    exporttopdf() {
      // Create a temporary container
      const pdfContainer = document.createElement('div');
      const pdfContainer1 = document.createElement('div');
      const pdfContainer2 = document.createElement('div');
      const pdfContainer3 = document.createElement('div');

      const captureArea1 = document.getElementById('captureArea1').cloneNode(true);
      const captureArea2 = document.getElementById('captureArea2').cloneNode(true);
      const captureArea3 =  document.getElementById('captureArea3').cloneNode(true);

      captureArea2.classList.add('page-break');
      captureArea3.classList.add('page-break');
      // Append cloned elements to the container
      pdfContainer1.appendChild(captureArea1);
      pdfContainer2.appendChild(captureArea2);
      pdfContainer3.appendChild(captureArea3);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer1.style.width = '100%';
      pdfContainer2.style.width = '200%';
      pdfContainer3.style.width = '200%';
      pdfContainer.appendChild(pdfContainer1);
      pdfContainer.appendChild(pdfContainer2);
      pdfContainer.appendChild(pdfContainer3);

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: 'Cost Monitoring.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
     },
    async exporttoexcell() {
      const worksheet = XLSX.utils.json_to_sheet(this.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Cost Monitoring.xlsx");
    },
    async retaingroupchartvars(groupvars){
      if(groupvars.xaxiskey!="" && groupvars.groupkey!=""){
      this.groupchartkeys = groupvars;
      }
    },
    async getdata() {
      console.log("this.JsonData",this.JsonData.sites);
      this.data = this.JsonData.sites;
      this.piedata = this.JsonData.summary;
    },
    // async getdata() {
    //     this.loader= true;
    //     await axios.post(
    //       `${this.baseUrl}getfilteredreports?table_name=Demographics`)
    //       .then((res) => {
    //         this.data = res.data;
    //         this.totalCount = res.data.length;
    //         this.loader= false;
    //         this.datahistory.push(this.data);
    //         this.historydata['Home'] = this.data;
    //         this.historykeys.push('Home');
    //       })
    //       .catch((err) => console.log("error in screen list", err));
    //   },
    async drillpopupopened(datafromchild) {
        this.drilldata = datafromchild;
        this.drillpopup = true;  
    },
    async drilldowncurrentchart(datafromchild){
      this.data = datafromchild;
      this.datahistory.push(this.data);
    },
    async drilldowncurrentchartforbar(datafromchild){
      console.log("sorteddataforbarchart",datafromchild);
      const lastkey = this.historykeys[this.historykeys.length - 1];
      // this.data = datafromchild.sortedData;
      if(this.historydata[lastkey].length != datafromchild.sortedData.length){
      // if(!this.historykeys.includes(datafromchild.filterkeyword)){
       this.historykeys.push(datafromchild.filterkeyword);
      this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
      this.data = this.historydata[datafromchild.filterkeyword];
      }
    },
    async gotohistorylevel(history){
      this.deleteAfterKeyvaluearrays(history);
      this.deleteAfterKeyarrays(history);
      this.data = this.historydata[history];
    },
    async deleteAfterKeyvaluearrays(selectedKey) {
      const entries = Object.entries(this.historydata); // Convert the object to an array of entries
      const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);
    
      // If the key is found, slice the array to keep only the entries up to the selected key
      const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;
    
      // Convert the array of entries back into an object
      return Object.fromEntries(newEntries);
    },
    async deleteAfterKeyarrays(selectedKey) {

      let index = this.historykeys.indexOf(selectedKey);

if (index !== -1) {
    // Slice the array up to the element (including the element)
    this.historykeys = this.historykeys.slice(0, index + 1);
}
    },
    async retainxaxiskey(xaxiskey){
      if(xaxiskey!=""){
      this.xaxiskeys["piechart"] = xaxiskey;
      }
    },
      async onclosed(){
        this.drillpopup = false;
      },
      async pageclosed(){
        this.$router.push(`/studymonitoring`);
      }
  },
};